
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  userName: string; // Ensure this is available in the Employee interface
}

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.css','/../../../shared/all-style/add-meeting-style.component.css']
})
export class AddMessageComponent implements OnInit {
  form: FormGroup;
  isSubmitting = false;
  file: File | null = null;
  selectedEmployeesControl = new FormControl();
  data: Employee[] = [];
  id: string;
  selectedFile: File | null = null;
  constructor(
    private fb: FormBuilder,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.id = localStorage.getItem('id');
    this.mainService.managerAlllist(this.id,true).subscribe((res: Employee[]) => {
      this.data = res;
    });this.form = this.fb.group({
      purpose: ['', Validators.required],
      message: ['', Validators.required],
      meetingTime: ['', Validators.required],
      userIds: ['', Validators.required]
    });
    // this.routeSub = this.route.queryParams.subscribe((params) => {
    //   this.leaveData.purpose = params['purpose'];
    //   this.leaveData.date = params['date'];
    //   this.leaveData.message = params['message'];
    //   this.leaveData.userIds = params['userIds'];
    // });
  }
  // ngOnInit(): void {
  //   this.id = localStorage.getItem('id');
  //   this.mainService.AllEmployeeProfileList().subscribe((res: Employee[]) => {
  //     this.data = res;
  //   });
  //   this.form = this.fb.group({
  //     purpose: ['', Validators.required],
  //     message: ['', Validators.required],
  //     meetingTime: ['', Validators.required],
  //     userIds: ['', Validators.required]
  //   });
  //   console.log('Form initialized:', this.form);
  // }

  // formSubmit(event) {
  //   event.preventDefault(); 
  
  //   let target = event.target;
   
  //   const arrObj = {
  //     purpose: target.querySelector('#purpose').value.trim(),
  //     date: target.querySelector('#date').value.trim(),
  //     message: target.querySelector('#message').value.trim(),
  //     userIds: this.selectedEmployeesControl.value.map((employee: Employee) => employee.id),
  //   };
  
  //   if (!arrObj.purpose || !arrObj.date || !arrObj.message  || arrObj.userIds.length === 0) {
  //     Swal.fire('Please fill in all fields and select at least one employee.');
  //     return;
  //   }
  
  //   this.mainService.customChat(arrObj).subscribe(
  //     (data: any) => {
  //       console.log(data);
  //       Swal.fire('Successfully done !!', 'success');
  
  //       this.router.navigate(['/manager-dashboard/show-user-chat']);
  //     },
  //     (error) => {
  //       console.log(error);
  //       Swal.fire('Error', ' ' + error.error.message, 'error');
  //     }
  //   );
  // }
  






 

  onSelect(event: any): void {
    console.log('File selected:', event);
    const maxFileSize = 300 * 1024;
    const fileToAdd = event.target.files[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    this.selectedFile = fileToAdd;
    console.log('File added:', this.selectedFile);
  }

  onRemove(): void {
    this.selectedFile = null;
  }

  formSubmit(event: Event): void {
    event.preventDefault();
    if (this.selectedFile) {
      const folderName = 'messages';
      this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
        (response: any) => {
          const filePath = response.message; // Adjust based on your API's response
          this.saveFormData(filePath);
        },
        (error) => {
          console.error('File upload failed:', error);
          Swal.fire('Error', 'File upload failed.', 'error');
        }
      );
    } else {
      this.saveFormData('');
    }
  }

  getFormData() {
    return {
      purpose: this.form.get('purpose')?.value,
      message: this.form.get('message')?.value,
      meetingTime: new Date(this.form.get('meetingTime')?.value).getTime(),
      userIds: this.selectedEmployeesControl.value.map((employee: Employee) => employee.id)
    };
  }

  saveFormData(filePath: string): void {
    const formData = {
      id: 0,
      purpose: this.form.get('purpose')?.value,
      message: this.form.get('message')?.value, // Use filePath here
      meetingTime: new Date(this.form.get('meetingTime')?.value).getTime(),
      image: filePath, // Set the file path obtained from the upload API
      userIds: this.selectedEmployeesControl.value.map((emp: any) => emp.id)
    };

    this.mainService.customChat(formData).subscribe(
      (response) => {
        console.log('Form data saved successfully:', response);
        Swal.fire('Success', 'Form data saved successfully', 'success');
        this.form.reset();
        this.selectedFile = null;
      },
      (error) => {
        console.error('Form data save failed:', error);
        Swal.fire('Error', 'Form data save failed.', 'error');
      }
    );
  }
}
