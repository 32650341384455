import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { LeaveModalComponent } from '../leave-modal/leave-modal.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-show-all-approved-leave',
  templateUrl: './show-all-approved-leave.component.html',
  styleUrls: ['./show-all-approved-leave.component.css', '/../../../shared/all-style/all-style.component.css'],
})
export class ShowAllApprovedLeaveComponent implements OnInit {
  id: string | null = localStorage.getItem('id');
  user: any;
  inputDateFromString: any;
  inputDateToString: any;
  filter: any;
  data: any = [];
  startDate: string = '';
  endDate: string = '';
  status: string = 'APPROVED';
  approvedBy:any;
  // myDate = new Date();
  // userList: any[] = [];
  // filteredData: any[] = [];
  showFilterForm: boolean = false;

  userId: string = '';
  leaveDates: any[] = [];
  leavedata: any[] = [{ leaveDates: [{ date: [] }] }];
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  // leavedata1: any = [];
  showAdditionalDates: boolean[] = [];

  constructor(
    private mainService: MainService,
    private auth: MainService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe(
      (res) => {
        this.user = res;
      },
      (error) => {
        console.error('Error fetching employee profile:', error);
      }
    );
    this.fetchData(this.userId, this.startDate, this.endDate, this.status, this.pageNumber, this.pageSize,this.approvedBy);
  }

  fetchData(userId: string | null, startDate: string, endDate: string, status: string, pageNumber: number, pageSize: number,approvedBy:any): void {
   
    this.mainService.getAllLeave(userId, pageNumber, pageSize,startDate, endDate ,status,approvedBy).subscribe(
      (res: any) => {
        this.data = res.content;
        this.totalElements = res.totalElements;
        this.showAdditionalDates = new Array(this.data.length).fill(false);
        this.addSerialNumbers(this.data);
        console.log(JSON.stringify(this.data));
      },
      (error) => {
        console.error('Error fetching approved leaves:', error);
      }
    );
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.userId, this.startDate, this.endDate, this.status, this.pageNumber, this.pageSize,this.approvedBy);
  }

  getLeaveTypeText(leaveType: string): string {
    switch (leaveType) {
      case 'HALF_DAY':
        return 'Half Leave';
      case 'ABSENT':
        return 'Absent';
      case 'SHORT_LEAVE':
        return 'Short Leave';
      case 'PAID_LEAVE':
        return 'Paid Leave';
      default:
        return leaveType;
    }
  }

  addSerialNumbers(data: any[]): void {
    data.forEach((item, index) => {
      item.serialNumber = index + 1;
    });
  }

  approvedfilter(): void {
    this.inputDateFromString = this.auth.toTimestamp(this.startDate);
    this.inputDateToString = this.auth.toTimestamp(this.endDate);
    const userId = this.userId ;
    this.fetchData(userId, this.inputDateFromString, this.inputDateToString, this.status, this.pageNumber, this.pageSize,this.approvedBy);
  }

  openUserModal(reason: string, status: string, userName: string, leaveType: string, createdDate: string): void {
    this.dialog.open(LeaveModalComponent, {
      width: '700px',
      data: { reason, status, userName, leaveType, createdDate },
    });
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }
  clearFilters() {
    this.userId = '';
    this.status = '';
    this.startDate = '';
    this.endDate = '';
    this.fetchData(this.userId, this.startDate, this.endDate, this.status, this.pageNumber, this.pageSize,this.approvedBy);
  }
}
