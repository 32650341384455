import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css']
})
export class AddTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  isSubmitting = false;
  constructor(private snack: MatSnackBar, private mainService: MainService, private router: Router) { }
  public user = {
    date: '',
    time: '',
    subject: '',
    fileUrl: '',
  };
  ngOnInit(): void {
  }

  files: File[] = [];

  onSelect(event) {
    console.log(event);
    const maxFileSize = 300 * 1024; 
    if (event.addedFiles.length > 1) {
      Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
      return;
    }
    const fileToAdd = event.addedFiles[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    if (fileToAdd.type === 'application/pdf') {
      Swal.fire('Error', 'You cannot upload PDF files.', 'error');
      return;
    }
    this.files = [fileToAdd];
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  formSubmit() {
    if (this.files.length === 0 && !this.user.subject) {
      Swal.fire('Error', 'Please select at least one file or enter a task', 'error');
      return;
    }
    this.isSubmitting = true; 
    this.uploadFiles();
  }

  uploadFiles() {
    const documentsArray: string[] = [];
    let processedFiles = 0;
    if (this.files.length === 0) {
      this.addTask(); 
      return;
    }
    this.files.forEach(file => {
      const data = new FormData();
      const folderName= 'taskDocs'
      data.append('file', file);

      this.mainService.uploadFile(file,folderName).subscribe(
        (res) => {
          console.log(res);
          const secureUrl = res.message;
          documentsArray.push(secureUrl);
          console.log('Uploading files to Cloudinary', this.files);
          if (++processedFiles === this.files.length) {
            this.user.fileUrl = documentsArray[0];
            this.addTask(); 
          }
        },
        (error) => {
          console.error(error);
          this.isSubmitting = false; 
        }
      );
    });
  }

  addTask() {
    this.mainService.addtask(this.id, this.user).subscribe(
      (patchRes) => {
        console.log("API response:", patchRes);
        Swal.fire('Success', 'Form submitted successfully', 'success');
        this.router.navigate(['user-dashboard/task']);
        this.isSubmitting = false; 
      },
      (patchError) => {
        console.error(patchError);
        Swal.fire('Error');
        this.isSubmitting = false; 
      }
    );
  }
}
