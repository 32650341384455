import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('slideInOut', [
      state('true', style({ height: '0', opacity: '0' })),
      state('false', style({ height: '*', opacity: '1' })),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  showDropdown = false;
  isHovered = false;

  constructor(private login: LoginService, private router: Router) {}

  ngOnInit(): void {}

  public logout() {
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: 'Confirm Sign Off',
      text: 'Are you sure you want to sign off?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, sign off',
      confirmButtonColor: '#ff4081',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed logout
        // Assuming `login` is a service that handles logout
        this.login.logout();
  
        // Navigate to the root URL
        this.router.navigate(['/']).then(() => {
          // Reload the page
          window.location.reload();
        });
      }
    });
  
  }
  // generateMailtoLink(email: string, subject: string): string {
  //   const encodedSubject = encodeURIComponent(subject);
  //   return `mailto:${email}?subject=${encodedSubject}`;
  // }

  // generateWhatsAppLink(message: string): string {
  //   const phoneNumber = '+919161622129';
  //   const encodedMessage = encodeURIComponent(message);
  //   return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  // }

  onMouseEnter() {
    this.showDropdown = true;
  }

  onMouseLeave() {
    this.showDropdown = false;
  }
  hide:boolean=true;

  toggleCard() {
    this.hide = !this.hide;
  }
}
