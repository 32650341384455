<h2 mat-dialog-title>Send Email</h2>  

<mat-dialog-content>
  <span class="close" (click)="closeEmailPopup()">&times;</span>
  <div class="email-form-container">
    <form (ngSubmit)="sendEmail()" class="email-form">
      <!-- To Section -->
      <div class="form-group">
        <label for="toList">To</label>
        <select 
          id="toList" 
          [(ngModel)]="emailDetails.toList" 
          name="toList" 
          class="form-control" 
          required
        >
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of user" [value]="employee.userEmail">
            {{ employee.userName }} 
          </option>
        </select>
      </div>

      <!-- CC Section -->
      <div class="form-group">
        <label for="ccList">CC</label>
        <div class="email-list">
          <div *ngFor="let cc of emailDetails.ccList; let i = index" class="email-item">
            <span>{{ cc }}</span>
            <button type="button" (click)="removeCc(i)">×</button>
          </div>
        </div>
        <select 
          id="ccSelect" 
          [(ngModel)]="newCc" 
          class="form-control"
        >
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of user" [value]="employee.userEmail">
            {{ employee.userName }} 
          </option>
        </select>
        <button type="button" (click)="addCc()">Add CC</button>
      </div>

      <!-- BCC Section -->
      <div class="form-group">
        <label for="bccList">BCC</label>
        <div class="email-list">
          <div *ngFor="let bcc of emailDetails.bccList; let i = index" class="email-item">
            <span>{{ bcc }}</span>
            <button type="button" (click)="removeBcc(i)">×</button>
          </div>
        </div>
        <select 
          id="bccSelect" 
          [(ngModel)]="newBcc" 
          class="form-control"
        >
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of user" [value]="employee.userEmail">
            {{ employee.userName }} 
          </option>
        </select>
        <button type="button" (click)="addBcc()">Add BCC</button>
      </div>

      <!-- Subject Section -->
      <div class="form-group">
        <label for="subject">Subject</label>
        <input
          type="text"
          id="subject"
          [(ngModel)]="emailDetails.subject"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>
      
      <!-- Body Section -->
      <label for="body">Body</label>
      <div class="form-group">
        <angular-editor
          id="body"
          [(ngModel)]="emailDetails.body"
          name="body"
          [config]="editorConfig"
          required
        ></angular-editor>
      </div>

      <!-- Attachments Section -->
      <div class="form-group">
        <label for="attachmentList">Attachments</label>
        <input
          id="attachmentList"
          type="file"
          (change)="onFileSelected($event)"
          multiple
        />
        <div class="attachment-list">
          <div *ngFor="let file of emailDetails.attachmentList; let i = index">
            <span>{{ file.name }}</span>
            <button type="button" (click)="removeAttachment(i)">×</button>
          </div>
        </div>
      </div>

      <button type="submit" class="submit-btn">Send Email</button>
    </form>
  </div>
</mat-dialog-content>
