import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-show-meetings',
  templateUrl: './show-meetings.component.html',
  styleUrls: ['./show-meetings.component.css', '/../../../shared/all-style/all-style.component.css'],
})
export class ShowMeetingsComponent implements OnInit {
  totalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 10;
  data: any;
  userId: string = '';
  hostId: string | null = localStorage.getItem('id');
  startDate: string = '';
  endDate: string = '';
  inputDateFromString: any;
  inputDateToString: any;
  showFilterForm: boolean = false;
  meeting: any;
  status: string = '';
  employees: any;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchMeetings(this.status, this.pageNumber, this.pageSize, this.hostId, this.userId, this.startDate, this.endDate);
    this.mainService.AllEmployeeProfileList().subscribe((res: any) => {
      this.employees = res;
    });
  }

  fetchMeetings(status: string, pageNumber: number, pageSize: number, hostId: string | null, userId: string, startDate: string, endDate: string) {
    this.mainService.showMeetings(status, hostId, userId, startDate, endDate, pageNumber, pageSize).subscribe(
      (res) => {
        this.data = res;
        this.meeting = this.data.content;
        this.totalElements = this.data.totalElements;
        console.log('Fetched meetings:', this.data);
      },
      (error) => {
        Swal.fire('Error','Data Not Found', 'error');

      }
    );
  }
    isUserPresent(user: any): boolean {
    return user.present === true; 
    }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchMeetings(this.status, this.pageNumber, this.pageSize, this.hostId, this.userId, this.startDate, this.endDate);
  }

  onSubmitFilter() {
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.fetchMeetings(this.status, this.pageNumber, this.pageSize, this.hostId, this.userId, this.inputDateFromString, this.inputDateToString);
  }

  ondelete(id: number) {
    if (confirm('Are you sure you want to delete?')) {
      this.mainService.deletemeeting(id).subscribe(
        (res) => {
          console.log('Successfully deleted');
          window.location.reload();
        },
        (error) => {
          console.error('Error deleting meeting:', error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        }
      );
    }
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'DONE':
        return 'rgb(103, 59, 141)';
      case 'SCHEDULED':
        return 'rgb(153, 102, 204)';
      case 'SUSPENDED':
        return 'rgb(77, 77, 179)';
      case 'POSTPONED':
        return 'rgb(106, 106, 152)';
      case 'PENDING':
        return 'rgb(128, 128, 128)';
      default:
        return 'rgb(106, 106, 152)';
    }
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  navigateToAddUserTask() {
    this.router.navigate(['/admin/meeting']);
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }

  clearFilters() {
    this.userId = '';
    this.status = '';
    this.startDate = '';
    this.endDate = '';
    this.fetchMeetings(this.status, this.pageNumber, this.pageSize, this.hostId, this.userId, this.startDate, this.endDate);
  }
}
