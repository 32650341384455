<!-- login.component.html -->
<div class="bootstrap-wrapper" style="background-repeat: no-repeat; background-attachment: fixed; background-position: center; background: #02020e; height: 100vh;">
  <div class="container-fuild" >
    <div class="row" style="height: 100%; margin-right: 0px; margin-left: 0px;"  >
      <div class="col-md-6 align-self-center" >
        <mat-card style=" height: 100vh; background: #0f0f0f; color: white;">
          <div class="container text-center" style="margin-top: 47px;" (click)="redirectToAdmin()">
            <img src="../../../assets/HR CONSOLE HORIZONTAL copy.png" style="height: 46px; margin-top: 79px;" alt="logo">
          </div>
          <h1 class=" container  text-center">
            <span class="material-icons" style="margin-right: 10px;">login</span> Login Here !!
          </h1>
          <!-- Initial login form -->
          <form class="login-form" (ngSubmit)="formSubmit()" style="margin-top: 0px;" *ngIf="!forgotPasswordVisible && !otpSent">
            <div class="">
              <label>User Name</label><br />
              <input style="margin-top: 5px; height: 33px;" [(ngModel)]="loginData.userName" name="userName" required matInput placeholder="Enter here" class="custom-input" />
            </div>
            <div class="password-input-container">
              <input [(ngModel)]="loginData.password" name="password" [type]="showPassword ? 'text' : 'password'" required matInput placeholder="Enter Password" class="custom-input" />
              <div class="visibility-icon-container">
                <mat-icon id="eye" matSuffix (click)="togglePasswordVisibility()">
                  {{ showPassword ? "visibility_off" : "visibility" }}
                </mat-icon>
              </div>
            </div>
            <div class="container text-center">
              <button mat-raised-button color="primary" type="submit" style="width: 124px;">Login</button>
              <button
                mat-raised-button
                color="primary"
                type="button"
                *ngIf="showForgetPasswordButton && !forgotPasswordVisible"
                (click)="toggleForgotPasswordForm()"
                style="width: 139px; margin-left: 20px;"
              >
                Forgot Password
              </button>
            </div>
          </form>
          <!-- Forgot Password form -->
          <div *ngIf="forgotPasswordVisible && !otpSent" class="forgot-password-form">
            <form (submit)="sendOTP()">
              <div class="form-field">
                <label for="email">Enter your email:</label><br />
                <input [(ngModel)]="email" name="email" type="email" required matInput placeholder="Enter your email" class="custom-input" />
              </div>
              <div class="container text-center">
                <button mat-raised-button color="primary" type="button" (click)="sendOTP()">Send OTP</button>
              </div>
            </form>
          </div>

          <!-- OTP input field and submit button -->
          <!-- OTP input field and submit button -->
<div *ngIf="otpSent" class="otp-form">
  <form (submit)="submitOTP()">
    <div class="form-field">
      <label for="email">Enter your email:</label><br />
      <input [(ngModel)]="email" name="email" type="email" required matInput placeholder="Enter your email" class="custom-input" />
    </div>
    <div class="form-field">
      <label for="otp">Enter OTP:</label><br />
      <input [(ngModel)]="otp" name="otp" type="text" required matInput placeholder="Enter OTP" class="custom-input" />
    </div>

    <div class="container text-center">
      <button mat-raised-button color="primary" type="submit" (click)="submitOTP()">Submit OTP</button>
    </div>
  </form>
</div>

        </mat-card>
      </div>
      <div class="col-md-6 text-center" id="second-container">
        <img src="../../../assets/hr-icon.png"  alt="Hr Console Logo"style="height: 192px;margin-top: 200px; " />
      </div>
    </div>
  </div>
</div>

