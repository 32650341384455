<div class="container3">
  <h4 style="
              text-align: center;
              font-size: 30px;
              margin-top: -31px;
              color: white;
              margin-bottom: -1px;
            ">
    Assignment
  </h4>
  <hr class="animated-line" />
</div>
<mat-card>
  <div class="compose-email" style="width: 100%">
    <div class="form-header"></div>
    <div class="form-body">
      <form (submit)="assigment($event)">
        <div class="form-group">
          <label>Subject:</label>
          <input type="text" id="task" placeholder="Enter subject" required />
        </div>

        <div class="form-group">
          <label> Description</label>
          <textarea id="description" placeholder="Enter message" required style="height: 200px"></textarea>
        </div>
        <div class="form-group">
          <label> Deadline:</label>
          <input type="date" id="deadline" style="
                      width: 100%;
                      height: 37px;
                      padding: 11px;
                      border-radius: 4px;
                      border: 1px solid grey;
                    " placeholder="Enter recipient email" required />
        </div>

        <div class="form-actions">
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  </div>
</mat-card>