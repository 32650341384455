<mat-dialog-content>
    <div class="modal-overlay" (click)="closeModal()"></div>
    <div class="modal-content" style="width: 34%;">
        <span class="close" (click)="closeModal()">&times;</span>
        <h2>{{ events[0]?.subject }}</h2>
        <p><mat-icon style="color:#ef205f;">event</mat-icon>
            {{ events[0]?.eventDateTime | date:'dd-MMM-yyyy' }}
            <mat-icon style="margin-left: 87px;color:#ef205f;">access_time</mat-icon>
            {{ events[0]?.eventDateTime | date:'hh:mm:a' }}
          </p>
          
        <img *ngIf="events[0]?.poster" [src]="events[0]?.poster" alt="Event Image" style="width: 100%;height:350px;    border: 1px solid #3e101e;">
        <p style="margin-top:10px;">{{ events[0]?.description }}</p>
    </div>
</mat-dialog-content>

