<div class="container">
  <div class="card">
    <h2>Attendance Report</h2>
    <div class="chart">
      <canvas #attendanceChart></canvas>
    </div>
    <div class="legend">
      <div class="legend-item">
        <div class="legend-color" style="background-color: #007bff;"></div>
        <span style=" color: #8d8d98;">Attendance: {{attendanceData?.presentDays}}</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: #ffc107;"></div>
        <span style=" color: #8d8d98;">Half Day: {{attendanceData?.halfDay}}</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: #333;"></div>
        <span style=" color: #8d8d98;">Absent: {{attendanceData?.absent}}</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: #333;"></div>
        <span style=" color: #8d8d98;">Late: {{attendanceData?.monthlyLateCount}}</span>
      </div>
    </div>
  </div>
  <div class="card">
    <h2>Meeting Stats</h2>
    <div class="chart">
      <canvas #meetingChart></canvas>
    </div>
    <div class="legend">
      <div class="legend-item">
        <div class="legend-color" style="background-color: #007bff;"></div>
        <span style=" color: #8d8d98;">Presence: {{attendanceData?.presentMeetings}}</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: #ffc107;"></div>
        <span style=" color: #8d8d98;">Absent: {{attendanceData?.absentMeetings}}</span>
      </div>
    </div>
  </div>
</div>
