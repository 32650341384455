import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LeaveModalComponent } from '../leave-modal/leave-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-show-leave-request',
  templateUrl: './show-leave-request.component.html',
  styleUrls: ['./show-leave-request.component.css','/../../../shared/all-style/all-style.component.css'],
})
export class ShowLeaveRequestComponent implements OnInit {
  userId: string;
  id = localStorage.getItem('id');
  date: any;
  data: any;
  localDateTime: any;
  dates: any[] = [];
  leavedata: any[] = [ {dates: [{ date: [] }],},];
  // filteredData: any[] = [];
  // totalElements: number = 0;
  // pageSize: number = 0;
  // pageNumber: number = 0;

  filteredData: any[] = [];
  totalElements: number = 0;
  pageSize: number =10;
  pageNumber: number = 0;
  constructor(
    private http: HttpClient,
    private mainService: MainService,
   
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
   this.fetchData();
  }

  fetchData(): void {
    console.log("Number : "+this.pageNumber)
    this.mainService.getPendingLeaves( this.pageNumber, this.pageSize).subscribe((res: any) => {
      this.data = res;
      this.filteredData = this.data.content;
      this.totalElements = this.data.totalElements;
      console.log("hello"+JSON.stringify(this.data));
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }
  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  getpendingtype(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      default:
        return status;
    }
  }
  getLeaveTypeText(leaveType: string): string {
    switch (leaveType) {
      case 'HALF_DAY':
        return 'Half Leave';
      case 'ABSENT':
        return 'Absent';
      case 'SHORT_LEAVE':
        return 'Short Leave';
      case 'PAID_LEAVE':
        return 'Paid Leave';
      default:
        return leaveType;
    }
  }
  openUserModal(reason: string, status: string,userName:string,leaveType:string,createdDate) {
    this.dialog.open(LeaveModalComponent, {
        width: '700px',
        data: { reason ,status,userName,leaveType,createdDate} 
    });
}
}
