import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-employee-status',
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.css']
})
export class EmployeeStatusComponent implements OnInit {
  data: any;
  subchildData: any;
  managerId: string | null = localStorage.getItem('id');
  selectedManagerId: string | null = null;
  showManagerSelect: boolean = true; 
  pageNumber:number=0;
  pageSize:number=10;

  totalElements:number = 0;
  @ViewChild('selectEmployee') selectEmployeeRef!: ElementRef<HTMLSelectElement>;
  @ViewChild('subchildSelect') subchildSelectRef!: ElementRef<HTMLSelectElement>;
  datePipe: any;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    // Load initial employee data
    this.loadManagerList(this.managerId, true,this.pageNumber,this.pageSize);
  }

  loadManagerList(managerId: string | null, subChild: boolean,pageNumber,pageSize): void {
    if (managerId) {
      this.mainService.managerList(managerId, subChild,pageNumber,pageSize).subscribe(
        (res: any) => {
          this.data = res.content;
          this.totalElements = res.totalElements;

          console.log('Data received:', this.data);
        },
        (err: any) => {
          console.error('Error fetching manager list', err);
        }
      );
    }
  }
  getShowingTo(): number {
    // console.log("hello"+this.pageSize +" page Number" + this.pageNumber +"totalelements"+this.totalElements);
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
    
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.loadManagerList(this.managerId,true,this.pageNumber,this.pageSize);
  }
  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(
      date,
      "hh:mm a"
    );
  }
}
