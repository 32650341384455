import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css']
})
export class AddTaskComponent implements OnInit {
  assignedById = localStorage.getItem('id');
  isSubmitting = false;
  employee: string;
  deadLine: string;

  public user = {
    employee: '',
    date: '',
    time: '',
    subject: '',
    fileUrl: '',
    deadLine: '',
    assignedById: this.assignedById,
  };

  data: any;
  files: File[] = [];

  constructor(private snack: MatSnackBar, private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
    this.mainService.managerAlllist(this.assignedById, true).subscribe((res: any) => {
      this.data = res;
    });
  }

  onSelect(event) {
    const maxFileSize = 300 * 1024; // 300 KB
    if (event.addedFiles.length > 1) {
      Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
      return;
    }
    const fileToAdd = event.addedFiles[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    if (fileToAdd.type === 'application/pdf') {
      Swal.fire('Error', 'You cannot upload PDF files.', 'error');
      return;
    }
    this.files = [fileToAdd];
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  formSubmit() {
    if (this.files.length === 0 && !this.user.subject) {
      Swal.fire('Error', 'Please select at least one file or enter a task', 'error');
      return;
    }
    if (this.user.deadLine) {
      this.user.deadLine = new Date(this.user.deadLine).getTime().toString();
    }
    this.isSubmitting = true;
    this.uploadFiles();
  }

  uploadFiles() {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    if (this.files.length === 0) {
      this.addTask();
      return;
    }

    this.files.forEach((file) => {
      const folderName = 'taskDocs';
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          const secureUrl = res.message;
          documentsArray.push(secureUrl); 

          if (++processedFiles === this.files.length) {
            this.user.fileUrl = documentsArray[0];
            this.addTask();
          }
        },
        (error) => {
          Swal.fire('Error', 'File upload failed. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    });
  }

  addTask() {
    const userId = this.employee || this.assignedById;
    this.mainService.addtask(userId, this.user).subscribe(
      (res) => {
        Swal.fire('Success', 'Task added successfully', 'success');
        this.router.navigate(['manager-dashboard/task']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to add task. Please try again.', 'error');
        this.isSubmitting = false;
      }
    );
  }
}
