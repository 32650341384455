<h4> All Candidate</h4> 

  <button mat-mini-fab (click)="navigateToAddUserProfile()">
    <mat-icon>person_add</mat-icon>
  </button>
  <button mat-mini-fab (click)="showFilterForm = !showFilterForm" style="margin-left:10px">
    <mat-icon>filter_alt</mat-icon>
  </button>
  <div class="filter-form-wrapper" *ngIf="showFilterForm" style="display: flex; align-items: center; margin-top: 20px;">
    <form #filterForm="ngForm" (submit)="candidateFilter()" style="display: flex;">
      <div class="form-field" style="margin-right: 10px;">
        <label for="start_date">Date From:</label>
        <input name="start_date" [(ngModel)]="startDate" type="date" class="form-control" />
      </div>
      <div class="form-field" style="margin-right: 10px;">
        <label for="end_date">Date To:</label>
        <input name="end_date" [(ngModel)]="endDate" type="date" class="form-control" />
      </div>
      <button mat-raised-button  color="accent"  type="submit">Filter</button>
    </form>
  </div>


<div class="container2" style="margin-top:20px">
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Date</th>
        <th>Name</th>
        <th>Mobile No</th>
        <th>Designation</th>
        <th>Email</th>
        <th>Experience</th>
        <th>Location</th>
        <th>Current CTC</th>
        <th>Expected CTC</th>
        <th>Notice Period</th>
        <th>LinkedIn</th>
        <th>Action</th>
    </tr>
    
  </thead>

    <tbody>
      <tr *ngFor="let data of data" class="data-row">
        <td> {{ data.id }}</td>
        <td>{{ data.createdDateTime | date : "dd-MMM-yyyy" }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.mobile }}</td>
        <td>{{ data.designation }}</td>
        <td>{{ data.email }} </td>
        <td>{{ data.experience }}</td>
        <td>{{ data.location }}</td>
        <td>{{ data.currentCTC }}</td>
        <td> {{ data.expectedCTC }}</td>
        <td>{{ data.noticePeriod }}</td>
        <td>{{ data.linkedIn }}</td>
        <td>
          <button mat-button [matMenuTriggerFor]="menu"><mat-icon class="more-icon">more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/update-candidate']" [queryParams]="{ id: data.id }">
              <mat-icon mat-list-icon>update</mat-icon>Status Update
            </button>
            <button mat-menu-item (click)="ondelete(data.id)">
              <mat-icon mat-list-icon>delete</mat-icon>Delete
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>

