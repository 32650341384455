<div class="row">
  <h1>Current Openings</h1>
  <div class="container container2">
    <div class="scrollable-content">
      <div class="all-card">
    <div class="card" *ngFor="let candidate of candidates">
      <div class="card-header">
        <img src="../../../../assets/Rectangle.png" alt="GitHub">
        <div class="card-title">
          {{ candidate.designation }}<br>
          <span>posted {{ candidate.createdDate | timeAgo }}</span>
        </div>
        <div class="dots">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon button with a vertical three dot icon" class="menu-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          
          <mat-menu #menu="matMenu" class="custom-menu">
            <button mat-menu-item class="menu-item active-item" (click)="updateStatus(candidate.vacancyId, 'true')">
              <mat-icon>check_circle</mat-icon>
              <span>Active</span>
            </button>
            <button mat-menu-item class="menu-item inactive-item" (click)="updateStatus(candidate.vacancyId, 'false')">
              <mat-icon>cancel</mat-icon>
              <span>InActive</span>
            </button>
          </mat-menu>
        </div>
        <!-- updatevacancy -->
      </div>
      <div class="info" style="margin-top: 45px;">
        <div class="card-info">
          <span><img src="../../../../assets/location (1).png"> {{candidate.location}}</span>
          <span><img src="../../../../assets/employee.png"> {{candidate.experience}}</span>
          <span>{{candidate.applicationCount}} applications</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="add-new" (click)="openModalDialog(null)">
        Add New
      </div>
    </div>
  </div>
</div>
  </div>
  <div class="header-row" style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 50px; border-radius: 8px; border-bottom: none; color: whitesmoke">
    <h1>Candidates</h1>
    
  </div>
  <div class="button-group" style="display: flex; gap: 10px;width:100%;overflow-y: auto;">
    <button class="custom-button" (click)="toggleFilters()" style="background-color: #ce3262;">Filter</button>
    <div *ngIf="showFilters" class="filter-container" style="    display: contents;">
    <button mat-button class="custom-button" (click)="openSaveDialog()">
      <img src="../../../../assets/add.png"> Add new
    </button>
    
   
    <button mat-button class="custom-button">
      Designation 
      <select [(ngModel)]="designation" (change)="applyFilters()">
        <option [value]="null">All</option>
        <option *ngFor="let candidate of candidates" [value]="candidate.designation">{{candidate.designation}}</option>
      </select>
    </button>
    
    <button mat-button class="custom-button">
      Status 
      <select [(ngModel)]="status" (change)="applyFilters()">
        <option value="SELECTED">Selected</option>
        <option value="REJECTED">Rejected</option>
        <option value="PENDING">Pending</option>
        <option value="INPROGRESS">In Progress</option>
        <option value="HIRED">Hired</option>
        <option value="SCREENING">Screening</option>
      </select>
    </button>
    
    <button mat-button class="custom-button">
      Start Date 
      <input type="date" [(ngModel)]="startDate" (change)="applyFilters()" />
    </button>
    
    <button mat-button class="custom-button">
      End Date 
      <input type="date" [(ngModel)]="endDate" (change)="applyFilters()" />
    </button>
    
    <!-- Active/Inactive Filter -->
    <button mat-button class="custom-button">
      Active/Inactive 
      <select [(ngModel)]="isActiveVacancy" (change)="applyFilters()">
        <option [value]="null">All</option>
        <option value="true">Active</option>
        <option value="false">Inactive</option>
      </select>
    </button>
    
    <!-- AI Status Filter -->
    <button mat-button class="custom-button">
      AI Status 
      <select [(ngModel)]="hiringAIStatus" (change)="applyFilters()">
        <option value="SELECTED">Selected</option>
        <option value="REJECTED">Rejected</option>
        <option value="AVERAGE">Average</option>
      </select>
    </button>
    
  </div>
  </div>
  <div class="container-fluid">
    <div class="filter" style="display: flex;">
      <div class="navbar-nav">
      <span  class="nav-item" (click)="filterStatus('--')" >All</span>
      <span   class="nav-item" (click)="filterStatus('SELECTED')">Accepted</span>
      <span   class="nav-item" (click)="filterStatus('REJECTED')" >Rejected</span>
    </div>
  </div>

  </div>
  <div class="container2">
    <div class="scrollable-content">
    <table class="candidates-table ">
      <thead>
        <tr>
          <th>E. ID</th>
          <th>Name</th>
          <th>Designation</th>
          <th>Source</th>
          <th>Acceptance Score</th>
          <th>Application Date</th>
          <th>URLS</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of hiring">
          <td>{{ candidate.id }}</td>
          <td>
            {{ candidate.name }}
            <a [href]="candidate.resume" target="_blank">
              <img src="../../../../assets/attach-file.png" alt="Resume" style="margin-left: 10px;">
            </a>
          </td>
          
          <td>{{ candidate.designation }}</td>
          <td>{{ candidate.createdByName }}</td>
          <!-- <td>
            <div class="acceptance-score">
              {{ candidate.matchedPercentage ? }}%
            </div>
          </td> -->
          <td>
            <div class="acceptance-score">
              <mat-icon style="font-size:9px" [ngClass]="{
                              'green': candidate.progressStatus === 'INPROGRESS',
                              'red': candidate.progressStatus === 'REJECTED',
                              'magenta': candidate.progressStatus === 'SELECTED'
                            }">circle</mat-icon>
              {{ candidate.matchedPercentage ? candidate.matchedPercentage.toFixed(2) : '0.00' }}%
            </div>
          </td>
          
          
          
          <td>{{ candidate.createdTime | date: 'dd/MM/yy' }}</td>

          <td>
            <div class="urls">
              <a *ngIf="hasUrl(candidate.urls, 'github')" [href]="getUrl(candidate.urls, 'github')" target="_blank">
                <img src="../../../../assets/github.png" alt="GitHub">
              </a>
              <a *ngIf="hasUrl(candidate.urls, 'linkedin')" [href]="getUrl(candidate.urls, 'linkedin')" target="_blank">
                <img src="../../../../assets/linkedin.png" alt="LinkedIn">
              </a>
              <a *ngIf="hasUrl(candidate.urls, 'portfolio')" [href]="getUrl(candidate.urls, 'portfolio')" target="_blank">
                <img src="../../../../assets/twitter.png" alt="Portfolio">
              </a>
            </div>
            
          </td>
          <td>{{ candidate.autoStatus }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination-container">
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>
