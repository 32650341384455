<h4> Meetings</h4>
<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Meeting List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
      <mat-icon>add</mat-icon> Add Meeting
    </button>
  </div>
</div>
<div class="filter-form-wrapper" *ngIf="showFilterForm" >
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()">
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="dateRange" style="color:black">Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style="width: 100%; margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
          style="width: 100%;"
        />
      </div>
    </div>

    <!-- Status -->
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="status">Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control" style="width: 100%;">
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="SCHEDULED">Scheduled</option>
        <option value="POSTPONED">Postponed</option>
        <option value="SUSPENDED">Suspended</option>
        <option value="DONE">Done</option>
      </select>
    </div>
    <div class="form-group">
      <label>Select  Employee</label>
      <select id="userId" name="employeeId" [(ngModel)]="user_id" >
        <option value="">Select Employee</option>
        <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div>
    
    <!-- Clear button -->
    <div style="display: flex; justify-content: space-between;">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
  <div class="container2" style="margin-top: 30px;">
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>UserIds</th>
          <th>Meeting Time</th>
          <th>Purpose</th>
          <th>Location</th>
          <th>Description</th>
          <th>Assigned User</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredData" class="data-row">
          <td>{{ data.id }}</td>
          <td>{{ data.meetingTime |date: 'dd-MMM-yyyy HH:mm a'  }}</td>
          <td>{{ data.purpose }}</td>
          <td>{{ data.location }}</td>
          <td>{{ data.description }}</td>
          <td>
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon>event</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let user of data.assignedUsers">
                <mat-icon 
                  class="user-icon" 
                  [ngClass]="{'green': user.isUserPresent, 'red': !user.isUserPresent}"
                >circle</mat-icon>
                {{ user.assignToName }}
              </button>
            </mat-menu>
          </td>
            <td>
              <ng-container *ngFor="let user of data.assignedUsers">
                <mat-slide-toggle [checked]="user.isUserPresent" *ngIf="user.assignToId == id.toString()" (change)="togglePresence(data,user)">
                  {{ user.isUserPresent ? 'Present' : 'Absent' }}
                </mat-slide-toggle>
              </ng-container>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>

