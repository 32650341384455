<h4>Your Leaves</h4>
  <div class="container2" style="margin-top: 40px;">
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>Apply Date</th>
          <th>Employee Name</th>
          <th>Reason</th>
          <th>Leave Type</th>
          <th>Requested date</th>
          <th>Comments</th>
          <th>By</th>
          <th>Manager Status</th>
          <th>Status</th>
          <th><mat-icon mat-list-icon>edit</mat-icon>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <td>{{ data.createdDate | date:'dd-MMM-yyyy hh:mm:a' }}</td>
          <td>{{ data.userName }}</td>
          <td>{{ data.reason }}</td>
          <td>{{ getLeaveTypeText(data.leaveType) }}</td>
          <td><button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let leaveDate of data.leaveDates" style="color: black;">
              {{ leaveDate }}
            </button>
          </mat-menu>
          
          </td>
          <td>{{ data.comment }}</td>
          <td>{{data.approvedByName}}</td>
          <td>{{data.managerStatus}}</td>
          <td style="width: 112px;color:white;" [style.background-color]="
            data.status === 'PENDING'
              ? 'rgb(103, 59, 141)'
              : data.status === 'APPROVED'
              ? 'rgb(106, 106, 152)'
              : 'rgba(103, 59, 141, 0.67)'
          ">
            {{getpendingtype(data.status)}}
          </td>
          <td>
            <button mat-button [matMenuTriggerFor]="actionMenu"><mat-icon mat-list-icon>more_vert</mat-icon></button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item [routerLink]="['/manager-dashboard/leaveapprove-dialog']" [queryParams]="{ id: data.userId, reason: data.reason, leave_id: data.id, todate: data.leaveDates[1], fromdate: data.leaveDates[0], leaveType: data.leaveType }"> <mat-icon mat-list-icon>update</mat-icon>Approve</button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
    <div class="pagination-container">
      <div class="pagination-info">
        Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
      </div>
      <mat-paginator 
        [length]="totalElements" 
        [pageSize]="pageSize" 
        [pageSizeOptions]="[10, 20, 50, 100]" 
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>

