<h4>My Profile</h4>
<mat-card *ngIf="user" class="profile-card">
  <div class="row" style="padding: 0 92px;">
    <div class="col-md-12 position-relative first-div" style="background-image: url('assets/Cover.png');">
      <div class="profile-image">
        <img 
          [src]="user?.profileImage || 'assets/12.png'" 
          alt="Profile Picture" 
          style="width: 100px; height: 102px; border-radius: 50%; position: absolute; background-color: rgba(173, 168, 214, 1); top: 58px; left: 13px; " 
        />
        <img class="edit-icon" src="assets/Icon.png" alt="edit icon" (click)="onEditClick()" style="cursor: pointer;"/>
      </div>
      <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

      <!-- <div class="delete-icon" (click)="onDeleteImage(user.profileImage)" style="cursor: pointer;">
        <img src="assets/deleteicon.png" alt="Delete Icon" />
      </div> -->
      
      
    </div>

    <!-- Second Div with Employee Details -->
    <div class="col-md-12 container2 second-div">
      <div class="scrollable-content">
      <h2 class="text-center">Employee Details</h2>
      <div class="row" style="    padding: 42px;  margin-top: -46px;">
        <!-- Personal Information -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Personal Information</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Employee ID :</div>
                  <div class="value">{{ user.id }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Full Name :</div>
                  <div class="value">{{ user.firstName }} {{ user.lastName }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Date of Birth :</div>
                  <div class="value">{{ user.dob | date:'dd-MMM-yyyy' }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Gender :</div>
                  <div class="value">{{ user.gender }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Designation :</div>
                  <div class="value">{{ user.designation }}</div>
                </div>
                <div class="col-md-6" style="display: flex;">
                  <div class="label">Status:</div>
                  <div class="value">
                    <button *ngIf="user.active" style="background-color: green; border: none; border-radius: 50%; width: 24px; height: 24px;">
                      <span style="color: white; font-size: 16px;">✓</span>
                    </button>
                    <button *ngIf="!user.active" style="background-color: red; border: none; border-radius: 50%; width: 24px; height: 24px;">
                      <span style="color: white; font-size: 16px;">✕</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Contact Information & Address -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Contact Information & Address</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Phone Number :</div>
                  <div class="value">{{ user.userName }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Alternate Number :</div>
                  <div class="value">{{ user.phone }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Email :</div>
                  <div class="value">{{ user.email }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Current Address :</div>
                  <div class="value">{{ user.currentAddress }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Permanent Address :</div>
                  <div class="value">{{ user.permanentAddress }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Employment Details -->
        <div class="col-md-12 details-section">
          <div class="custom-head">
            <h3>Employment Details</h3>
          </div>
          <div class="row" style="border: 1.78px solid #857e7e; border-radius: 0px 0px 8px 8px; background: #161515; padding: 28px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Joining Date :</div>
                  <div class="value">{{ user.joiningDate | date:'dd-MMM-yyyy' }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Job Title :</div>
                  <div class="value">{{ user.designation }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Department :</div>
                  <div class="value">{{ user.department }}</div>   
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Oraganization Name :</div>
                  <div class="value">{{ user.organizationName }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Department Head :</div>
                  <div class="value">{{ user.department }}</div>
                </div>
                <div class="col-md-6" style="display:flex;">
                  <div class="label">Organization  Email :</div>
                  <div class="value">{{ user.organizationEmail }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</mat-card>
