<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

<div class="card task-card" style="margin-top: 30px;">
  <div class="card-body">
    <form (ngSubmit)="formSubmit()">
      <h2 class="form-heading text-center">Update Task</h2>
      
      <!-- Employee Selector -->
      <!-- <div class="form-group">
        <label for="employee">Select Employee</label>
        <select name="employee" id="employee" [(ngModel)]="updateUser.employee" class="form-control" required>
          <option value="" disabled>Select Employee</option>
          <option *ngFor="let employee of employees" [value]="employee.id">{{employee.userName}}</option>
        </select>
      </div> -->
      
      <!-- Task Name -->
      <div class="form-group">
        <label for="subtaskName">Task Name</label>
        <input [(ngModel)]="updateUser.subtaskName" name="subtaskName" type="text" id="subtaskName" class="form-control" placeholder="Enter task name" required>
      </div>
      
      <!-- Task Deadline -->
      <div class="form-group">
        <label for="taskDeadline">Task Deadline</label>
        <input [(ngModel)]="updateUser.taskDeadline" name="taskDeadline" type="date" id="taskDeadline" class="form-control" required>
      </div>
      <div class="form-group">
        <label for="taskPriority">Task Priority Level</label>
        <select [(ngModel)]="updateUser.priorityLevel" name="priorityLevel" id="taskPriority" class="form-control" required>
          <option value="LOW">Low</option>
          <option value="MEDIUM">Medium</option>
          <option value="HIGH">High</option>
        </select>
      </div>
      
      <!-- Task Description -->
      <div class="form-group">
        <label for="subtaskDescription">Task Description</label>
        <textarea [(ngModel)]="updateUser.subtaskDescription" name="subtaskDescription" id="subtaskDescription" class="form-control" placeholder="Enter task description" rows="5" required></textarea>
      </div>
      
      <!-- Task Files -->
      <div class="form-group">
        <label for="taskFiles">Task Files</label>
        <ngx-dropzone (change)="onSelect($event)" class="custom-dropzone">
          <ngx-dropzone-label><i class="fas fa-cloud-upload-alt"></i><br>Drop Task Files</ngx-dropzone-label>
          <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
      </div>
      
      <!-- Save Button -->
      <div class="form-group text-center">
        <button type="submit" class="btn btn-primary" [disabled]="isSubmitting">Save Task</button>
        <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
      </div>
    </form>
  </div>
</div>
