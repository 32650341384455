<div class="employee-details">
    <div class="lsit-data" style="display: flex; justify-content: space-between;">
        <h2>Employee Today Attendance</h2>
    </div>
    <div  class="container2">
        <div class="scrollable-content">
        <table>
            <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Manager Name</th>
                    <th>Designation</th>
                    <th>In Time</th>
                    <th>Out Time</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="6" style="height: 28px;">
                        <hr style="width: 100%; border: 0.20px solid #353535; margin: 0;">
                    </td>
                </tr>
                <tr *ngFor="let empData of data">
                    <td>
                        <img class="myImage" *ngIf="empData.profile" style="height: 69px; width: 69px; border-radius: 50%;" [src]="empData.profile" alt="Profile Picture">
                        {{ empData.userName }}
                    </td>
                    <td>{{empData.managerName}}</td>
                    <td>{{ empData.designation }}</td>
                    <td>{{ empData.inTime }}</td>
                    <td class="td_center" *ngIf="empData.inTime">
                      {{ empData.inTime | date : "hh:mm a" }}
                    </td>
                    <td class="td_center" *ngIf="empData.outTime">
                      {{ empData.outTime | date : "hh:mm a" }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</div>
<div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Attendance
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
