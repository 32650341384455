  
    <h4
      style="
        text-align: center;
        font-size: 30px;
        margin-top: -31px;
        color: black;
        margin-bottom: -1px;
      "
    >
      Attendance
    </h4>
<div class="container">
  <div class="Salary-details" style="width: 100%"></div>

  <div class="container2">
    <table>
      <thead>
        <tr>
          <th class="bg">Id</th>
          <th class="bg">Name</th>
          <th class="bg">Mobile</th>
          <th class="bg">In Time</th>
          <th class="bg">Out time</th>
          <th class="bg">Date</th>
          <th class="bg">Working Hour</th>
          <th class="bg">All Attendance</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of data">
          <th>{{ data.id }}</th>
          <!--  -->
          <th>{{ data.firstName }} {{ data.lastName }}</th>
          <th>{{ data.phone }}</th>
          <th>{{ data.ntime }}</th>
          <th>{{ data.otime }}</th>
          <th>{{ data.date }}</th>
          <th>{{ data.workingHour }}</th>

          <th>
            <button
              class="button1"
              [routerLink]="['/admin/single-employee-attendance']"
              [queryParams]="{ phone: data.phone }"
            >
              <a> All Attendance<mat-icon mat-list-icon></mat-icon></a>
            </button>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>
