<h4>
  Messages
 </h4>
<div class="meetingContainer">
  
  <form [formGroup]="form" (ngSubmit)="formSubmit($event)">
    <div>
      <label for="subject">Purpose </label>
      <input type="text" id="purpose" formControlName="purpose">
    </div>
    <div>
      <label for="message">Message:</label>
      <textarea id="message" formControlName="message"></textarea>
    </div>
    <label for="message">Select Employee:</label>
    <mat-form-field  class="white-text" class="w100" appearance="outline" >
      <mat-label class="white-text" >Select Employees</mat-label>
      <mat-select [formControl]="selectedEmployeesControl" class="white-text"  style="background: white;" multiple>
        <mat-option *ngFor="let employee of data" [value]="employee" style="text-transform:capitalize;background: white;" >
          ID: {{ employee.id }} - Name: {{ employee.userName }} 
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)"  style="height: 100px;">
      <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Images</ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngIf="file" [file]="file" [removable]="true" (removed)="onRemove($event)">
        <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </div> -->
    <div>
      <label for="message">Upload File:</label>
    <input
    id="file"
    type="file"
    (change)="onSelect($event)"
  
  />
</div>
    <button type="submit"  class="submit-button" >Submit</button>
  </form>
</div>
