import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient } from '@angular/common/http';
import { ElementRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  userId: any;
  selectedOption: string = 'Total';
  yearlyTaskCount: number = 0;
  monthlyTaskCount: number = 0;
  showOptions: boolean = false;
  totalPendingTasks: number = 0;
  totalUserPresent: number = 0;
  todayMeetingCount: number = 0;
  interviewScheduled: number = 0;
  getPendingLeaves: number = 0;
  modalVisible: boolean = false;
  status:any;
  hostId:any;
  vacancyId:any;
  isActiveVacancy:any;
  hiringAIStatus:any;
  startOfDay:any;
  designation:any;
  @ViewChild('button') button: ElementRef;

  // pastData: any = {};
  latestData: any= {};


  constructor(private mainService: MainService, private http: HttpClient,private dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchTotalTaskCount();
    this.fetchTotalUserPresent();
    this.fecthtodayMeetingCount();
    // this.InterviewSchedule();
    this.GetPendingLeaves();
    // setTimeout(() => {
    //   this.openDialog();
    // }, 1000); // 1000 milliseconds = 1 second

    // this.fetchInstagramCounts();

    // this.mainService.getpastMedia().subscribe(
    //   (res: any) => {
    //     this.pastData = res;
    //     console.log('getpast', this.pastData);
    //   },
    //   (error) => {
    //     console.error('Error fetching total task count', error);
    //   }
    // );
    this.mainService.getLatestMedia().subscribe(
      (res: any) => {
        this.latestData = res;
      },
      (error) => {
        console.error('Error fetching total task count', error);
      }
    );

  }
  // openDialog() {
  //   throw new Error('Method not implemented.');
  // }

  fetchTotalTaskCount() {
    this.mainService.totaltask().subscribe(
      (res: any) => {
        this.totalPendingTasks = res;
      },
      (error) => {
        console.error('Error fetching total task count', error);
      }
    );
  }

  selectOption(option: string) {
    this.selectedOption = option;

    if (option === 'Yearly') {
      this.mainService.totaltask().subscribe(
        (res: any) => {
          this.yearlyTaskCount = res;
        },
        (error) => {
          console.error('Error fetching yearly task count', error);
        }
      );
    } else if (option === 'Monthly') {
      this.mainService.totaltaskmonthly().subscribe(
        (res: any) => {
          this.monthlyTaskCount = res;
        },
        (error) => {
          console.error('Error fetching monthly task count', error);
        }
      );
    } else {
      this.fetchTotalTaskCount();
    }
  }

  fetchTotalUserPresent() {
    this.mainService.totaluserPresent().subscribe(
      (res: any) => {
        this.totalUserPresent = res;
      },
      (error) => {
        console.error('Error fetching total user present count', error);
      }
    );
  }


  fecthtodayMeetingCount(): void {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime().toString();
    const endOfDay = new Date(today.setHours(23, 59, 59, 999)).getTime().toString();
  
    this.mainService.showMeetings('SCHEDULED', this.userId, this.userId, startOfDay, endOfDay, 0, 500).subscribe(
      (res: any) => {
        this.todayMeetingCount = res.content.filter((meeting: any) => {
          const meetingTime = new Date(meeting.meetingTime).getTime();
          return meetingTime >= parseInt(startOfDay) && meetingTime <= parseInt(endOfDay);
        }).length;
        console.log("testing"+this.todayMeetingCount)
      },
      error => {
        console.error('Error fetching meetings:', error);
      }
    );
  }
  

  InterviewSchedule() {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime().toString();
    const endOfDay = new Date(today.setHours(23, 59, 59, 999)).getTime().toString();
    this.mainService.getAllhiring( this.userId,   this.vacancyId, this.isActiveVacancy,  this.hiringAIStatus, this.status, startOfDay,  this.designation,  endOfDay, 0, 100).subscribe(
      (res: any) => {
        this.interviewScheduled = res.content.filter((interview:any)=>{
          const interviewDate = new Date(interview.interviewDate).getTime();
          return interviewDate >= parseInt(startOfDay) && interviewDate <= parseInt(endOfDay);
        }).length;
        console.log("countinterview"+this.interviewScheduled)
      },
      (error) => {
        console.error('Error fetching interview scheduled count', error);
      }
    );
  }

  GetPendingLeaves() {
    this.mainService.getPendingLeaveswelcome().subscribe(
      (res: any) => {
        this.getPendingLeaves = res;
      },
      (error) => {
        console.error('Error fetching pending leaves count', error);
      }
    );
  }


  openModal() {
    this.modalVisible = true;
  }

  // openDialog() {
  //   this.dialog.open(NewComponent);
  //   throw new Error('Method not implemented.');
  // }

  // fetchInstagramCounts() {
  //   const url = 'https://www.instagram.com/deepalimishra4331/';



  //   this.http.get(url, { responseType: 'text' }).subscribe(
  //     (htmlContent: string) => {
  //       // Extract follower count
  //       const followerCountMatches = htmlContent.match(/"edge_followed_by":{"count":(\d+)}/);
  //       this.followerCount = followerCountMatches && followerCountMatches[1] ? parseInt(followerCountMatches[1]) : 0;

  //       // Extract following count
  //       const followingCountMatches = htmlContent.match(/"edge_follow":{"count":(\d+)}/);
  //       this.followingCount = followingCountMatches && followingCountMatches[1] ? parseInt(followingCountMatches[1]) : 0;
  //     },
  //     (error) => {
  //       console.error('Error fetching Instagram profile page:', error);
  //     }
  //   );
  // }
}
