import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-assign-task',
  templateUrl: './assign-task.component.html',
  styleUrls: ['./assign-task.component.css']
})
export class AssignTaskComponent implements OnInit {
  data: any[] = [];
  user_id: any;
  subject: string = '';

  constructor(private mainService: MainService, private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe((res: any[]) => {
      this.data = res;
    }); 
  }

  formSubmit(event: Event) {
    event.preventDefault();
    const arrObj = {
      id: this.user_id,
      subject: this.subject
    };

    this.mainService.assignTask(this.user_id, arrObj).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Success!', 'Task assigned successfully!', 'success');
        this.router.navigate(['/admin/showuser-task']);
      },
      (error) => {
        Swal.fire('Error', 'Failed to assign task: ' + error.error.message, 'error');
      }
    );
  }
}
