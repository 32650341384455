import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-candidate',
  templateUrl: './update-candidate.component.html',
  styleUrls: ['./update-candidate.component.css']
})
export class UpdateCandidateComponent implements OnInit {
  userId: string;
  data: any;
  id = localStorage.getItem('id');
  phone = localStorage.getItem('phone');

  time: any;
  user: any;
  meridiem: any;

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    // console.log("-------+ userId= " + this.userId);

    this.mainService.getupdatecandidate(this.userId).subscribe(
      (res) => {
        this.data = res;
        JSON.stringify(this.data);
        // console.log('User ID:', this.id);
      }
    );
  }
  navigateToAddUserProfile() {
    this.router.navigate(['/admin/update-candidate']);
  }
  updatecandidate(event: any): void {
    let target = event.target;

    let arrObj: any = {};
    arrObj['id'] = this.userId;
    arrObj['comment'] = target.querySelector('#comment').value || null;
    arrObj['feedback'] = target.querySelector('#feedback').value || null;
    arrObj['feedback2'] = target.querySelector('#feedback2').value || null;
    arrObj['date'] = target.querySelector('#date').value || null;
    arrObj['time'] = target.querySelector('#time').value || null;
    arrObj['status'] = target.querySelector('#status').value || null;

    // console.log(arrObj);

    JSON.stringify(arrObj);

    this.mainService.updateCandidate(this.id, arrObj).subscribe(
      (res: HttpResponse<any>) => {
        JSON.stringify(this.data);
        alert("Successfully updated.");

        this.router.navigate(['/admin/all-candidate']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
