import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

interface User {
  status: string;
}
@Component({
  selector: 'app-update-meeting',
  templateUrl: './update-meeting.component.html',
  styleUrls: ['./update-meeting.component.css']
})
export class UpdateMeetingComponent implements OnInit {
  userId :any;
  data : any;
  id = localStorage.getItem('id');
  public user: User = {
    status: '',
  };
  constructor(private mainService : MainService,private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');

  }
  updatemeetings(event) {
  let target = event.target;
  var arrObj = {};

   arrObj['id'] = this.userId;
  if (this.user.status != null)
  {
    arrObj["status"] = this.user.status;
  }

  this.mainService.UpdateMeetingStatus(this.id,arrObj).subscribe(
    (res :HttpResponse<any>)=>{
      this.data = res;
      JSON.stringify(this.data)
    Swal.fire("Successfully updated.")
      this.router.navigate(['/admin/show-meetings'])
    },
    (error) => {
      console.log(error);
      Swal.fire('Error', ' ' + error.error.message, 'error');
    }
  );
}
}






