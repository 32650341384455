<h4>Meetings</h4>
<div class="header-row" >
  <h5>Meeting List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
      <mat-icon>add</mat-icon> Add Meeting
    </button>
  </div>
</div>
<div class="filter-form-wrapper" *ngIf="showFilterForm" >
  <!-- Close icon -->

  <div class="filter-btn">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" >
    <!-- Date range -->
    <div class="form-group" >
      <label for="dateRange" >Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style=" margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>

    <!-- Status -->
    <div class="form-group" >
      <label for="status" >Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control selector" >
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="SCHEDULED">Scheduled</option>
        <option value="POSTPONED">Postponed</option>
        <option value="SUSPENDED">Suspended</option>
        <option value="DONE">Done</option>
      </select>
    </div>
    <div >
      <select id="userId" name="employeeId" [(ngModel)]="userId" >
        <option value="">Select Employee</option>
        <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div>
    
    <!-- Clear button -->
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" class="selector">Filter</button>
    </div>

  </form>

</div>




<!-- <div style="display: flex; align-items: center;">
  <button mat-mini-fab routerLink="/admin/meeting">
    <mat-icon>add_chart</mat-icon>
  </button>
  <button mat-mini-fab (click)="showFilterForm = !showFilterForm" style="margin-left:10px">
    <mat-icon>filter_alt</mat-icon>
  </button>
  <div class="filter-form-wrapper" *ngIf="showFilterForm" style="display: flex; align-items: center; margin-top: 20px;">
    <form #filterForm="ngForm" (submit)="meetingFilter()" style="display: flex;">
      <div class="form-field" style="margin-right: 10px;">
        <label for="start_date">Date From:</label>
        <input name="start_date" [(ngModel)]="startDate" type="date" class="form-control" />
      </div>
      <div class="form-field" style="margin-right: 10px;">
        <label for="end_date">Date To:</label>
        <input name="end_date" [(ngModel)]="endDate" type="date" class="form-control" />
      </div>
      <button mat-raised-button color="accent" type="submit">Filter</button>
    </form>
  </div>
</div> -->

<div class="container2" style="margin-top:30px">
  <div class="scrollable-content">
  <table>
    <thead>
      <tr>
        <th>MeetingsIds</th>
        <th>Meeting Time</th>
        <th>Purpose</th>
        <th>Location</th>
        <th>Description</th>
        <th>Assigned User</th>
        <th>Admin Response</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of meeting" class="data-row">
        <td>{{ data.id }}</td>
        <td>{{ data.meetingTime | date: 'dd-MMM-yyyy HH:mm a' }}</td>
        <td>{{ data.purpose }}</td>
        <td>{{ data.location }}</td>
        <td>{{ data.description }}</td>
        <td>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let user of data.assignedUsers">
              <mat-icon 
                class="user-icon" 
                [ngClass]="{'green': user.isUserPresent, 'red': !user.isUserPresent}"
              >circle</mat-icon>
              {{ user.assignToName }}
            </button>
          </mat-menu>
        </td>
        
        
        <td [ngStyle]="{ backgroundColor: getStatusColor(data.status) }">
          {{ data.status }}
        </td>
        <td>
          <button mat-button [matMenuTriggerFor]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/update-meetings']" [queryParams]="{ id: data.id }">
              <mat-icon mat-list-icon>update</mat-icon>Update
            </button>
            <button mat-menu-item (click)="ondelete(data.id)">
              <mat-icon mat-list-icon>delete</mat-icon> Delete
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>  
<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>