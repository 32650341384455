<h2 mat-dialog-title>Update Status</h2>
<span class="modal-close" (click)="onNoClick()">X</span>
<mat-dialog-content>
  <div class="container">
    <div class="form-group">
      <div class="flex-item">
        <label for="designation">Select Employee</label><br>
        <select [(ngModel)]="selectedManagerId" style="width: 100%;">
          <option *ngFor="let list of managerList" [value]="list.id">
            {{ list.userName }}
          </option>
        </select>
      </div>
      <div class="flex-item">
        <label>Select Status</label>
        <select [(ngModel)]="selectedStatus">
          <option value="SELECTED">Selected</option>
          <option value="SCREENING">Screening</option>
          <option value="INTERVIEW">Interview</option>
          <option value="HIRED">Hired</option>
          <option value="REJECTED">Rejected</option>
        </select>
      </div>
      <div class="flex-item">
        <label>Select Interview Date</label>
        <input type="date" [(ngModel)]="interviewDate" />
      </div>
      <div class="flex-item">
        <label>Comment</label>
        <textarea [(ngModel)]="comments" rows="3" style="width: 100%;background: #282828;
    color: #757575;"></textarea>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
