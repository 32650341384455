<button class="button1" style="margin-top: 36px">
  <a [routerLink]="['/admin']">⇦ Home</a>
</button>
<button class="button2" style="float: right; margin-top: 36px">
  <a class="theme-btn" onclick="history.go(-1)">Back ⇨</a>
</button>
<div class="container3">
  <h4
    style="
      text-align: center;
      font-weight: 500;
      font-size: 30px;
      margin-top: -31px;
      color: black;
      margin-bottom: -1px;
    "
  >
    User Assignment
  </h4>
  <hr class="animated-line" />
</div>
<div class="container">
  <img
    src="../../../../assets/smile-emoji-emoji-smile.gif" alt="emoji"
    style="height: 68px; border-radius: 50%; width: 65px"
  />

  <div class="container2">
    <table>
      <thead>
        <tr>
          <th class="background">Date</th>
          <th class="background">Name</th>
          <th class="background">Assignment</th>
          <th class="background">Description</th>
          <th class="background">Deadline</th>
          <th class="background">Comments</th>
          <th class="background">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td>{{ formatDateTime(item.dateTime) }}</td>
          <td>{{ item.user.firstName }} {{ item.user.lastName }}</td>
          <td>{{ item.task }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.deadline }}</td>
          <td>
            <div id="existingComments">{{ item.comment }}</div>
          </td>
          <td
            style="color: white"
            [style.background-color]="
              item.status === 'Pending'
                ? 'yellow'
                : item.status === 'Reviewed' && item.comment
                ? '#e61212'
                : 'Green'
            "
          >
            {{ item.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
