<div class="container2" *ngIf="hideSidebar">
  <mat-card   >
  <mat-action-list style="background: #161515">
    <div mat-subheader style="color: white">
      Menu
    </div>
  <button [routerLink]="getHomeRoute()"  mat-list-item>
      <mat-icon mat-list-icon style="color: white">home</mat-icon>
      <div class="heading">Home</div>
    </button>

    <button mat-list-item routerLink="user-profile">
      <mat-icon mat-list-icon style="color: white">account_circle</mat-icon>
      <div class="heading">My Profile</div>
    </button>

    <button mat-list-item routerLink="attendance">
      <mat-icon mat-list-icon>fingerprint</mat-icon>
      <div class="heading">Attendance</div>
    </button>

    <button mat-list-item routerLink="user-meeting">
      <mat-icon mat-list-icon>settings_input_antenna</mat-icon>
      <div class="heading">Meetings</div>
    </button>

    <button *ngIf="userRole === 'MANAGER'" mat-list-item routerLink="email-manager">
      <mat-icon mat-list-icon>settings_input_antenna</mat-icon>
      <div class="heading">Email</div>
    </button>
    <button *ngIf="userRole==='MANAGER'" mat-list-item routerLink="project-dashboard">
      <mat-icon mat-list-icon>assignment</mat-icon>
      <div class="heading">Assignment</div>
    </button>
    <!-- <button *ngIf="userRole==='USER'" mat-list-item routerLink="user-assignment">
      <mat-icon mat-list-icon>assignment</mat-icon>
      <div class="heading">Assignment</div>
    </button> -->
    
     
    <button mat-list-item routerLink="task">
      <mat-icon mat-list-icon>spellcheck</mat-icon>
      <div class="heading">Tasks</div>
    </button>
  

    <button mat-list-item routerLink="add-task">
      <mat-icon mat-list-icon>plus_one</mat-icon>
      <div class="heading">Task Addition</div>
    </button>
    <button mat-list-item routerLink="user-salary">
      <mat-icon mat-list-icon>trending_up</mat-icon>
      <div class="heading"> Payroll Panel
      </div>
    </button>

    <button mat-list-item routerLink="user-review">
      <mat-icon mat-list-icon>rate_review</mat-icon><div class="heading">My Performance 
      </div>
    </button>

    <!-- <button mat-list-item routerLink="user-assignment">
      <mat-icon mat-list-icon>trending_up</mat-icon>
      <div class="heading">Assignment</div>
    </button> -->
    <button mat-list-item routerLink="onuser-end-leave">

      <mat-icon mat-list-icon>accessibility_new</mat-icon>
      <div class="heading">Apply For Leave</div>
    </button>
   
    <!-- <button mat-list-item routerLink="new-announcement" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">flight</mat-icon>Add Announcement
    </button> -->
    <button mat-list-item routerLink="user-leave" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">flight</mat-icon>Day-Off Applications
    </button>
    <button mat-list-item routerLink="forget-password-user" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">lock</mat-icon>Reset Password
    </button>
    <button mat-list-item routerLink="user-document" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">description</mat-icon>
      Documentation
    </button>
    <button mat-list-item routerLink="show-user-chat" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">messages</mat-icon>
      Messages
    </button>
    <button mat-list-item routerLink="feedback" style="color: white">
      <mat-icon mat-list-icon style="margin-right: 25px">developer_board</mat-icon>
     Feedback
    </button>
    <button mat-list-item (click)="logout()">
      <mat-icon mat-list-icon>logout</mat-icon>
      <div class="heading">Sign Off</div>
    </button>
  </mat-action-list>
</mat-card>
</div>

