<div class="container">
    <div class="growth-container" >
      <h2>MoM Growth Rate </h2>
      <div class="legend">
        <span class="dot"></span>Monthly Report
      </div>
      <div class="chart">
        <canvas #chartCanvas ></canvas>
      </div>
      
    </div>
    </div>
    