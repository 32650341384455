import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const video = document.createElement('video');
    video.src = '../../../assets/home_video_AdobeExpress (5).mp4';
    video.style.marginTop = '-50px';
    video.style.width = '100%';
    video.autoplay = true;
    video.loop = true;
    video.muted = true;

    const videoBackground = document.getElementById('video-background');
    videoBackground.appendChild(video);
  }
}
