<h4>Add Candidate</h4>
<div class="container">
  <h2>Personal Details</h2>
  <div class="col-md-12">
    <form (submit)="formSubmit($event)" style="opacity: 0.8; min-height: 500px">
      
        <div class="details personal">
          <div class="fields">
            <div class="input-field" style="padding: 8px">
              <mat-label style="font-weight: 700">Designation</mat-label>
              <mat-select
                [(ngModel)]="data.designation"
                id="designation"
                name="designation"
                required
              >
                <mat-option value="Sales Manager">Sales Manager</mat-option>
                <mat-option value="Sales Executive">Sales Executive</mat-option>
                <mat-option value="Campaign Manager"
                  >Campaign Manager</mat-option
                >
                <mat-option value="Social Media Analyst"
                  >Social Media Analyst</mat-option
                >
                <mat-option value="SEO Executive">SEO Executive</mat-option>
                <mat-option value="Graphic Designer"
                  >Graphic Designer</mat-option
                >
                <mat-option value="Network Admin">Network Admin</mat-option>
                <mat-option value="Php Developer">Php Developer</mat-option>
                <mat-option value="Fullstack Developer"
                  >Fullstack Developer</mat-option
                >
              </mat-select>
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px">Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="name"
                required
              />
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px">Email</label>
              <input
                type="text"
                placeholder="Enter your email"
                id="email"
                required
              />
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px">Mobile No</label>
              <input
                type="number"
                placeholder="Enter your mobile no."
                id="mobile"
                maxlength="10"
                pattern="[5-9][0-9]{9}"
                required
              />
            </div>
            <div class="input-field" style="padding: 8px">
              <mat-label style="font-weight: 700">Gender</mat-label>
              <mat-select
                [(ngModel)]="data.gender"
                id="gender"
                name="gender"
                required
              >
                <mat-option value="Male" style="font-weight: 600"
                  >Male</mat-option
                >
                <mat-option value="Female" style="font-weight: 600"
                  >Female</mat-option
                >
                <mat-option value="Other" style="font-weight: 600"
                  >Other</mat-option
                >
              </mat-select>
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px"
                >Date Of Birth</label
              >
              <input
                type="date"
                placeholder="Enter birth date"
                id="dob"
                required
              />
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px">Location</label>
              <input
                type="text"
                placeholder="Enter your location"
                id="location"
                required
              />
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px"
                >Experience</label
              >
              <input
                type="text"
                placeholder="Enter your experience"
                id="experience"
                required
              />
            </div>
            <div class="input-field">
              <label style="font-weight: 700; font-size: 14px"
                >Current CTC</label
              >
              <input
                type="text"
                placeholder="Enter your current CTC"
                id="currentCTC"
                required
              />
            </div>
            <div class="input-field" style="margin-top: 32px">
              <label style="font-weight: 700; font-size: 14px"
                >Expected CTC</label
              >
              <input
                type="text"
                placeholder="Enter your expectedCTC"
                id="expectedCTC"
                required
              />
            </div>
            <div class="input-field" style="margin-top: 32px">
              <label style="font-weight: 700; font-size: 14px"
                >Notice Period</label
              >
              <input
                type="text"
                placeholder="Enter your notice period"
                id="noticeperiod"
                required
              />
            </div>
            <div class="input-field" style="margin-top: 32px">
              <label style="font-weight: 700; font-size: 14px">LinkedIn</label>
              <input
                type="text"
                placeholder="Enter your LinkedIn Id"
                id="linkedIn"
                required
              />
            </div>           
            <button mat-raised-button color="accent" type="submit" > Save</button>
          </div>
        </div>
 
    </form>
  </div>
</div>

