import { Component, Inject, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
interface User {
  status: string;
}
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css'],
})

export class AddCommentComponent implements OnInit
{
  userId : any;
  public user: User =
  {
    status: '',
  };
constructor( private mainService: MainService,private router: Router ,
private dialogRef: MatDialogRef<AddCommentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  id: any;
  taskId: any;
  ngOnInit(): void
  {
    this.taskId = JSON.stringify(this.data.id);
    this.userId = JSON.stringify(this.data.userId);
    this.mainService.gettask(this.userId).subscribe((res) =>
      {
        this.data = res;
        JSON.stringify(this.data);
    });
  }

  formSubmit(event) {
    let target = event.target;
    var arrObj = {};

    if (target.querySelector('#id').value != null) {
      arrObj['id'] = target.querySelector('#id').value;
    }
    if (target.querySelector('#comments').value != null) {
      arrObj['comments'] = target.querySelector('#comments').value;
    }
    this.mainService.addcomment(this.userId, arrObj).subscribe(
      (data: any) => {
        Swal.fire('Successfully done !!', 'success').then(() => {
          this.closeModalAndRedirect();
        });
      },
      (error) => {
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
  onfromSubmit(event)
  {
    let target = event.target;
    var arrObj = {};

    arrObj['id'] = this.taskId;

    if (this.user.status != null) {
      arrObj["status"] = this.user.status;
    }

    console.log(arrObj);

    console.log("---------- " + JSON.stringify(arrObj))
    this.mainService.taskbyid(this.id, arrObj).subscribe(
      (res: HttpResponse<any>) => {
        this.data = res;
        JSON.stringify(this.data)
        Swal.fire('Successfully done !!', 'success').then(() => {
          this.closeModalAndRedirect();
        });
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }

  isAdmin(): boolean {
    if (this.userId === this.id) {
      return true;
    } else {
      console.log("User does not have admin role."); 
      return false;
    }
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  closeModalAndRedirect(): void {
    this.dialogRef.close();
    this.router.navigate(['/admin/showuser-task']).then(() => {
      window.location.reload();
    });
  }

}
