import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-task-statistics',
  templateUrl: './task-statistics.component.html',
  styleUrls: ['./task-statistics.component.css']
})
export class TaskStatisticsComponent implements OnInit {
  @ViewChild('myChart') myChart!: ElementRef;
  user_id = localStorage.getItem('id');
  data: any;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.loadBarChartData();
  }

  loadBarChartData() {
    if (this.user_id) {
      this.mainService.taskgraphUser(this.user_id).subscribe((res) => {
        this.data = res;
        console.log(JSON.stringify(this.data));
        if (this.data != null) {
          this.createChart();
        }
      });
    } else {
      console.error('User ID not found in localStorage.');
    }
  }

  createChart() {
    const ctx = this.myChart.nativeElement.getContext('2d');

    const chartConfig: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.data.map((item: any) => item.Month),
        datasets: [
          {
            label: 'Total Task',
            data: this.data.map((item: any) => item['Total Task']),
            backgroundColor: 'rgba(135, 145, 233, 0.3)',
            borderWidth: 0,
            borderRadius: 20
          },
          {
            label: 'Status - Completed',
            data: this.data.map((item: any) => item['Status - Completed']),
            backgroundColor: 'rgba(89, 50, 234, 1)',
            borderWidth: 0,
            borderRadius: 20
          }
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true
          },
          y: {
            beginAtZero: true,
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: true // Enable the legend
          }
        }
      },
    };

    new Chart(ctx, chartConfig);
  }
}
