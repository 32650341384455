<div class="back">
  <a [routerLink]="['/admin']"
    ><button class="button1" style="margin-top: 36px">Home</button></a
  >
  <a class="theme-btn" onclick="history.go(-1)"
    ><button class="button2" style="float: right; margin-top: 36px">
      Back ⇨
    </button></a
  >
  <div class="container3">
    <h4
      style="
        text-align: center;
        font-size: 30px;
        margin-top: -31px;
        color: black;
        margin-bottom: -1px;
      "
    >
      Attendance
    </h4>
    <hr class="animated-line" />
  </div>
</div>
<div class="filter-container">
  <button
    style="border: 0px solid white; margin-left: 21px; background: whitesmoke"
    (click)="showFilterForm = !showFilterForm"
  >
    <img
      style="width: 34px; margin-left: -10px; margin-right: 12px" alt="Funnel Search Icon"
      src="../../../../assets/funnel (2).png"
    />
  </button>

  <div class="filter-form-wrapper" *ngIf="showFilterForm">
    <button class="filter-btn" (click)="filterByWeek()">Week</button>
    <button class="filter-btn" (click)="filterByMonth()">Month</button>

    <button class="filter-btn" (click)="filterByQuarter()">Quarter</button>
  </div>
  <form
    #filterForm="ngForm"
    (submit)="AttendanceFIlter($event)"
    style="display: flex"
  >
    <div class="form-field" style="margin-left: 30px">
      <label for="start_date">Date From:</label>
      <input
        name="start_date"
        [(ngModel)]="start_date"
        type="date"
        class="form-control"
      />
    </div>
    <div class="form-field">
      <label for="end_date">Date To:</label>
      <input
        name="end_date"
        [(ngModel)]="end_date"
        type="date"
        class="form-control"
      />
    </div>
    <button type="submit" class="filter-btn">Filter</button>
  </form>
</div>

<div class="content-wrapper">
  <section class="Leads_manage leads_redesign">
    <div class="container2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-lg-12">
            <div class="in_time_location">
              <div class="table-responsive table-fixed table-bg">
                <table
                  class="table table-bordered table-hover table-striped text-center"
                >
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 5%">Sn.</th>
                      <th
                        rowspan="2"
                        style="width: 5%"
                        *ngIf="!isClickedOnAttendance"
                      >
                        Name.
                      </th>
                      <th rowspan="2" style="width: 10%">Date</th>
                    </tr>
                    <tr>
                      <th style="width: 10%">In Time</th>
                      <th style="width: 10%">Out Time</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr *ngFor="let user of userAttendanceList">
                      <td class="td_center">{{ user.serialNumber }}</td>

                      <td class="td_center" *ngIf="!isClickedOnAttendance">
                        {{ user.user.firstName + " " + user.user.lastName }}
                        <br /><br /><br />
                        <a
                          (click)="
                            liveLocation(
                              user.user.id,
                              user.user.firstName,
                              user.user.lastName
                            )
                          "
                        >
                          <img
                            src="../../../../assets/loaction-icon.webp" Alt="location Icon"
                            style="width: 64px; margin-top: -26px; height: 40px"
                          />
                        </a>
                      </td>

                      <td class="td_center">{{ user.date }}</td>
                      <td class="td_center" *ngIf="user.inTime">
                        {{ user.inTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.inTime"></td>
                      <td class="td_center" *ngIf="user.outTime">
                        {{ user.outTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.outTime"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination-container">
                <div class="pagination-info">
                  Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
                </div>
                <mat-paginator 
                  [length]="totalElements" 
                  [pageSize]="pageSize" 
                  [pageSizeOptions]="[10, 20, 50, 100]" 
                  (page)="onPageChange($event)">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
