import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface User {
  status: string;
  subject: string;
  deadLine: string | null; // Store as timestamp
}

@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.css']
})
export class UpdateTaskComponent implements OnInit {
  userId = localStorage.getItem('id');
  data: any;
  taskId: string;

  public user: User = {
    status: '',
    subject: '',
    deadLine: null,
  };

  constructor(
    private mainService: MainService,
    private router: Router,
    private dialogRef: MatDialogRef<UpdateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.taskId = this.dialogData.taskId;
    this.user.subject = this.dialogData.subject;
    // Convert the timestamp to a date string for the calendar input
    this.user.deadLine = this.dialogData.deadLine ? new Date(this.dialogData.deadLine).toISOString().split('T')[0] : null;
  }

  onFormSubmit(event): void {
    // Convert the date string back to a timestamp before saving
    const updatedTask = {
      id: this.taskId,
      status: this.user.status || null,
      subject: this.user.subject || null,
      deadLine: this.user.deadLine ? new Date(this.user.deadLine).getTime() : null,
    };

    this.mainService.taskbyid(this.userId, updatedTask).subscribe(
      (res: HttpResponse<any>) => {
        this.data = res;
        Swal.fire({
          title: 'Successfully updated',
          text: 'Your task has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
            this.router.navigate(['user-dashboard/task']);
            window.location.reload();
          }
        });
      },
      (error: HttpErrorResponse) => {
        const errorMessage = error.error?.message || 'An unexpected error occurred. Please try again later.';
        Swal.fire('Error', errorMessage, 'error');
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }
}
