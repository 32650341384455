<h4>User task</h4>
<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Task List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <!-- <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
      <mat-icon>add</mat-icon> Add Task
    </button> -->
    <!-- <button mat-button class="custom-button" (click)="downloadpdf()">
      <mat-icon>file_download</mat-icon> Download
    </button> -->
    
  </div>
</div>

<div class="filter-form-wrapper" *ngIf="showFilterForm" style="position: absolute; top: 200px; right: 50px; background: white; padding: 20px; box-shadow: 0 0 10px #958c8c; border-radius: 8px; width: 300px;z-index: 1000;">
  <!-- Close icon -->

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" style="display: block; margin-top: 20px;">
    <!-- Date range -->
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="dateRange" style="color:black">Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style="width: 100%; margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
          style="width: 100%;"
        />
      </div>
    </div>

    <!-- Status -->
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="status" style="color:black">Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control" style="width: 100%;">
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="REVIEWED">Reviewed</option>
        <option value="DONE">Done</option>
      </select>
    </div>
    <!-- <div class="heelo">
      <select id="userId" name="employeeId" [(ngModel)]="user_id" style="width:100%">
        <option value="">Select Employee</option>
        <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div> -->
    
    <!-- Clear button -->
    <div style="display: flex; justify-content: space-between;">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
<div class="container2" style="margin-top: 30px;">
    <table>
      <thead>
        <tr>
          <th class="background">Date</th>
          <th class="background">Task</th>
          <th class="background">Comments</th>
          <th class="background">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredData">
          <td>{{ item.createdTime | date: 'dd-MMM-yyyy hh:mm a' }}</td>
          <td>{{ item.subject }}
            <ng-container *ngIf="item.fileUrl">
              <a href="#" (click)="openPopup(item.fileUrl)">
                <img src="../../../../assets/task.png" alt="Document Icon" width="20" height="20">
              </a>
            </ng-container>
          </td>
          <td><div id="existingComments">{{ item.comments }}</div></td>
          
          <td [style.background-color]="getStatusColor(item.status)">{{ item.status }}</td>
        </tr>
      </tbody>
    </table>
</div>
<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>