<div class="container3">
  <h4 style="
      text-align: center;
      font-size: 30px;
      margin-top: -31px;
      color: white;
      margin-bottom: -1px;
    ">
    Assignments
  </h4>
  <hr class="animated-line" />
</div>

<div class="container">
  <div class="Salary-details" style="width: 100%">
    <!-- <h3 style="text-align:center;font-size: 30px;margin-top: 42px;">Show All Employee Profile</h3> -->
  </div>

  <!-- {{data | json}} -->

  <button style="
      background: #ce3262;
      border: #ce3262;
      color: white;
      margin-top: 30px;
      width: 198px;
      height: 40px;
      font-size: 15px;
      margin-bottom: 40px;
      margin-left: 20px;
      border-radius: 8px;
      font-weight: bold;
      cursor: pointer;
    ">
    <a style="font-size: 16px; color: white" (click)="navigateToAddUserProfile()"><span
        class="material-icons">task</span> Add Assignments</a>
  </button>

  <div class="container2">
    <table>
      <thead>
        <tr>
          <th class="background">Date</th>
          <th class="background">Name</th>
          <th class="background">Subject</th>
          <th class="background">Description</th>
          <th class="background">Deadline</th>
          <th class="background">Comment</th>
          <th class="background">Status</th>
          <th class="background"><mat-icon mat-list-icon>edit</mat-icon>Action</th>
          <th class="background"><mat-icon mat-list-icon>delete</mat-icon>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <th>{{ formatDateTime(data.date) }}</th>
          <th>{{ data.user.firstName }} {{ data.user.lastName }}</th>
          <th>{{ data.task }}</th>
          <th>{{ data.description }}</th>
          <th>{{ data.deadline }}</th>
          <th>{{ data.comment }}</th>
          <th style="width: 121px;color: white;" [style.background-color]="
                data.status === 'Pending'
                  ? 'rgba(103, 59, 141, 0.67)'
                  : data.status === 'Reviewed' && data.comment
                  ? ' rgb(103, 59, 141)'
                  : data.status === 'Completed'
                  ? 'rgb(106, 106, 152)'
                  : ''
              ">
            {{ data.status }}

          </th>

          <th>
            <button class="button2" style="font-size: 12px" [routerLink]="['/user-dashboard/update-assignment']"
              [queryParams]="{ id: data.id }">
              <a><mat-icon mat-list-icon>update</mat-icon>Update</a>
            </button>
          </th>
          <th>
            <button class="button2" style="color: white; background: #ce3262; width: 100%" (click)="ondelete(data.id)">
              Delete<mat-icon mat-list-icon>delete</mat-icon>
            </button>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>