import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-total-salary',
  templateUrl: './total-salary.component.html',
  styleUrls: ['./total-salary.component.css'],
})
export class TotalSalaryComponent implements OnInit {
  userId: any;
  data: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data1: { userId: any },
    private http: HttpClient,
    private main: MainService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userId = this.data1.userId; 
    this.main.allTotalSalary(this.userId).subscribe((res: any) => {
      this.data = res;
      
    });
  }
}
