import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  id: number;
  user_id = localStorage.getItem('id');
  month: string;
  data: any = {};
  circleDasharray = 283;
  circleDashoffset: any;
  strokeColor: string;
  performanceMessage: string;
  report: any = {};
  userReviews: number;
  teamWork: number;
  generalConduct: number;
  jobKnowledge: number;
  workQuality: number;
  skills: number;
  assistance: number;
  attendance: number;
  workDelivery: number;
  performance: number;
  employee: any;
  employees: any;
  timeline:any;
  constructor(private datePipe: DatePipe, private mainService: MainService) {
    this.id = +localStorage.getItem('id'); // Initialize userId and convert to number
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('User ID is null or invalid');
      return; // Handle the absence of user ID appropriately
    }

    if (this.id) {
      this.mainService.managerAlllist(this.id, true).subscribe(
        (res: any) => {
          this.employees = res;
          console.log('Employees:', this.employees);
        },
        (err: any) => {
          console.error('Error fetching employees:', err);
        }
      );
    }
    this.mainService.performance(this.user_id, this.month).subscribe((res: any) => {
      this.data = res;
      this.timeline= this.data.timeline
      console.log("record time"+ JSON.stringify(this.timeline))
    });
  }

  onUserChange(): void {
    if (this.user_id) {
      this.mainService.performance(this.user_id, this.month).subscribe((res: any) => {
        this.data = res;
        this.calculateCircleDashoffset();
        this.setPerformanceMessage();
      }, error => {
        console.error('Error fetching performance data:', error);
        // Handle error appropriately
      });

      this.mainService.report(this.user_id).subscribe((res: any) => {
        this.report = res;
        console.log('API Response report:', this.report); 
        this.setReportData();
      }, error => {
        console.error('Error fetching report data:', error);
      });
    }
  }

  setReportData(): void {
    let totalUserReviews = 0;
    let totalTeamWork = 0;
    let totalGeneralConduct = 0;
    let totalJobKnowledge = 0;
    let totalWorkQuality = 0;
    let totalSkills = 0;
    let totalAssistance = 0;
    let totalAttendance = 0;
    let totalWorkDelivery = 0;
    let totalPerformance = 0;

    if (this.report.length > 0) {
      this.report.forEach((item: any) => {
        totalUserReviews += item.percentage ?? 0;
        totalTeamWork += item.teamWork ?? 0;
        totalGeneralConduct += item.generalConduct ?? 0;
        totalJobKnowledge += item.jobKnowledge ?? 0;
        totalWorkQuality += item.workQuality ?? 0;
        totalSkills += item.skills ?? 0;
        totalAssistance += item.assistance ?? 0;
        totalAttendance += item.attendance ?? 0;
        totalWorkDelivery += item.workDelivery ?? 0;
        totalPerformance += item.percentage ?? 0;
      });

      const itemCount = this.report.length;
      this.userReviews = totalUserReviews / itemCount;
      this.teamWork = totalTeamWork / itemCount;
      this.generalConduct = totalGeneralConduct / itemCount;
      this.jobKnowledge = totalJobKnowledge / itemCount;
      this.workQuality = totalWorkQuality / itemCount;
      this.skills = totalSkills / itemCount;
      this.assistance = totalAssistance / itemCount;
      this.attendance = totalAttendance / itemCount;
      this.workDelivery = totalWorkDelivery / itemCount;
      this.performance = totalPerformance / itemCount;
    } else {
      this.userReviews = 0;
      this.teamWork = 0;
      this.generalConduct = 0;
      this.jobKnowledge = 0;
      this.workQuality = 0;
      this.skills = 0;
      this.assistance = 0;
      this.attendance = 0;
      this.workDelivery = 0;
      this.performance = 0;
    }
  }

  calculateCircleDashoffset(): void {
    const percentage = Math.round(this.data.overAllPerformace);
    this.circleDashoffset = this.circleDasharray - (this.circleDasharray * percentage / 100);
    this.strokeColor = percentage === 100 ? '#BDBDBD' : '#4FBAF0';
  }

  setPerformanceMessage(): void {
    if (this.data.overAllPerformace < 50) {
      this.performanceMessage = "Performance needs improvement!";
    } else if (this.data.overAllPerformace < 75) {
      this.performanceMessage = "Good performance, keep it up!";
    } else if (this.data.overAllPerformace < 90) {
      this.performanceMessage = "Great performance!";
    } else {
      this.performanceMessage = "Outstanding performance!";
    }
  }
}
