<mat-dialog-content>
  <div class="modal-overlay" (click)="closeModal()"></div>
  <div class="modal-content" style="width: 34%;">
    <span class="close" (click)="closeModal()">&times;</span>
    <form (ngSubmit)="onFormSubmit($event)">
      <mat-form-field class="w100" appearance="outline" style="margin-top: 16px">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="user.status" id="status" name="status" style="background:white;">
          <mat-option value="PENDING">PENDING</mat-option>
          <mat-option value="DONE">DONE</mat-option>
        </mat-select>
      </mat-form-field>
      
      <label>Deadline</label>
      <input type="date" name="deadLine" [(ngModel)]="user.deadLine" style="background:white; width: 100%;"/>

      <mat-form-field class="w100" appearance="outline" style="margin-top: 34px; width: 100%;">
        <mat-label>Task</mat-label>
        <textarea required autofocus name="subject" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="background:white; width: 100%;" rows="14"></textarea>
      </mat-form-field>
      
      <div class="container text-center mt15">
        <button mat-raised-button color="accent">Update</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
