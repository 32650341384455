import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-style',
  templateUrl: './all-style.component.html',
  styleUrls: ['./all-style.component.css']
})
export class AllStyleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
