import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { resolve } from 'dns';
import { error } from 'console';

@Component({
  selector: 'app-update-user-profile',
  templateUrl: './update-user-profile.component.html',
  styleUrls: ['./update-user-profile.component.css'],
})
export class UpdateUserProfileComponent implements OnInit {
  authorities: any = { authority: '' };
  roleString: string;
  roles: any[];
  userId: string;
  data: any;
  user: any;
  storedAuthority;
  date: string;
  role:any;
  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.mainService.showuserbyId(this.userId).subscribe((res) => {
      this.data = res;
      // this.authorities.authority = this.data.authorities[0]?.authority || '';
      this.storedAuthority = this.data.authorities[0]?.authority || '';
      console.log("Authority ------- "+this.storedAuthority)
    });
    this.mainService.getRoles().subscribe((res: any) => {
      this.roles = res;
    });
  
  }
  updateUserProfile(event) {
    let target = event.target;
    var arrObj = {};
    arrObj['id'] = this.userId;
    if (target.querySelector('#firstName').value != null) {
      arrObj['firstName'] = target.querySelector('#firstName').value;
    } else {
      arrObj['firstName'] = null;
    }
    if (target.querySelector('#lastName').value != null) {
      arrObj['lastName'] = target.querySelector('#lastName').value;
    }
    if (target.querySelector('#userName').value != null) {
      arrObj['userName'] = target.querySelector('#userName').value;
    }
    if (target.querySelector('#dob').value != null) {
      arrObj['dob'] = target.querySelector('#dob').value;
    }
    if (target.querySelector('#email').value != null) {
      arrObj['email'] = target.querySelector('#email').value;
    }
    if (target.querySelector('#phone').value != null) {
      arrObj['phone'] = target.querySelector('#phone').value;
    }
    if (this.data.gender != null) {
      arrObj['gender'] = this.data.gender;
    }
    if (target.querySelector('#currentAddress').value != null) {
      arrObj['currentAddress'] = target.querySelector('#currentAddress').value;
    }

    if (this.data.department != null) {
      arrObj['department'] = this.data.department;
    }
    if (this.data.designation != null) {
      arrObj['designation'] = this.data.designation;
    }
    this.updateUserRole()
      .then(() => {
        this.mainService.updateuserprofile(arrObj['id'], arrObj).subscribe(
          (res: HttpResponse<any>) => {
            this.data = res;
            JSON.stringify(this.data);
            Swal.fire('Successfully updated.');
            this.router.navigate(['/admin/all-employee-profile']);
          },
          (error) => {
            console.log(error);
            Swal.fire('Error', ' ' + error.error.message, 'error');
          }
        );
      })
      .catch((error) => {
        Swal.fire('Error', ' ' + error.error.message, 'error');
      });
  }

  updateUserRole(): Promise<any> {
    return new Promise((resolve, reject) => {
      const roleUpdatePayload = {
        name: this.storedAuthority,
        userId: this.userId,
      };
      this.mainService.updateRole(roleUpdatePayload).subscribe(
        (res: HttpResponse<any>) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
