import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-user-total-salary',
  templateUrl: './user-total-salary.component.html',
  styleUrls: ['./user-total-salary.component.css']
})
export class UserTotalSalaryComponent implements OnInit {

  user_id: any;
  id = localStorage.getItem('id');
  data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data1: { userId: any }, private http: HttpClient, private main: MainService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user_id = this.data1.userId;
    console.log(this.user_id);

    // Fetch the specific data for the selected user_id
    this.main.allTotalSalary(this.id).subscribe((res: any) => {
      this.data = res;
      console.log("total salary : " + JSON.stringify(this.data));
    });
  }
}

