<h2 mat-dialog-title>Hiring Details</h2>
<span class="modal-close" (click)="onNoClick()">X</span>
<mat-dialog-content>
  <div class="container">
    <div class=" form-group">
      <div class="flex-item">
        <label for="designation">Designation</label><br>
        <select id="designation" [(ngModel)]="data.designation">
          <option *ngFor="let option of designations" [value]="option.value">{{option.label}}</option>
        </select>
      </div>
      <div class="flex-item">
        <label for="budget">Budget</label><br>
        <input type="text" id="budget" [(ngModel)]="data.budget" />
      </div>
      <div class="flex-item">
        <label for="budget">Location</label><br>
        <input type="text" id="location" [(ngModel)]="data.location" />
      </div>
      <div class="flex-item">
        <label for="budget">Experience</label><br>
        <input type="text" id="experience" [(ngModel)]="data.experience" />
      </div>
    </div>
    
    <div class="form-group">
      <label for="keywords">Keywords</label><br>
      <div class="flex-item">
        <input type="text" id="keywordInput" [(ngModel)]="newKeyword" placeholder="Enter keyword" />
        <button (click)="addKeyword()">&#43;</button>
      </div>
      <div class="keywords-container">
        <div *ngFor="let keyword of data.keywords; let i = index" class="keyword-item">
          {{ keyword }}
          <button (click)="removeKeyword(i)">&times;</button>
        </div>
      </div>
    </div>
    
    
    
    <div class="form-group">
      <label for="designationkeywords">Designation Keywords</label><br>
      <div class="flex-item">
        <input type="text" id="designationKeywordInput" placeholder="Enter keyword" />
        <button (click)="addDesignationKeyword()">&#43;</button>
      </div>
      <div class="keywords-container">
      <div *ngFor="let keyword of data.designationkeywords; let i = index" class="keyword-item">
        {{ keyword }}
        <button (click)="removeDesignationKeyword(i)">&times;</button>
      </div>
    </div>
    </div>
    
    <div class="form-group">
      <label for="isActive">Status</label><br>
      <select id="isActive" [(ngModel)]="data.isActive">
        <option [value]="true">Active</option>
        <option [value]="false">Inactive</option>
      </select>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
