<div class="show">
<h4> Reset Password </h4>
  <div style="float: left; width: 47%">
    <div class="container" style=" border: 1px solid white; margin-top: 58px; background: rgb(0, 13, 29, 0.3);">
      <h1 style="color: white;">Create A Strong Password</h1>
      <p style="color: white;">Your password must be at least 6 characters and should include a  combination of numbers, letters and special characters (!$@%). </p>
      <form (ngSubmit)="updatePassword()" #passwordForm="ngForm">
        <div class="form-group">
          <label for="password" style="color: white">
            <mat-icon>vpn_key</mat-icon>
            New Password:
          </label>
          <input type="password" class="form-control"  id="password" name="password" [(ngModel)]="user.password" required />
        </div>
        <div class="form-group">
          <label for="confirmPassword" style="color: white">
            <mat-icon>lock</mat-icon>
            Confirm Password:
          </label>
          <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" [(ngModel)]="user.confirmPassword" required />
        </div>
        <div class="form-group">
          <button  mat-raised-button type="submit" color="accent">Update Password</button>
        </div>
      </form>
    </div>
  </div>
</div>
