
  <div class="container3">
    <h4
      style="
        text-align: center;
        font-size: 30px;
        margin-top: -31px;
        color: black;
        margin-bottom: -1px;
      "
    >
      Calender Invite
    </h4>
  </div>
 

<section id="full-screen">
  <div
    class="container"
    style="
      background: url('../../../../assets/3d-render-notebook-mock-up-with-clean-blank-design-advertising-3d-illustration-perspective-view.jpg');
    "
  >
    <form (ngSubmit)="formSubmit($event)" class="row" style="background: white">
      <div class="col-md-6">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter Email"
          required
        />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="organizerEmail">Select Email:</label>
          <select
            id="organizerEmail"
            placeholder="Select Employee Email"
            name="employeeSelector"
            style="
              width: 100%;
              border-radius: 4px;
              padding: 6px;
              border: 1px solid #cccccc;
              height: 32px;
            "
            required
          >
            <option value="" disabled selected>Select Employee Email</option>
            <option *ngFor="let employee of data" [value]="employee.email">
              ID: {{ employee.id }} - Name: {{ employee.firstName }}
              {{ employee.lastName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <label for="startTime">Start Time</label>
        <input
          type="datetime-local"
          id="startTime"
          name="startTime"
          placeholder="Enter Start Timing"
          required
        />
      </div>
      <div class="col-md-6">
        <label for="endTime">End Time</label>
        <input
          type="datetime-local"
          id="endTime"
          name="endTime"
          placeholder="Enter End Timing"
          required
        />
      </div>
      <div class="col-md-6">
        <label for="location">Location</label>
        <input
          type="text"
          id="location"
          name="location"
          placeholder="Enter Location"
          required
        />
      </div>
      <div class="col-md-6">
        <label for="description">Description</label>
        <textarea
          id="description"
          name="description"
          placeholder="Enter Description"
          required
        ></textarea>
      </div>
      <div class="col-md-6">
        <label for="summary">Summary</label>
        <input
          type="text"
          id="summary"
          name="summary"
          placeholder="Your Summary"
          required
        />
      </div>
      <div class="col-md-6">
        <label for="attachment">Attachment</label>
        <input
          type="file"
          id="attachment"
          name="attachment"
          (change)="onAttachmentFileChange($event)"
        />
      </div>
      <div class="col-md-12">
        <button type="submit">Send Invitation</button>
      </div>
    </form>
  </div>
</section>
