import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
interface User {
  status: string;
}
@Component({
  selector: 'app-update-assignment',
  templateUrl: './update-assignment.component.html',
  styleUrls: ['./update-assignment.component.css']
})
export class UpdateAssignmentComponent implements OnInit {

  userId: string;
  data: any;
  id = localStorage.getItem('id');

  public user: User = {
    status: '',
  };
  constructor(private mainService: MainService, private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    console.log("Task Id: " + this.userId);
  }

  onfromSubmit(event) {
    let target = event.target;
    var arrObj = {};

    arrObj['id'] = this.userId;

    if (this.user.status != null) {
      arrObj["status"] = this.user.status;
    }

    this.mainService.updatebyuserid(this.id, arrObj).subscribe(
      (res: HttpResponse<any>) => {
        this.data = res;
        JSON.stringify(this.data)
        alert("Successfully updated.")

        this.router.navigate(['/user-dashboard/user-assignment']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
