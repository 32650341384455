<div class="back">
  <button class="button1" style="margin-top: 36px">
    <a [routerLink]="['/admin']">Home</a>
  </button>
  <button class="button2" style="float: right; margin-top: 36px">
    <a class="theme-btn" onclick="history.go(-1)">Back</a>
  </button>
  <div class="container3">
    <h4
      style="
        text-align: center;
        font-size: 30px;
        margin-top: -31px;
        color: white;
        margin-bottom: -1px;
      "
    >
      Add Comments
    </h4>
    <hr class="animated-line" />
  </div>
</div>

<mat-card>
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (ngSubmit)="formSubmit($event)">
            <div class="task-info">
              <label>Today Assign Id</label>
              <input type="id" id="id" name="id" [value]="id" readonly />
            </div>

            <mat-form-field
              class="w100"
              appearance="outline"
              style="margin-top: 34px"
            >
              <mat-label>Assignment</mat-label>

              <textarea
                required
                autofocus
                type="text"
                id="comment"
                name="comment"
                matInput
                placeholder="Enter here"
                rows="14"
              ></textarea>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
