import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.css']
})
export class MarkAttendanceComponent implements OnInit {
  user_id: string;
  users: any;
  message:any;
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('id');
  }

  markAttendance() {
    this.mainService.markAttendance(this.user_id).subscribe(
      (res) => {
        this.users = res;
        // alert(res.message);
        Swal.fire(JSON.stringify(this.users.message));
        window.location.href = "#/user-dashboard/attendance";
      },
      (error) => {
        console.error(error.error.message);
        alert(JSON.stringify(error.error.message));
        window.location.reload();
      }
    );
  }

  updateAttendance() {
    this.mainService.updateAttendance(this.user_id).subscribe(
      (res) => {
        this.users = res;
        Swal.fire(JSON.stringify(this.users.message));
        window.location.href = "#/user-dashboard/attendance";
      },
      (error) => {
        console.error(error.error.message);
        alert(JSON.stringify(error.error.message));
        window.location.reload();
      }
    );
  } 
}

