import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface User {
  status: string;
  subject: string;
}

@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.css']
})
export class UpdateTaskComponent implements OnInit {
  userId = localStorage.getItem('id');
  data: any;
  id: string;
  taskId: any;
  subject: any;

  public user: User = {
    status: '',
    subject: '',
  };

  constructor(
    private mainService: MainService,
    private router: Router,
    private dialogRef: MatDialogRef<UpdateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.taskId = this.dialogData.taskId;
    this.user.subject = this.dialogData.subject;
    this.id = this.userId;
  }

  onformSubmit(event) {
    let target = event.target;
    const arrObj = {
      id: this.taskId,
      status: this.user.status || null,
      subject: this.user.subject || null,
    };
    this.mainService.taskbyid(this.id, arrObj).subscribe(
      (res: HttpResponse<any>) => {
        this.data = res;
        Swal.fire({
          title: 'Successfully updated.',
          text: 'Your task has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
            this.router.navigate(['user-dashboard/task']);
            window.location.reload();
          }
        });
      },
      (error: HttpErrorResponse) => {
        if (error.error && error.error.message) {
          Swal.fire('Error', ' ' + error.error.message, 'error');
        } else {
          Swal.fire('Error', 'An unexpected error occurred. Please try again later.', 'error');
        }
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }
}
