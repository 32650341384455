<h4> Add Task</h4>
<mat-card style="background: #2d2d2d;">
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (ngSubmit)="formSubmit()">
            <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)">
              <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Task Files</ngx-dropzone-label>
              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-image-preview>
            </div>
            <mat-form-field class="w100" appearance="outline" style="margin-top: 34px; width: 100%;">
              <mat-label style="color: white;">Task</mat-label>
              <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="color: white; background: #ce32622e; width: 100%;" rows="14"></textarea>
            </mat-form-field>
            
            <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
