<h4> Update Candidate</h4>
<div class="container">
  <h2>Previous Status</h2>
  <div>
    <table style="width: 100%;margin-top: 30px; border: 2px solid black">
      <thead>
        <tr>
          <th>Comment</th>
          <th>Feedback</th>
          <th>Feedback2</th>
          <th>Date</th>
          <th>Time</th>
          <th>Status</th>
          <th>Delete </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of data">
          <td>{{ data.comment }}</td>
          <td>{{ data.feedback }}</td>
          <td>{{ data.feedback2 }}</td>
          <td>{{ data.date |date :"dd-MMM-yyyy"}}</td>
          <td>{{ data.time }}</td>
          <td>{{ data.status }}</td>
          <td><button class="button"><a (click)="navigateToAddUserProfile()">Delete</a> </button> </td>
        </tr>
      </tbody>
    </table>
  </div>
  <h2 style="margin-top: 30px;">Update Details</h2>
  <div class="col-12">

    <form
      *ngFor="let data of data"
      (submit)="updatecandidate($event)"
      style="opacity: 0.8; min-height: 401px"
    >
      <div class="form first">
        <div class="details personal">
          <div class="fields">
            <div class="input-field">
              <label
                for="comment"
                style="font-weight: 600; font-size: 14px; color: black"
                >Comment</label
              >
              <input
                type="text"
                placeholder="Enter your Comment"
                id="comment"
                name="comment"
                [(ngModel)]="data.comment"
              />
            </div>
            <div class="input-field">
              <label
                for="feedback"
                style="font-weight: 600; font-size: 14px; color: black"
                >Feedback</label
              >
              <input
                type="text"
                placeholder="Enter your Feedback"
                id="feedback"
                name="feedback"
                [(ngModel)]="data.feedback"
                required
              />
            </div>
            <div class="input-field">
              <label
                for="feedback2"
                style="font-weight: 600; font-size: 14px; color: black"
                >Feedback2</label
              >
              <input
                type="text"
                placeholder="Enter your Feedback"
                id="feedback2"
                name="feedback2"
                [(ngModel)]="data.feedback2"
                required
              />
            </div>
            <div class="input-field">
              <label
                for="date"
                style="font-weight: 600; font-size: 14px; color: black"
                >Date</label
              >
              <input
                type="date"
                placeholder="Enter Date"
                id="date"
                name="date"
                [(ngModel)]="data.date"
                required
              />
            </div>

            <div class="input-field">
              <label
                for="time"
                style="font-weight: 600; font-size: 14px; color: black"
                >Time</label
              >
              <input
                type="time"
                placeholder="Enter Time"
                id="time"
                name="time"
                [(ngModel)]="data.time"
                required
              />
            </div>

            <div class="input-field">
              <label
                for="status"
                style="font-weight: 600; font-size: 14px; color: black"
                >Status</label
              >
              <select
                id="status"
                name="status"
                [(ngModel)]="data.status"
                required
              >
                <option value="Rejected" style="font-weight: 600">
                 Rejected
                </option>
                <option value="Postponed" style="font-weight: 600">
                  Postponed
                </option>
                <option value="Reviewed" style="font-weight: 600">
                  Reviewed
                </option>
                <option value="1stRoundClear" style="font-weight: 600">
                  1st Round Clear
                 </option>
                 <option value="2ndRoundClear" style="font-weight: 600">
                  2nd Round Clear
                 </option>
                 <option value="Pending" style="font-weight: 600">
                  Pending
                 </option>
                <option value="Hired" style="font-weight: 600">Hired</option>
              </select>
            </div>

            <button
              style="width: 245px; height: 47px; margin-top: 32px"
              type="submit"
              [routerLink]="['/admin/update-candidate']"
              [queryParams]="{ id: userId }"
              class="button1"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
