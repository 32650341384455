<h4 >Hiring Status</h4>
<div class="info">
  <div class="container">
    <h2 class="title">APPROVED</h2>
    <div class="items">
      <div class="item" *ngFor="let hiring of filterCandidates('SELECTED')">
        <div class="card-header">
          <div class="text-container">
            <div class="card-title">{{hiring.name}}</div>
            <div class="date">{{hiring.createdTime | date}}</div>

          </div>
          <div class="dots">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" style="font-size: 10px;" (click)="openModalDialog(hiring.id)">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="icon">
          <div class="match green">{{hiring.designation}}</div>
        </div>
        
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="title">SCREENING</h2>
    <div class="items">
      <div class="item" *ngFor="let hiring of filterCandidates('SCREENING')">
        <div class="card-header">
          <div class="text-container">
            <div class="card-title">{{hiring.name}}</div>
            <div class="date">{{hiring.createdTime |date}}</div>
          </div>
          <div class="dots">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" style="font-size: 10px;" (click)="openModalDialog(hiring.id)">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="icon">
          <div class="match green">{{hiring.designation}}</div>
        </div>
        <div class="comment-section">
          <div class="comment" title="{{hiring.comments}}">{{hiring.comments}}</div>
          <div class="date">Screening Date {{hiring.interviewDate | date}}</div>
          <div class="employee-name"><span>Assigned to </span>{{hiring.assignToName}}</div>
          <div class="updated-time">Last updated: {{hiring.updatedTime | date:'short'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="title">INTERVIEW</h2>
    <div class="items">
      <div class="item" *ngFor="let hiring of filterCandidates('INTERVIEW')">
        <div class="card-header">
          <div class="text-container">
            <div class="card-title">{{hiring.name}}</div>
            <div class="date">{{hiring.createdTime |date}}</div>
          </div>
          <div class="dots">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" style="font-size: 10px;" (click)="openModalDialog(hiring.id)">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="icon">
          <div class="match green">{{hiring.designation}}</div>
        </div>
        <div class="comment-section">
          <div class="comment" title="{{hiring.comments}}">{{hiring.comments}}</div>
          <div class="date">{{hiring.interviewDate | date}}</div>
          <div class="employee-name"><span>Assigned to </span>{{hiring.assignToName}}</div>
          <div class="updated-time">Last updated: {{hiring.updatedTime | date:'short'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="title">HIRED</h2>
    <div class="items">
      <div class="item" *ngFor="let hiring of filterCandidates('HIRED')">
        <div class="card-header">
          <div class="text-container">
            <div class="card-title">{{hiring.name}}</div>
            <div class="date">{{hiring.createdTime |date}}</div>
          </div>
          <div class="dots">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" style="font-size: 10px;" (click)="openModalDialog(hiring.id)">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="icon">
          <div class="match green">{{hiring.designation}}</div>
        </div>
        <div class="comment-section">
          <div class="comment" title="{{hiring.comments}}">{{hiring.comments}}</div>
          <div class="date">{{hiring.interviewDate | date}}</div>
          <div class="employee-name"><span>Assigned to </span>{{hiring.assignToName}}</div>
          <div class="updated-time">Last updated: {{hiring.updatedTime | date:'short'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="title">REJECTED</h2>
    <div class="items">
      <div class="item" *ngFor="let hiring of filterCandidates('REJECTED')">
        <div class="card-header">
          <div class="text-container">
            <div class="card-title">{{hiring.name}}</div>
            <div class="date">{{hiring.createdTime |date}}</div>
          </div>
          <div class="dots">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" style="font-size: 10px;" (click)="openModalDialog(hiring.id)">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="icon">
          <div class="match green">{{hiring.designation}}</div>
        </div>
        <div class="comment-section">
          <div class="comment" title="{{hiring.comments}}">{{hiring.comments}}</div>
          <div class="date">{{hiring.interviewDate | date}}</div>
          <div class="employee-name"><span>Assigned to </span>{{hiring.assignToName}}</div>
          <div class="updated-time">Last updated: {{hiring.updatedTime | date:'short'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
