<mat-toolbar style="background-color: #070707">
  <button type="button" (click)="toggleSidebar()"class="toggle-button"style="background: black; border: none; outline: none; cursor: pointer">
    <mat-icon>
      {{ hideSidebar ? "menu" : "close" }}
    </mat-icon>
  </button>
  <a routerLink="/admin"  mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>
      <img src="../../../assets/HR CONSOLE HORIZONTAL copy.png"  alt="Hr Console" style="height: 58px; border-radius: 10px; margin-top: -20px" />
    </mat-icon>
  </a>

  <span class="example-spacer"></span>
  <a *ngIf="!isLoggedIn" [routerLink]="'/login'" mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    Login
  </a>
  <button (click)="form()" *ngIf="isLoggedIn" class="example-icon favorite-icon ml20" mat-button routerLink="/" aria-label="Example icon-button with heart icon">
    Home
  </button>

  <button *ngIf="isLoggedIn && user" class="example-icon favorite-icon ml20" mat-button aria-label="User's Name" style="text-transform: capitalize">
    {{ user.firstName }} {{ user.lastName }}
  </button>
  <a (click)="logout()"  *ngIf="isLoggedIn" mat-icon-button class="example-icon favorite-icon mr20 ml20" aria-label="Example icon-button with heart icon">
    Sign Off
  </a>
</mat-toolbar>
