import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-task',
  templateUrl: './user-task.component.html',
  styleUrls: ['./user-task.component.css']
})
export class UserTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  status: string = 'Pending';
  value: any;
  data: any;
  userId: any;
  name: string;
  filteredData: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  startDate: string = '';
  totalElements: any = 0;
  endDate: string = '';
  showFilterForm: boolean = false;
  inputDateFromString: any;
  inputDateToString: any;

  constructor(
    private http: HttpClient, 
    private mainService: MainService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.name = this.capitalizeFirstLetter(this.route.snapshot.queryParamMap.get('name'));
    this.fetchData();
  }

  fetchData(userId: string = this.id, pageNumber: number = this.pageNumber, pageSize: number = this.pageSize, status: string = this.status, startDate: string = '', endDate: string = ''): void {
    this.mainService.task(userId, status, pageNumber, pageSize, startDate, endDate).subscribe((res: any) => {
      this.data = res;
      this.filteredData = this.data.content;
      this.totalElements = this.data.totalElements;
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.id, this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate);
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }

  onSubmitFilter(): void {
    this.inputDateFromString = this.startDate ? this.mainService.toTimestamp(this.startDate) : '';
    this.inputDateToString = this.endDate ? this.mainService.toTimestamp(this.endDate) : '';
    this.fetchData(this.id, this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString);
  }

  navigateToAddUserTask(): void {
    this.router.navigate(['/admin/add-task']);
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getStatusColor(status: string): string {
    switch(status) {
      case 'PENDING':
        return 'rgb(103, 59, 141)';
      case 'DONE':
        return 'rgb(153, 102, 204)'; // Lighter shade of purple
      case 'DUE':
        return 'rgb(77, 77, 179)'; // Dark blue
      case 'REVIEWED':
        return 'rgb(103, 59, 141)'; // Same as PENDING for consistency
      case 'ASSIGNED':
        return 'rgb(128, 128, 128)'; // Gray
      default:
        return 'rgb(106, 106, 152)'; // Default color
    }
  }

  openPopup(fileUrl: string): boolean {
    window.open(fileUrl, 'Document Viewer', 'width=600,height=400,resizable=yes,scrollbars=yes');
    return false;
  }
}
