import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
}

interface MeetingData {
  purpose: string;
  date: string;
  // time: string;
  message: string;
  // image: string;
  userIds: number[];
}
@Component({
  selector: 'app-custom-chat',
  templateUrl: './custom-chat.component.html',
  styleUrls: ['./custom-chat.component.css']
})
export class CustomChatComponent implements OnInit {
  id: string; 
  private routeSub: Subscription;
  leaveData: MeetingData = {
    purpose: '',
    date: '',
    message: '',
    userIds: [],
  };
  selectedEmployeesControl = new FormControl();
  data: Employee[] = [];

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.id = localStorage.getItem('id');
    this.mainService.AllEmployeeProfileList().subscribe((res: Employee[]) => {
      this.data = res;
    });
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.leaveData.purpose = params['purpose'];
      this.leaveData.date = params['date'];
      this.leaveData.message = params['message'];
      this.leaveData.userIds = params['userIds'];
    });
  }

  formSubmit(event) {
    event.preventDefault(); 
  
    let target = event.target;
   
    const arrObj = {
      purpose: target.querySelector('#purpose').value.trim(),
      date: target.querySelector('#date').value.trim(),
      message: target.querySelector('#message').value.trim(),
      userIds: this.selectedEmployeesControl.value.map((employee: Employee) => employee.id),
    };
  
    if (!arrObj.purpose || !arrObj.date || !arrObj.message  || arrObj.userIds.length === 0) {
      Swal.fire('Please fill in all fields and select at least one employee.');
      return;
    }
  
    this.mainService.customChat(arrObj).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Successfully done !!', 'success');
  
        this.router.navigate(['/admin/show-chat']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
  
}
