import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-approve-leave',
  templateUrl: './approve-leave.component.html',
  styleUrls: ['./approve-leave.component.css', '/../../../shared/all-style/all-style.component.css']
})
export class ApproveLeaveComponent implements OnInit {
  userId: string;
  leaveDates: any[] = [];
  leavedata: any[] = [
    {
      leaveDates: [{ date: [] }],
    },
  ];

  content: any[];
  pageSize: number = 10;
  pageNumber: number = 0;
  startDate: string = '';
  endDate: string = '';
  showFilterForm: boolean;
  user_id: string;
  status: string = 'APPROVED';
  managerStatus: string = 'APPROVED';
  inputDateFromString: any;
  inputDateToString: any;
  totalElements: number = 0;
  employees: any;
  id = localStorage.getItem('id');
  data: any;
  showAdditionalDates: boolean[] = [];

  constructor(private http: HttpClient, private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
    this.fetchData();
    this.mainService.managerAlllist(this.id, true).subscribe((res: any) => {
      this.employees = res;
    });
  }

  fetchData(userId: string = this.id, pageNumber: number = this.pageNumber, pageSize: number = this.pageSize, status: string = this.status, startDate: string = this.startDate, endDate: string = this.endDate, managerStatus: string = this.managerStatus, subchild: boolean = true) {
    this.mainService.managerGetAllLeaveOfUser(userId, startDate, endDate, status, managerStatus, subchild, pageNumber, pageSize).subscribe((res: any) => {
      if (res && res.content) {
        this.data = res.content;
        this.totalElements = res.totalElements;
        JSON.stringify(this.data);
        console.log(this.data);
      } else {
        console.error("Response does not contain expected 'content' property:", res);
      }
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.user_id || this.id, this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate, this.managerStatus);
  }

  onSubmitFilter() {
    const userId = this.user_id || this.id;
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.fetchData(userId, this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString, this.managerStatus);
  }

  getpendingtype(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'REJECTED':
        return 'Rejected';
      case 'APPROVED':
        return 'Approved';
      default:
        return status;
    }
  }

  navigateToAddUserTask() {
    this.router.navigate(['/manager-dashboard/onuser-end-leave']);
  }

  getLeaveTypeText(leaveType: string): string {
    switch (leaveType) {
      case 'HALF_DAY':
        return 'Half Leave';
      case 'ABSENT':
        return 'Absent';
      case 'PAID_LEAVE':
        return 'Paid Leave';
      default:
        return leaveType;
    }
  }

  toggleAdditionalDates(id: string) {
    this.showAdditionalDates[id] = !this.showAdditionalDates[id];
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  clearFilters() {
    this.user_id = '';
    this.status = 'PENDING';
    this.startDate = '';
    this.endDate = '';
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
}
