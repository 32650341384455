<div class="back">
  <a [routerLink]="['/admin']"><button class="button1" style="margin-top: 36px">
      ⇦ Home
    </button></a>
  <a class="theme-btn" onclick="history.go(-1)"><button class="button2" style="float: right; margin-top: 36px">
      Back ⇨
    </button></a>
  <div class="container3">
    <h4 style="
        text-align: center;
        font-size: 30px;
        margin-top: -31px;
        color: white;
        margin-bottom: -1px;
      ">
      Sales Attendance
    </h4>
    <hr class="animated-line" />
  </div>
</div>
<div class="content-wrapper">
  <section class="Leads_manage leads_redesign">
    <button style="border: 0px solid white; margin-left: 21px; background: none !important"
      (click)="showFilterForm = !showFilterForm">
      <img style="width: 34px; margin-left: -10px; margin-right: 12px" src="../../../../assets/funnel (2).png"
        alt="filter Icon" />
    </button>

    <div class="filter-form-wrapper" *ngIf="showFilterForm">
      <form #filterForm="ngForm" (submit)="AttendanceFIlter($event)" style="display: flex">
        <div class="form-field" style="margin-left: 21px">
          <label for="userIds">Select Employee:</label>
          <select id="userIds" name="employeeSelector" [(ngModel)]="user_id">
            <option *ngFor="let employee of data" [value]="employee.id">
              {{ employee.firstName }} {{ employee.lastName }}
            </option>
          </select>
        </div>
        <div class="form-field">
          <label for="start_date">Date From:</label>
          <input ngModel name="start_date" [(ngModel)]="start_date" type="date" class="form-control" />
        </div>
        <div class="form-field">
          <label for="end_date">Date To:</label>
          <input ngModel name="end_date" [(ngModel)]="end_date" type="date" class="form-control" />
        </div>
        <button type="submit" style="
            border: 0px solid white;
            margin-left: 21px;
            background: none !important;
          " (click)="userLiveLocation(user_id)">
          <img src="../../../../assets/current-location-icon.png" alt="current location " style="
              width: 34px;
              margin-left: -42px;
              margin-right: 12px;
              margin-top: 14px;
            " />
        </button>
        <button type="submit" class="filter-btn">Filter</button>
      </form>
    </div>

    <div class="container2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-lg-12">
            <div class="in_time_location">
              <div class="table-responsive table-fixed table-bg">
                <table class="table table-bordered table-hover table-striped text-center">
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 5%">Sn.</th>
                      <th rowspan="2" style="width: 5%" *ngIf="!isClickedOnAttendance">
                        Name.
                      </th>
                      <th rowspan="2" style="width: 10%">Date</th>
                    </tr>
                    <tr>
                      <th style="width: 10%">In Time</th>
                      <th>Location</th>
                      <th style="width: 10%">Out Time</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr *ngFor="let user of userAttendanceList">
                      <td class="td_center">{{ user.serialNumber }}</td>

                      <td class="td_center" *ngIf="!isClickedOnAttendance">
                        {{ user.user.firstName + " " + user.user.lastName }}
                        <br /><br /><br />
                        <a (click)="
                            liveLocation(
                              user.user.id,
                              user.user.firstName,
                              user.user.lastName
                            )
                          ">
                          <img src="../../../../assets/loaction-icon.webp" alt="location icon"
                            style="width: 64px; margin-top: -26px; height: 40px" />
                        </a>
                      </td>

                      <td class="td_center">{{ user.date }}</td>
                      <td class="td_center" *ngIf="user.inTime">
                        {{ user.inTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.inTime"></td>
                      <td style="height: 150px">
                        <agm-map style="width: 100%; height: 100%" [latitude]="user.inLat" [longitude]="user.inLong"
                          [zoom]="15" [mapTypeControl]="true">
                          <agm-marker [latitude]="user.inLat" [longitude]="user.inLong">
                          </agm-marker>
                        </agm-map>
                      </td>
                      <td class="td_center" *ngIf="user.outTime">
                        {{ user.outTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.outTime"></td>

                      <td style="height: 150px">
                        <agm-map style="width: 100%; height: 100%" [latitude]="user.outLat" [longitude]="user.outLong"
                          [zoom]="15" [mapTypeControl]="true">
                          <agm-marker [latitude]="user.outLat" [longitude]="user.outLong">
                          </agm-marker>
                        </agm-map>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>