<h4 >Task</h4>

   <div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
    <h5>Task List</h5>
    <div class="button-group" style="display: flex; gap: 10px;">
      <button mat-button class="custom-button" (click)="toggleFilterForm()">
        <mat-icon>filter_list</mat-icon> Filter
      </button>
      <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
        <mat-icon>add</mat-icon> Add Task
      </button>
      <button mat-button class="custom-button" (click)="downloadpdf()">
        <mat-icon>file_download</mat-icon> Download
      </button>
      
    </div>
  </div>

  <div class="filter-form-wrapper" *ngIf="showFilterForm" >
    <!-- Close icon -->

    <div class="filter-btn">
      <h2 style="margin: 0;" style="color:black">Filter</h2>
      <button mat-icon-button (click)="toggleFilterForm()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" style="display: block; margin-top: 20px;">
      <!-- Date range -->
      <div class="form-group" style="margin-bottom: 20px;">
        <label for="dateRange" >Select Date:</label><br>
        <div style="display: flex;">
          <input
            id="startDate"
            name="startDate"
            [(ngModel)]="startDate"
            type="date"
            class="form-control"
            style=" margin-right: 10px;"
          />
          <input
            id="endDate"
            name="endDate"
            [(ngModel)]="endDate"
            type="date"
            class="form-control"
          />
        </div>
      </div>
   
      <!-- Status -->
      <div class="form-group" >
        <label for="status" >Status:</label><br>
        <select id="status" name="status" [(ngModel)]="status" class="form-control selector" >
          <option value="">All</option>
          <option value="PENDING">Pending</option>
          <option value="REVIEWED">Reviewed</option>
          <option value="DONE">Done</option>
        </select>
      </div>
      <div class="filter-btn">
        <button mat-button color="warn" (click)="clearFilters()">Clear</button>
        <button mat-raised-button color="accent" type="submit" class="selector">Filter</button>
      </div>

    </form>

  </div>
  
  <div class="container2">
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>Date </th>
          <th>Task</th>

          <th>Comments</th>
          <th>Deadline</th>
          <th>Status</th>
          <th>
          <mat-icon mat-list-icon>edit</mat-icon>Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredData">
          <td>{{ (data.createdTime |date: 'dd-MMM-yyyy HH:mm a' ) }}</td>
          <td>
            {{ data.subject }}
            <ng-container *ngIf="data.fileUrl">
              <a href="#" (click)="openPopup(data.fileUrl)">
                <img src="../../../../assets/task.png" alt="Document Icon" width="20" height="20">
              </a>
            </ng-container>
          </td>
          <td>{{ data.comments }}</td>
          <td>{{data.deadLine |date: 'dd-MMM-yyyy hh:mm a'  }}</td>
          <td style="width: 121px;color: white" [style.background-color]="
                        data.status === 'Pending'
                          ? 'rgb(103, 59, 141)'
                          : data.status === 'Reviewed' && data.comment
                          ? 'rgba(103, 59, 141, 0.67)'
                          : data.status === 'Completed'
                          ? 'rgb(106, 106, 152)'
                          : ''
                      ">
            {{ data.status }}
          </td>
          <td>
            <button mat-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
            <!-- <button mat-menu-item [routerLink]="['/user-dashboard/update-task']" [queryParams]="{ id: data.id }">
              <mat-icon>update</mat-icon>Update
            </button>  -->
          
              <button mat-menu-item (click)="openUpdateModal(data.id,data.subject)">
                <mat-icon>edit</mat-icon> Update
              </button>
       
          </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
