<h4> Update Salary Details <button mat-mini-fab color="accent" *ngIf="data"> User ID {{ userId }}</button></h4>
 <form *ngFor="let data of data" (submit)="updateUser($event)" >
  <table>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <label>hra:-</label>
          <input
            type="text"
            placeholder="HRA"
            id="hra"
            value="{{data.hra}}"
          /><br />
          <label>Medical Fund:-</label>
          <input
            type="text"
            placeholder="Medical Fund"
            id="medicalFund"
            value="{{data.medicalFund}}"
          /><br />
          <label>Convience:-</label>
          <input
            type="text"
            placeholder="Convience"
            id="convience"
            value="{{data.convience}}"
          /><br />

          <label>bonus:-</label>
          <input
            type="text"
            placeholder="Bonus"
            id="bonus"
            value="{{data.bonus}}"
          /><br />
          <label>Basic Salary:-</label>
          <input
            type="text"
            placeholder="Basic Salary"
            id="basicSalary"
            value="{{data.basicSalary}}"
          /><br />
          <label>Incentive:-</label>
          <input
            type="text"
            placeholder="Incentive"
            id="incentive"
            value="{{data.incentive}}"
          /><br />
      </div>
    </div>
    </div>
  </table>
  <button mat-raised-button color="accent" type="submit" style="margin-left: 300px;"> Update </button>
</form>
