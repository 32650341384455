import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forget-password-admin',
  templateUrl: './forget-password-admin.component.html',
  styleUrls: ['./forget-password-admin.component.css'],
})
export class ForgetPasswordAdminComponent implements OnInit {
  user: any = {
    password: '', 
    confirmPassword: '',
  };
  id: string | null;

  constructor(
    private mainService: MainService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.id = localStorage.getItem('id');
  }

  ngOnInit(): void {
  }

  updatePassword() {
    if (!this.id) {
      console.error('User ID not found.');
      this.showSnackBar('User ID not found.');
      return;
    }

    if (this.user.password !== this.user.confirmPassword) {
      console.error('Passwords do not match.');
      this.showSnackBar('Passwords do not match.');
      return;
    }

    const passwordPattern =
      /^(?=.*[a-zA-Z]{6,})(?=.*\d{4,})(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/;

    if (!passwordPattern.test(this.user.password)) {
      console.error('Password does not meet the requirements.');
      Swal.fire(
        'Password does not meet the requirements. It should contain at least 6 letters, one symbol, and four numeric values.'
      );
      return;
    }

    this.mainService.forgetPasswordAdmin(this.id, this.user).subscribe(
      (data) => {
        console.log('Password updated:', data);
        Swal.fire({
          title: 'Password updated successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          this.router.navigate(['/admin']);
        });
      },
      (error) => {
        console.error('Error updating password:', error);
        Swal.fire('Error updating password');
      }
    );
  }
  private showSnackBar(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
    });
  }
}
