<!-- app.component.html -->
<ngx-ui-loader
  fgsColor="#ff4081"
  pbColor="#ff4081"
  text="Please wait.."
  pbThickness="6"
  textColor="#ff4081"
></ngx-ui-loader>

<div ngxUiLoaderBlurred blur="6">
  <div class="global-style">
    <app-navbar></app-navbar>

    <!-- dynamic content -->
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </div>
</div>
