import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-review-graph',
  templateUrl: './review-graph.component.html',
  styleUrls: ['./review-graph.component.css']
})
export class ReviewGraphComponent implements OnInit {
  chart: Chart;
  performance: any;
  userId: any;
  pieChart: Chart;
  data: any[] = [];
  firstName:any;
  id:any;
  user:any={};
  constructor(private mainService: MainService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.mainService.review(this.userId).subscribe((data) => {
      this.performance = data;
      this.createChart();
      this.createPieChart();
    });

    this.mainService.showuserbyId(this.userId).subscribe((res) => {
    this.user = res;
    JSON.stringify(this.user)
    });

  }
getTotalScore(): number {
  if (!this.performance) return 0;

  let totalScore = 0;
  for (const item of this.performance) {
    totalScore += item['Total Score'];
  }
  return totalScore;
}

getObtainedScore(): number {
  if (!this.performance) return 0;

  let obtainedScore = 0;
  for (const item of this.performance) {
    obtainedScore += item['Obtained Score'];
  }
  return obtainedScore;
}

// ...

  createChart() {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.performance.map((item) => item.Month),
        datasets: [
          {
            label: 'Total Score',
            data: this.performance.map((item) => item['Total Score']),
            backgroundColor: '#980b36e3',
            borderColor: 'whitesmoke',
            borderWidth: 1,
          },
          {
            label: 'Obtained Score',
            data: this.performance.map((item) => item['Obtained Score']),
            backgroundColor: '#153d13',
            borderColor: 'whitesmoke',
            borderWidth: 1,
          },

        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

  }
  createPieChart() {
    let totalScore = 0;
    let obtainedScore = 0;

    this.performance.forEach((item) => {
      totalScore += item['Total Score'];
      obtainedScore += item['Obtained Score'];
    });

    const pieChartData = [
      {
        label: 'Total Score',
        totalScore: totalScore,
        backgroundColor: '#ce3262',
        borderColor: '#820c30',
      },
      {
        label: 'Obtained Score',
        totalScore: obtainedScore,
        backgroundColor: '#30773b',
        borderColor: '#064d11',

      },
    ];

    const ctx = document.getElementById('myPieChart') as HTMLCanvasElement;

    this.pieChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: pieChartData.map((item) => item.label),
        datasets: [
          {
            data: pieChartData.map((item) => item.totalScore),
            backgroundColor: pieChartData.map((item) => item.backgroundColor),
            borderColor: pieChartData.map((item) => item.borderColor),
          },
        ],
      },
      options: {
        responsive: true,

      },
      plugins: {
        legend: {
          display: true,
          position: 'right', 
        },
      },
    });
  }
}
