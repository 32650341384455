<div class="bootstrap-wrapper container2">
  <div class="row" style="height: 450px">
    <h4 style="text-align: center;font-size: 30px;color: white;width: 100%;">
      About Developers
    </h4>
    <hr class="animated-line" />
    <div class="col-md-12">
      <h2>Abhishek Srivastav <br />
        <h4>Full Stack Developer </h4>
      </h2>
      <div class="flex-container">
        <div class="image-container" style="margin-top: -24px">
          <img src="../../../../assets/Abhisheksrivastav.jpeg" alt="Developer Image Senior" style="box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);" />
        </div>
        <div class="content">
          <p>
            <span>“</span> Abhishek Srivastav is a dynamic Software Developer with a
            specialization in Full Stack Development, boasting proficiency in
            Java and Angular technologies. With a stellar track record in
            crafting exceptional digital solutions, Abhishek possesses a rich
            skill set that encompasses SpringBoot, Angular, MySQL, Git, Cloud
            Management, RESTful APIs, Java Core & Advanced concepts, Spring,
            and ORM. In the realm of project management and execution,
            Abhishek stands as a formidable leader. He takes pride in
            overseeing projects from their inception to deployment,
            meticulously planning each step along the way. His versatile
            expertise allows him to wear multiple hats, as he has successfully
            assumed roles as both a project manager and a hands-on developer.
            Abhishek's forte lies in designing robust database schemas and
            crafting efficient back-end solutions using Java SpringBoot.
            Simultaneously, he expertly manages the front-end using Angular,
            providing invaluable guidance to his teams at every stage of
            development. His knack for orchestrating seamless collaboration
            and harnessing the power of cutting-edge technologies ensures
            project success. His contributions to the world of software
            development exemplify excellence. His commitment to innovation,
            attention to detail, and unwavering dedication make him a valuable
            asset to any project.<span>”</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h2>
        Deepali Mishra <br />
        <h4>Web Developer</h4>
      </h2>
      <div class="flex-container">
        <div class="image-container">
          <img src="../../../../assets/Deepalimishra.jpeg" alt="Developer Image Junior" />
        </div>
        <div class="content">
          <p><span>“</span>
            Deepali Mishra, a talented Web Developer, brings expertise in PHP,
            Angular, HTML, CSS, Bootstrap, MySql and API implementation to the
            table. In her role, Deepali is the creative force behind stunning
            HTML/CSS designs and the seamless integration of APIs, bridging
            the gap between front-end and back-end functionality. Deepali's
            commitment to user-centered design extends to manipulating and
            optimizing data within web applications while adhering to ethical
            coding practices. Her innovative mindset allows her to introduce
            fresh design patterns, enhancing project outcomes. With a keen eye
            for detail, Deepali manages hosting and ensures the smooth
            operation of web projects.<span>”</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h2>
        Ashutosh Tripathi <br/>
        <h4>Android Developer</h4>
      </h2>
      <div class="flex-container">
        <div class="image-container">
          <img src="../../../../assets/Ashutosh.jpeg" alt="Developer Image Junior" />
        </div>
        <div class="content">
          <p><span>“</span>
            Ashutosh Tripathi is an Android Developer with a knack for
            creating intuitive mobile applications. Proficient in Android
            development, UI design, Java Core, and API integration, Ashutosh
            specializes in crafting seamless user experiences. He takes charge
            of end-to-end mobile app UI design and API integration, ensuring a
            smooth flow of data and functionality. Ashutosh's skills extend
            beyond aesthetics; he leverages API implementations to manipulate
            data, contributing to enhanced user experiences. His dedication to
            mobile app development and the ability to transform ideas into
            user-friendly interfaces make him an invaluable
            asset to any project.<span>”</span>
          </p>
        </div>