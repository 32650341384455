import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-user-announcement',
  templateUrl: './user-announcement.component.html',
  styleUrls: ['./user-announcement.component.css']
})
export class UserAnnouncementComponent implements OnInit {
  events: any[] = [];
  modalVisible: boolean = false;

  constructor(private mainService: MainService,private dialogRef: MatDialogRef<UserAnnouncementComponent>) { }

  ngOnInit(): void {
    this.mainService.newevent().subscribe(
      (res: any[]) => {
        this.events = res;
      },
      (error) => {
        console.error('Error fetching announcement:', error);
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
}

}
