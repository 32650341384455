<h2 mat-dialog-title>Hiring Details</h2>
<span class="modal-close" (click)="onNoClick()">X</span>
<mat-dialog-content>
    <div class="container">
        <div class="form-group">
            <div class="flex-item">
                <label for="firstName">First Name</label><br>
                <input type="text" id="firstName" [(ngModel)]="data.name" />
            </div>
            <div class="flex-item">
                <label for="email">Email</label><br>
                <input type="email" id="email" [(ngModel)]="data.email" />
            </div>
        </div>
        
        
        
        <div class="form-group">
            <div class="flex-item">
                <label for="phone">Phone No.</label><br>
                <input type="tel" id="phone" [(ngModel)]="data.phone" />
            </div>
            <div class="flex-item">
                <label for="dob">D.O.B</label><br>
                <input type="date" id="dob" [(ngModel)]="data.dob" />
            </div>
        </div>
        
        <div class="flex-item">
            <label for="zip">Zip Code</label><br>
            <input type="text" id="pincode" [(ngModel)]="data.pincode" (change)="getPincode(data.pincode)" />
        </div>
        
        <div class="form-group">
            <div class="flex-item">
                <label for="city">City</label><br>
                <input type="text" id="city" [(ngModel)]="address.city" />
            </div>
            <div class="flex-item">
                <label for="state">State / Province</label><br>
                <input type="text" id="state" [(ngModel)]="address.state" />
            </div>
        </div>
        
        <div class="flex-item">
            <label for="address">Address</label><br>
            <input type="text" id="currentAddress" [(ngModel)]="data.currentAddress" />
        </div>
        
        <!-- <div class="form-group">
            <label for="designation">Designation</label><br>
            <select id="designation" [(ngModel)]="data.designation">
                <option *ngFor="let option of designations" [value]="option.value">{{option.label}}</option>
            </select>
        </div> -->
        <div class="form-group">
            <label for="listVacancy">Vacancy</label><br>
            <select id="listVacancy" [(ngModel)]="data.vacancyId">
              <option *ngFor="let option of listVacancy" [value]="option.vacancyId">{{option.designation}}</option>
            </select>
          </div>
        
        <div class="form-group">
            <div class="flex-item">
                <label for="experience">Experience</label><br>
                <input type="text" id="experience" [(ngModel)]="data.experience" />
            </div>
        </div>
        
        <div class="form-group">
            <div class="flex-item">
                <label for="currentCTC">Current CTC</label><br>
                <input type="text" id="currentCTC" [(ngModel)]="data.currentCTC" />
            </div>
            <div class="flex-item">
                <label for="expectedCTC">Expected CTC</label><br>
                <input type="text" id="expectedCTC" [(ngModel)]="data.expectedCTC" />
            </div>
        </div>
        
        <div class="form-group">
            <div class="flex-item">
                <label for="noticePeriod">Notice Period</label><br>
                <input type="text" id="noticePeriod" [(ngModel)]="data.noticePeriod" />
            </div>
            <div class="flex-item">
                <label for="linkedin">LinkedIn</label><br>
                <input type="text" id="linkedin" [(ngModel)]="data.urls[1]" />
            </div>
        </div>
        
        <div class="form-group">
            <div class="flex-item">
                <label for="github">Github Link</label><br>
                <input type="text" id="github" [(ngModel)]="data.urls[2]" />
            </div>
            <div class="flex-item">
                <label for="twitter">Twitter Link</label><br>
                <input type="text" id="twitter" [(ngModel)]="data.urls[3]" />
            </div>
        </div>
        <div class="form-group">
            <div class="flex-item">
                <label for="twitter">Resume Upload</label><br>
                <input type="file" (change)="onFileSelected($event)"/>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
