<div class="container3">
  <h4 style="
              text-align: center;
              font-size: 30px;
              margin-top: -31px;
              color: White;
              margin-bottom: -1px;
            ">
    Update Assignment
  </h4>
  <hr class="animated-line" />
</div>

<mat-card>
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (ngSubmit)="onfromSubmit($event)">
            <mat-form-field class="w100" appearance="outline" style="margin-top: 16px">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="user.status" id="status" name="status">
                <mat-option value="Pending">Pending</mat-option>
                <mat-option value="Completed">Completed</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>