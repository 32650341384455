<div class=" show" >
<h1> Password Reset</h1>
<form (ngSubmit)="resetPassword()">
  <div class="form-field">
    <label for="email">Email:</label>
    <input [(ngModel)]="email" name="email" type="email" required matInput placeholder="Enter your email" class="custom-input" />
  </div>
  <div class="form-field">
    <label for="newPassword">New Password:</label>
    <input [(ngModel)]="newPassword" name="newPassword" type="password" required matInput placeholder="Enter your new password" class="custom-input" />
  </div>
  <div class="form-field">
    <label for="confirmPassword">Confirm Password:</label>
    <input [(ngModel)]="confirmPassword" name="confirmPassword" type="password" required matInput placeholder="Confirm your new password" class="custom-input" />
  </div>
  <div class="container text-center">
    <button mat-raised-button  type="submit">Reset Password</button>
  </div>
</form>
</div>
