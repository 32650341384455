import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-story',
  templateUrl: './add-story.component.html',
  styleUrls: ['./add-story.component.css']
})
export class AddStoryComponent implements OnInit {
  story: string = '';
  projectId: string;
  storyIndex: number | null = null;
  subtaskId :number;
  
  constructor(
    private mainService: MainService,
    private dialogRef: MatDialogRef<AddStoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string, story?: string, storyIndex?: number,subtaskId :number} // Inject dialog data
  ) {
    this.projectId = data.projectId;
    this.subtaskId = data.subtaskId;
    if (data.story) {
      this.story = data.story;
    }

    if (data.storyIndex !== undefined) {
      this.storyIndex = data.storyIndex;
    }
  }

  ngOnInit(): void { }

  formSubmit(): void {
    if (!this.projectId || !this.subtaskId) {
      console.error('Assignment ID is not defined');
      console.log("check"+this.projectId);
      console.log("test"+this.subtaskId)
      return;
    }

    const payload = {
      story:[this.story]
    };
    this.mainService.addStoryBySubtaskId(this.projectId,this.subtaskId,payload).subscribe({
      next: (res: any) => {
        Swal.fire('Successfully Added').then(() => {
          this.closeModal();
        });
      },
      error: (err: any) => {
        console.error('Error saving stage', err);
        Swal.fire('Error', 'There was an issue saving the stage.', 'error');
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }
}
