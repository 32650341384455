import { Component, OnInit } from '@angular/core';
import { Feedback } from '../../Models/feedback';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  activeTab: string = 'feedback'; // Default active tab

  showTab(tab: string) {
    this.activeTab = tab;
  }
  id = localStorage.getItem('id');
  feedbackForm: FormGroup;
  userId: number;
  feedbackData: Feedback = {
    feedback: '',
    section: '',
  };
  constructor(private mainService: MainService, private router: Router, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.initForm();
    this.userId = +this.id;
  }
  initForm() {
    this.feedbackForm = this.fb.group({
      section: ['', Validators.required],
      feedback: ['', Validators.required]
    });
  }

  submitFeedback() {
    this.mainService.saveFeedBack(this.userId, this.feedbackData).subscribe(
      (data: any) => {
        console.log(data);
        alert('success');
        Swal.fire('Successfully done !!', 'success');
        this.router.navigate(['feedback']);
      }, (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
