import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css', '/../../../shared/all-style/all-style.component.css']
})
export class AttendanceComponent implements OnInit {
  user_id = localStorage.getItem('id');
  users: Users[];
  userAttendanceList: any[];
  inputDateFromString: any;
  inputDateToString: any;
  filter: any;
  data: any;
  startDate: any;
  endDate: any;
  myDate = new Date();
  currDate: string;
  userList: any[];
  filteredData: any[] = [];
  showFilterForm: boolean = false;
  router: any;
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  user: any;

  constructor(private datePipe: DatePipe, private auth: MainService) {
    this.currDate = this.datePipe.transform(this.myDate, "dd-MM-yyyy");
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData(): void {
    this.auth.getattendancewithuser1(this.user_id, this.pageNumber, this.pageSize).subscribe((res: any) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      console.log("hello" + JSON.stringify(this.data));
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  filterTable(): void {
    const filterValue = (<HTMLInputElement>document.getElementById('filterInput')).value;
    if (!filterValue) {
      Swal.fire({
        title: 'Empty Search',
        text: 'Please enter a date to perform the search.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }

    const filteredData = this.data.filter((item) => {
      const itemDate = new Date(item.date);
      const filterDate = new Date(filterValue);
      return itemDate.toDateString() === filterDate.toDateString();
    });

    this.filteredData = filteredData;
  }

  navigateToAddUserAttendance() {
    this.router.navigate(['/user-dashboard/mark-attendance']);
  }

  filterByWeek(): void {
    this.auth.weeklyAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  filterByMonth(): void {
    this.auth.monthAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  filterByQuarter(): void {
    this.auth.quarterAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  getTimeAsMinutes(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // userAttendance() {
  //   this.auth.getUsers().subscribe((data) => {
  //     var list: Array<any> = [];
  //     for (let user of data) {
  //       var obj: any = {};
  //       obj["id"] = user.id;
  //       obj["name"] = user.firstName + " " + user.lastName;
  //       list.push(obj);
  //     }
  //     this.userList = list;
  //     this.userAttendanceList.forEach((item, index) => {
  //       item.serialNumber = index + 1;
  //     });
  //   });
  // }

  addFilteredSerialNumbers(filteredData: any[]) {
    filteredData.forEach((item, index) => {
      item.serialNumber = index + 1;
    });
  }

  liveLocation(id) {
    this.router.navigate(["/user-dashboard/google-map/" + id + "/" + "attendance"]);
  }

  userLiveLocation(id) {
    this.router.navigate(["/user-dashboard/google-map/" + id]);
  }

  agentIdChange(id) {
    this.user_id = id;
  }

  isClickedOnAttendance = false;

  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(date, "hh:mm a");
  }

  attendanceFilter() {
    if (!this.startDate || !this.endDate) {
      Swal.fire("Search for Filter");
      return;
    }

    this.inputDateFromString = this.auth.toTimestamp(this.startDate);
    this.inputDateToString = this.auth.toTimestamp(this.endDate);

    this.auth.getUserAttendanceList(this.user_id, this.inputDateFromString, this.inputDateToString, this.pageNumber, this.pageSize).subscribe(
      (res: any) => {
        this.data = res;
        this.userAttendanceList = this.data.content;
        this.totalElements = this.data.totalElements;
        this.addFilteredSerialNumbers(this.userAttendanceList);
        JSON.stringify(this.userAttendanceList);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }
}

export class Users {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  gender: string;
  dob: string;
  password: string;
  active: boolean;
  deleted: boolean;
  emailVerified: boolean;
  parentId: number;
  role: {
    role: string;
  };
  corporate: {
    corporate: string;
  };
}
