import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { Chart, ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-graph-events',
  templateUrl: './graph-events.component.html',
  styleUrls: ['./graph-events.component.css']
})
export class GraphEventsComponent implements OnInit {
  @ViewChild('myChart') barChartRef!: ElementRef;
  user_id = localStorage.getItem('id');
  data: any;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.loadBarChartData();
  }

  loadBarChartData() {
    this.mainService.taskgraphUser(this.user_id).subscribe((res) => {
      this.data = res;
      if (this.data != null) {
        this.createChart();
      }
    });
  }
  
  createChart() {
    const ctx = this.barChartRef.nativeElement.getContext('2d');
    
    const chartConfig: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.data.map((item: any) => item.Month),
        datasets: [
         
          {
            label: 'Total Task',
            data: this.data.map((item: any) => item['Total Task']),
            backgroundColor: '#a0284d',
            borderWidth: 1,
            borderColor: 'whitesmoke',
          },
          {
            label: 'Status - Completed',
            data: this.data.map((item: any) => item['Status - Completed']),
            backgroundColor: 'darkgreen',
            borderWidth: 1,
            borderColor: 'whitesmoke',
          },
          {
            label: 'Status - Pending',
            data: this.data.map((item: any) => item['Status - Pending']),
            backgroundColor: 'darkred',
            borderWidth: 1,
            borderColor: 'whitesmoke',
          },
          {
            label: 'Task Record',
            data: this.data.map(() => 0), // All values set to 0
            backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent color
            borderWidth: 0, // No border
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true, // Start y-axis from zero
          },
        },
      },
    };

    new Chart(ctx, chartConfig);
  }
}
