import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MainService } from 'src/app/MyService/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { Renderer2 } from '@angular/core';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css','/../../../shared/all-style/profile-style.component.css'],
})
export class UserProfileComponent implements OnInit {
    urls: any = {
      documents: [],
    };
  user: any = null;
  username = localStorage.getItem('username');
  id= localStorage.getItem('id');
  selectedFile: File | null = null;
  @ViewChild('fileInput') fileInput: any;
  isSubmitting = false;
  activeTab: string = 'profile'; // Default active tab

  showTab(tab: string) {
    this.activeTab = tab;
  }
  constructor(
    private login: LoginService,
    private mainService: MainService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.user = this.login.getUser();
    console.log("Hello, user data: ", this.user);
    this.mainService.showDocs(this.id).subscribe((res:any)=>{
      this.urls.documents= res.urls;
    })
  }

  onEditClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    console.log("Selected file: ", this.selectedFile);
    const folderName = 'profilePhotos';
    const data = new FormData();
    data.append('file', this.selectedFile as Blob);
  
    this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
      (response: any) => {
        const filePath = response.message;
        this.updateUserProfileImage(filePath); 
      },
      (error) => {
        console.error('File upload failed:', error);
        Swal.fire('Error', 'File upload failed.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  updateUserProfileImage(filePath: string) {
    console.log("Updating user profile image with URL: ", filePath); // Log the image URL before updating
    this.mainService.updateProfileImage(this.user.id, filePath).subscribe(
      response => {
        console.log("API response:", response);
        Swal.fire('Success', 'Profile image updated successfully', 'success');
        this.user.profileImage = filePath;
        this.isSubmitting = false;
      },
      error => {
        console.error("Update profile image error:", error); // Log any errors during profile image update
        Swal.fire('Error', 'Could not update profile image', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  onDeleteImage(imageUrl: string) {
    const publicId = this.getPublicIdFromUrl(imageUrl);
    console.log("publicId: " + publicId);
    console.log("hello" + imageUrl);
  
    if (publicId) {
      this.mainService.deleteImage(publicId).subscribe(
        response => {
          console.log("Deleted image:", response);
          Swal.fire('Success', 'Image deleted successfully', 'success');
          // After successful deletion from Cloudinary, update the profile image URL in the database to an empty string
          this.updateUserProfileImage('');
        },
        error => {
          console.error("Delete image error:", error);
          Swal.fire('Error', 'Could not delete image', 'error');
        }
      );
    }
  }
  
  getPublicIdFromUrl(imageUrl: string): string | null {
    // Check if imageUrl is not null or undefined
    if (!imageUrl) {
      return null;
    }
  
    // Extract public ID from Cloudinary URL, including potential subdirectories
    const regex = /\/v\d+\/(.+?)\.\w+$/;
    const match = imageUrl.match(regex);
    if (match && match[1]) {
      console.log("Extracted publicId: ", match[1]);
      return match[1];
    } else {
      console.log("No publicId found in URL: ", imageUrl);
      return null;
    }
  }
  getImageAlt(documentUrl: string): string {
    const fileName = documentUrl.split('/').pop();
    const fileNameWithoutExtension = fileName?.split('.')[0];
    return fileNameWithoutExtension || ''; 
  }

  downloadFile(fullUrl: string, fileName: string) {
    const fileUrl = fullUrl.split('/user/image/')[1];
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async downloadImage(imageUrl: string, imageName: string) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = imageName; // Set the file name for download
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);
      link.click(); // Simulate click event
      document.body.removeChild(link); // Clean up
      window.URL.revokeObjectURL(url); // Release the object URL
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }
}
