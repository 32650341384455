import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-developer',
  templateUrl: './about-developer.component.html',
  styleUrls: ['./about-developer.component.css']
})
export class AboutDeveloperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
