<h4>Salary Details</h4>
<div class="Salary-details" style="width: 100%; box-shadow: 0 0 10px #958c8c;">
<form *ngFor="let data of data">
    <table class="form-table">
      <tr>
        <th><label> Id </label></th>
        <th>
          <input type="text" value="{{ data.userId }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>Basic Salary </label></th>
        <th>
          <input type="text" value="{{ data.basicSalary }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>Hra : </label></th>
        <th><input type="text" value="{{ data.hra }}" readonly /></th>
      </tr>
      <tr>
        <th><label>Medical fund </label></th>
        <th>
          <input type="text" value="{{ data.medicalFund }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>Bonus </label></th>
        <th><input type="text" value="{{ data.bonus }}" readonly /></th>
      </tr>
      <tr>
        <th><label>Incentive </label></th>
        <th>
          <input type="text" value="{{ data.convience }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>Total Salary </label></th>
        <th>
          <input type="text" value="{{ data.totalsalary }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>First name </label></th>
        <th>
          <input type="text" value="{{ data.userName }}" readonly />
        </th>
      </tr>
      <tr>
        <th><label>Email </label></th>
        <th>
          <input type="text" value="{{ data.userEmail }}" readonly />
        </th>
      </tr>
      <th>
        <button mat-mini-fab mat-icon-button [matMenuTriggerFor]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-raised-button color="accent" (click)="openDialog(data.userId)">
            All Total Salary
          </button><br>
            <button mat-menu-item (click)="downloadpdf()">
              Download PDF
            </button>
        </mat-menu>
      </th>
    </table>
  </form>
</div>