import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.css']
})
export class UserAssignmentComponent implements OnInit {

  id = localStorage.getItem('id');
  userId: any;
  data: any;
  constructor(private http: HttpClient, private mainService: MainService,private router:Router) {}

  ngOnInit(): void {
    this.mainService.getallAssignmentByUser(this.id).subscribe(res => {
      this.data = res;
      JSON.stringify(this.data);
    });
   
  }
  navigateToAddUserProfile() {
    this.router.navigate(['user-dashboard/user-assign']);
  }
  formatDateTime(dateTime: number[]): string {
    const [year, month, day] = dateTime;

    if (isNaN(year) || isNaN(month) || isNaN(day) ) {
      return 'Invalid date and time';
    }

    const date = new Date(year, month - 1, day);
    const formattedDate = `${date.getFullYear()}-${this.padZero(date.getMonth() + 1)}-${this.padZero(date.getDate())}`;
    const formattedDateTime = `${formattedDate}`;
    return formattedDateTime;
  }
  padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }
  ondelete(id)
  {
     if(confirm("Are you sure want to delete ")){
      this.mainService.deleteassignment(id).subscribe(res=>{
        Swal.fire("Successfully deleted")
        window.location.reload();
      })
      }

  }

}
