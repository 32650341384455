<h4>Your Leaves</h4>

<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Leaves List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <button mat-button class="custom-button" (click)="navigateToLeaveApprove()">  Approve Leave
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
      <mat-icon>add</mat-icon> ApplyLeave
    </button> 
  </div>
</div>

<div class="filter-form-wrapper" *ngIf="showFilterForm" >
  <!-- Close icon -->

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" >
    <!-- Date range -->
    <div class="form-group" >
      <label for="dateRange" >Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style=" margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>

    <!-- Status -->
    <div class="form-group" >
      <label for="status" >Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control" style="width: 100%;">
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="APPROVED">Approved</option>
        <option value="REJECTED">Rejected</option>
      </select>
    </div>
    <div class="form-group">
      <label>Select Employee:</label>
      <select id="userId" name="employeeId" [(ngModel)]="user_id" style="width:100%">
        <option value="">Select Employee</option>
        <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div>
    
    <!-- Clear button -->
    <div class="filter-btn">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
  <div class="container2" style="margin-top: 40px;">
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>Apply Date</th>
          <th>Reason</th>
          <th>Leave Type</th>
          <th>Requested date</th>
          <th>Comments</th>
          <th>Approved By</th>
          <th>Status</th>
          <!-- <th><mat-icon mat-list-icon>edit</mat-icon>Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <td>{{ data.createdDate | date:'dd-MMM-yyyy hh:mm:a' }}</td>
          <td ><span (click)="openUserModal(data.reason,data.status,data.userName,data.leaveType)">{{ data.reason }}</span><span><a *ngIf="data.fileUrl" [href]="data.fileUrl" target="_blank"><img src="../../../../assets/task.png"  style="width: 20px;height:20px"/></a></span></td>
      
          <td>{{ getLeaveTypeText(data.leaveType) }}</td>
          <td><button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let leaveDate of data.leaveDates" style="color: black;">
              {{ leaveDate }}
            </button>
          </mat-menu>
          
          </td>
          <td>{{ data.comment }}</td>
          <td>{{data.approvedByName}}</td>
          <td style="width: 112px;color:white;" [style.background-color]="
            data.status === 'PENDING'
              ? 'rgb(103, 59, 141)'
              : data.status === 'APPROVED'
              ? 'rgb(106, 106, 152)'
              : 'rgba(103, 59, 141, 0.67)'
          ">
            {{getpendingtype(data.status)}}
          </td>
          <!-- <td>
            <button mat-button [matMenuTriggerFor]="actionMenu"><mat-icon mat-list-icon>more_vert</mat-icon></button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item [routerLink]="['/manager-dashboard/leaveapprove-dialog']" [queryParams]="{ id: data.userId, reason: data.reason, leave_id: data.id, todate: data.leaveDates[1], fromdate: data.leaveDates[0], leaveType: data.leaveType }"> <mat-icon mat-list-icon>update</mat-icon>Approve</button>
            </mat-menu>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  </div>
    <div class="pagination-container">
      <div class="pagination-info">
        Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
      </div>
      <mat-paginator 
        [length]="totalElements" 
        [pageSize]="pageSize" 
        [pageSizeOptions]="[10, 20, 50, 100]" 
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>

