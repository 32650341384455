<div class="bootstrap-wrapper" style="margin-right:15px">
    <div [ngStyle]="{ 'width': hideSidebar ? '100%' : '120%' }">
        <div class="row">
            <div class="col-md-2 scroll slidebar" style="margin-top: 64px;" *ngIf="hideSidebar">
                <app-sidebar-user></app-sidebar-user>
            </div>
            <div class="col-md-10 scroll" style="background: #161515;transition: transform 0.3s ease;margin-top: 64px;">
                <router-outlet>
                
                </router-outlet>
            </div>
        </div>
    </div>
</div>