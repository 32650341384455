
<h4> Messages</h4>
<div  class="mettingContainer">
  <form (ngSubmit)="formSubmit($event)" #meetingForm="ngForm">
    <div class="form-group">
      <label for="purpose">Purpose:</label>
      <input type="text" id="purpose" name="purpose" placeholder="Purpose" required />
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="date">Date:</label>
        <input style="height: 41px; border-radius: 5px; border: 1px solid #cccccc;" type="date" id="date" name="date" required />
      </div>
      <!-- <div class="form-group col-md-6">
        <label for="date">Schedule Date:</label>
        <input style="height: 41px;border-radius: 5px;border: 1px solid #cccccc;" type="time" id="meetingTime" name="meetingTime" required />
      </div> -->
    </div>
    <div class="form-group">
      <label for="description">Message:</label>
      <textarea style="width: 100%; border-radius: 4px;padding: 12px;font-size: 16px; height: 147px; border: 1ps solid grey; color: white;background:#ce32622e; " type="text" id="message" name="message" required></textarea>
    </div>
    <!-- <div class="form-group">
        <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)">
            <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Image</ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
    </div> -->
    <mat-form-field  class="white-text" class="w100" appearance="outline">
      <mat-label class="white-text">Select Employees</mat-label>
      <mat-select [formControl]="selectedEmployeesControl" class="white-text" multiple>
        <mat-option *ngFor="let employee of data" [value]="employee" style="text-transform:capitalize;" >
          ID: {{ employee.id }} - Name: {{ employee.firstName }} {{ employee.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button type="submit" class="submit-button" style="margin-left: 529px">
      Submit
    </button>
  </form>
</div>