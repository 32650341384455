import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-rate-performance',
  templateUrl: './rate-performance.component.html',
  styleUrls: ['./rate-performance.component.css']
})
export class RatePerformanceComponent implements OnInit {
  id = localStorage.getItem('id');
  data: any;
  performanceData: any;
  userId: any;

  currentIndex: number = 0;
  dataToShow: any;
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.mainService.report(this.id).subscribe((res) => {
      this.data = res;
      JSON.stringify(this.data);
      this.mainService.report(this.id).subscribe((performanceRes) => {
        this.performanceData = performanceRes;
        JSON.stringify(this.performanceData);
        this.loadNextData();
      });
    });
  }
  loadNextData() {
    this.dataToShow = this.performanceData.slice(this.currentIndex, this.currentIndex + 1);
  }

  previousData() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.loadNextData();
    }
  }
  nextData() {
    if (this.currentIndex < this.performanceData.length - 1) {
      this.currentIndex++;
      this.loadNextData();
    }
  }
}
