import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-user-review',
  templateUrl: './show-user-review.component.html',
  styleUrls: ['./show-user-review.component.css']
})
export class ShowUserReviewComponent implements OnInit {
  data: any;
  user_id: any;

  constructor(
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe((res) => {
    this.data = res;
    console.log("hello"+JSON.stringify(this.data));
    });
  }

  onSubmit() {
    if (this.user_id) {
      this.router.navigate(['/admin/show-user-ratings'], {
        queryParams: { id: this.user_id }
      });
    }
  }

  onUpdate() {
    if (this.user_id) {
      this.router.navigate(['/admin/update-review'], {
        queryParams: { id: this.user_id }
      });
    }
  }
}
