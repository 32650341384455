import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/MyService/main.service';
import { Tooltip } from 'bootstrap';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import interactionPlugin from '@fullcalendar/interaction';
import { EventModelComponent } from 'src/app/pages/admin/event-model/event-model.component';
import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
          })
export class CalenderComponent implements OnInit
{
  addEventForm: FormGroup;
  submitted = false;
  calendarOptions: CalendarOptions;
  currentView: string = 'dayGridMonth';
  userId = localStorage.getItem('id');
  data: any;
  fetchedEvents: any[] = [];
  events: any[] = [];
  phone = localStorage.getItem('id');
  start:any;
  end:any;
  title:any;
  description:any;
  eventData:any;
  hideSidebar = false;
  user_id:any;
  constructor(private formBuilder: FormBuilder, private mainService: MainService,private dialogRef:MatDialog, private route: ActivatedRoute,private router: Router,) {}


  openDialog()
  {
    this.dialogRef.open(EventModelComponent);
  }

  ngOnInit(): void
  {
    this.user_id = this.userId;
    this.router.events.subscribe(event =>
      {
      if (event instanceof NavigationEnd)
      {
        this.hideSidebar = this.route.snapshot.data.hideSidebar;
      }
      });
    this.mainService.getEventsByAdmin(this.phone).subscribe((res) =>
    {
      this.data = res;
    });

    this.mainService.getEventsByAdmin(this.userId).subscribe(
      (res: any) => {
        this.fetchedEvents = res;
      this.events = this.fetchedEvents.map((event: any) => ({
      title: event.title,
      id : event.id,
      description: event.description,
      start: Array.isArray(event.start) ? new Date(event.start[0], event.start[1] - 1, event.start[2], event.start[3], event.start[4]) : null,
      end: Array.isArray(event.end) ? new Date(event.end[0], event.end[1] - 1, event.end[2], event.end[3], event.end[4]) : null
        }));
          this.calendarOptions.events = this.events;
      }
    );

    this.addEventForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

    this.calendarOptions =
    {
      editable: true,
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: this.currentView,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      events: this.events,
      dayHeaderFormat: { weekday: 'long' },
      eventDrop: this.handleEventDrop.bind(this),
      eventResize: this.handleEventResize.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventDidMount: this.handleEventMount.bind(this)
    };
  }
  handleEventDrop(arg)
  {
    const updatedEvent = arg.event;
    const updatedEventId = updatedEvent.id;
    const updatedEventStart = updatedEvent.start;
    const updatedEventEnd = updatedEvent.end;
    const eventToUpdate = this.events.find((event) => event.id === event.id);
    if (eventToUpdate)
    {
      eventToUpdate.start = updatedEventStart;
      eventToUpdate.end = updatedEventEnd;
    }
    this.updateEventOnServer(updatedEventId, updatedEventStart, updatedEventEnd);
  }

  handleEventResize(arg)
  {

  }
  get f() {
    return this.addEventForm.controls;
  }

  updateEventOnServer(eventId: string, start: Date, end: Date)
  {
    const eventData = {
      id: eventId,
      start: start.toISOString(),
      end: end.toISOString(),
    };


    this.mainService.updateEvent(this.user_id, eventData).subscribe(
      (res) =>
      {
        console.log('Event updated on the server:', res);
        const eventToUpdate = this.events.find((event) => event.id === eventId);
        if (eventToUpdate)
        {
          eventToUpdate.start = start;
          eventToUpdate.end = end;
        }
      },
      (error) =>
      {
        console.error('Error updating event on the server:', error);
      }
    );
  }


  onSubmit()
  {
    this.submitted = true;
    this.addEventForm.markAllAsTouched();
    if (this.addEventForm.invalid)
    {
      return;
    }

    const selectedEventDate = this.addEventForm.value.eventDate;
    console.log('Selected Event Date:', selectedEventDate);
    this.addEventForm.reset();
    $('#myModal').modal('hide');
  }

  handleDayRender(arg)
  {
    const cell = arg.el;
    cell.addEventListener('click', () => {
      this.handleDateClick(arg.date);
    });
  }

  handleDateClick(arg)
  {
    this.addEventForm.patchValue({ eventDate: arg.dateStr + 'T00:00:00' });
    this.changeView('dayGridDay');
    $('#myModal').modal('show');
  }

  handleEventClick(event: any)
  {
    const clickedEvent = event.event;
    const eventObj = {
      title: clickedEvent.title,
      description: clickedEvent.extendedProps.description,
      start: clickedEvent.start,
      end: clickedEvent.end
    };
    this.events = [eventObj];
    this.updateEventsContainer();
  }

  updateEventsContainer()
  {
      const eventsContainer = document.getElementById('eventsContainer');
      eventsContainer.innerHTML = '';
      this.events.forEach((event) => {
      const eventElement = document.createElement('div');
      eventElement.innerHTML = `<strong>${event.title}</strong><br>`;
      if (this.isCurrentView('dayGridMonth') || this.isCurrentView('timeGridWeek'))
      {
        eventElement.innerHTML += `${event.start}<br>${event.end}<br>Description: ${event.description}`;
      } else
      {
        eventElement.innerHTML += `${event.start}<br>${event.end}`;
      }
      eventsContainer.appendChild(eventElement);
    });
  }

  handleEventMount(info)
  {
    const tooltip = new Tooltip(info.el, {
      title: info.event.extendedProps.description,
      placement: 'top',
      trigger: 'hover',
      container: 'body'
    });
    info.el.addEventListener('mouseenter', () => {
      tooltip.show();
    });
    info.el.addEventListener('mouseleave', () => {
      tooltip.hide();
    });
    if (this.isCurrentView('dayGridMonth') || this.isCurrentView('timeGridWeek'))
    {
      const eventElement = info.el.querySelector('.fc-event-title');
      const descriptionElement = document.createElement('div');
      descriptionElement.classList.add('fc-event-description');
      descriptionElement.textContent = info.event.extendedProps.description;
      eventElement.appendChild(descriptionElement);
    }
  }
  changeView(view: string)
  {
    this.currentView = view;
    this.calendarOptions.initialView = view;
  }

  isCurrentView(view: string): boolean
  {
    return this.currentView === view;
  }

  hideForm()
  {
    this.addEventForm.patchValue({ title: '', description: '' });
    this.addEventForm.get('title').clearValidators();
    this.addEventForm.get('title').updateValueAndValidity();
  }
  parseDateString(dateString: string): Date | null
  {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  formatDateTimeRange(start: string, end: string): string
  {
    const startDate = this.parseDateString(start);
    const endDate = this.parseDateString(end);

    if (!startDate || !endDate)
    {
      return 'Invalid date range';
    }

    const startFormattedDate = this.formatDate(startDate);
    const startFormattedTime = this.formatTime(startDate);
    const endFormattedDate = this.formatDate(endDate);
    const endFormattedTime = this.formatTime(endDate);
    return `${startFormattedDate} - ${startFormattedTime} to ${endFormattedDate} - ${endFormattedTime}`;
  }

  formatDate(date: Date): string
  {
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatTime(date: Date): string
  {
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    return `${hours}:${minutes}`;
  }


  padZero(num: number): string
  {
    return num < 10 ? `0${num}` : `${num}`;
  }

  ondelete(id) {
    if (confirm("Are you sure you want to delete?")) {
      this.mainService.deleteEvents(id).subscribe(
        (res) => {
          Swal.fire({
            title: "Successfully deleted",
            icon: "success",
          }).then(() => {
            // Reload the current page
            window.location.reload();
          });
        },
        (error) => {
          console.log(error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        }
      );
    }
  }
  
  


}







