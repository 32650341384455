import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-event-model',
  templateUrl: './event-model.component.html',
  styleUrls: ['./event-model.component.css'],
})
export class EventModelComponent implements OnInit {
  currentDateTime: string;
  title: string;
  eventDate: Date;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  description: string;
  data: any;
  userId: any;
  id = localStorage.getItem('id');
  
  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentDateTime = new Date().toLocaleString();
  }

  formSubmit() {
    const eventData = {
      title: this.title,
      description: this.description,
      start: new Date(this.startDate + ' ' + this.startTime).toISOString(),
      end: new Date(this.endDate + ' ' + this.endTime).toISOString(),
    };

    // console.log('-------+ userId= ' + this.userId);
    this.mainService.saveEventsByAdmin(this.id, eventData).subscribe(
      (res: any) => {
        this.data = res;
        Swal.fire('Success', 'Event added successfully', 'success');
        window.location.reload();
        this.router.navigate(['/calender']);
        this.title = '';
        this.description = '';
        this.startDate = '';
        this.startTime = '';
        this.endDate = '';
        this.endTime = '';
      },
      (error: any) => {
        Swal.fire('Error', 'Failed to add event', 'error');
      }
    );
  }

  onCancel() {
    this.title = '';
    this.eventDate = null;
    this.startDate = '';
    this.startTime = '';
    this.endDate = '';
    this.endTime = '';
    this.description = '';
  }
}
