import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-single-employee-attendance',
  templateUrl: './single-employee-attendance.component.html',
  styleUrls: ['./single-employee-attendance.component.css'],
})
export class SingleEmployeeAttendanceComponent implements OnInit {
  user_id: any;
  users: Users[];
  userAttendanceList: any[];
  inputDateFromString: any;
  inputDateToString: any;
  dropdownSettings: IDropdownSettings = {};
  filter: any;
  data: any;
  start_date: any;
  end_date: any;
  myDate = new Date();
  currDate: string;
  userList: any[];
  filteredData: any[] = [];
  showFilterForm: boolean = false;
  phone: any;
  pageSize: number=10;
  pageNumber: number=0;
  totalElements: number =0;

  constructor(
    private datePipe: DatePipe,
    private auth: MainService,
    private route: ActivatedRoute
  ) {
    this.currDate = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');
  }

  ngOnInit() {
    this.phone = this.route.snapshot.queryParamMap.get('phone');
    this.user_id = this.route.snapshot.queryParamMap.get('phone');
    this.fetchData();
    // this.auth.getattendancewithuser1(this.phone).subscribe((res) => {
    //   this.data = res;
    //   console.log('hey baby    ' + JSON.stringify(this.data));
    //   this.userAttendanceList = this.data;
    //   // this.filteredData = this.data; // Initialize userAttendanceList with the initial data
    //   this.userAttendance();
    // });
  }
fetchData(){
  this.auth.getattendancewithuser1(this.user_id,this.pageNumber,this.pageSize).subscribe((res) => {
      this.data = res;
      console.log('hey baby    ' + JSON.stringify(this.data));
      this.userAttendanceList = this.data.content;
      this.totalElements=this.data.totalElements;
      // this.filteredData = this.data; // Initialize userAttendanceList with the initial data
      this.userAttendance();
    });
}
  filterTable(): void {
    const filterValue = (<HTMLInputElement>(
      document.getElementById('filterInput')
    )).value;

    // Check if filterValue is empty 
    if (!filterValue) {
      Swal.fire({
        title: 'Empty Search',
        text: 'Please enter a date to perform the search.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return; // Stop the function execution
    }

    const filteredData = this.data.filter((item) => {
      const itemDate = new Date(item.date);
      const filterDate = new Date(filterValue);
      return itemDate.toDateString() === filterDate.toDateString();
    });

    this.filteredData = filteredData;
  }

  filterByWeek(): void {
    this.auth.weeklyAttendance(this.user_id,this.pageNumber,this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements=this.data.totalElements;
    });
  }
  filterByMonth(): void {
    this.auth.monthAttendance(this.user_id,this.pageNumber,this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements=this.data.totalElements;
    });
  }
  filterByQuarter(): void {
    this.auth.quarterAttendance(this.user_id,this.pageNumber,this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements=this.data.totalElements;
    });
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  getTimeAsMinutes(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }

  userAttendance() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj['id'] = user.id;
        obj['name'] = user.firstName + ' ' + user.lastName;
        list.push(obj);
      }
      this.userList = list;

      this.userAttendanceList.forEach((item, index) => {
        item.serialNumber = index + 1;
      });
    });
  }

  liveLocation(id) {
    window.open('/admin/userGoogleMap/' + id + '/' + 'attendance');
  }

  userLiveLocation(id) {
    window.open('/admin/userGoogleMap/' + id);
  }

  agentIdChange(id) {
    this.user_id = id;
  }

  isClickedOnAttendance = false;

  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(date, 'hh:mm a');
  }
  addFilteredSerialNumbers(filteredData: any[]) {
    filteredData.forEach((user, index) => {
      user.serialNumber = index + 1;
    });
  }
  AttendanceFIlter(event: any) {
    if (!this.start_date || !this.end_date) {
      Swal.fire('Search for Filter');
      return;
    }
    this.inputDateFromString = this.auth.toTimestamp(this.start_date);
    this.inputDateToString = this.auth.toTimestamp(this.end_date);
    console.log('user id : ' + this.user_id);
    console.log('Start date : ' + this.inputDateFromString);
    console.log('End date : ' + this.inputDateToString);

    this.auth
      .getUserAttendanceList(
        this.user_id,
        this.inputDateFromString,
        this.inputDateToString,this.pageNumber,this.pageSize
      )
      .subscribe(
        (res: any) => {
          this.data=res;
          this.userAttendanceList = this.data.content;
          this.totalElements = this.data.totalElements; // Update the userAttendanceList with the filtered data
          this.addFilteredSerialNumbers(this.userAttendanceList);
          console.log(
            'Filter Response: ' + JSON.stringify(this.userAttendanceList)
          );
        },
        (error) => {
          console.log(error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        }
      );
  }
}
export class Users {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  gender: string;
  dob: string;
  password: string;
  active: boolean;
  deleted: boolean;
  emailVerified: boolean;
  parentId: number;
  role: {
    role: string;
  };
  corporate: {
    corporate: string;
  };
}
