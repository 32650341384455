import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-stages',
  templateUrl: './add-stages.component.html',
  styleUrls: ['./add-stages.component.css']
})
export class AddStagesComponent implements OnInit {
  stage: {
    stageName: string;
    status: string;
    stageId?: number;  
  } = {
    stageName: '',
    status: 'INITIALIZED',
  };
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  assignmentId: number;
  isUpdateMode: boolean = false;

  constructor(
    private mainService: MainService,
    private dialogRef: MatDialogRef<AddStagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: number, stageId?: number }
  ) {
    this.assignmentId = data.projectId;
    if (data.stageId) {
      this.isUpdateMode = true;
      this.stage.stageId = data.stageId;
    }
  }
  ngOnInit(): void {
    if (this.isUpdateMode && this.stage.stageId) {
      this.mainService.getStageById(this.assignmentId, this.stage.stageId, this.pageNumber, this.pageSize).subscribe({
        next: (res: any) => {
          const stageData = res.content.find((stage: any) => stage.stageId === this.stage.stageId);
          if (stageData) {
            this.stage = {
              stageName: stageData.stageName,
              status: stageData.status, 
              stageId: stageData.stageId
            };
          } else {
            Swal.fire('Error', 'Stage data not found in the current page.', 'error');
          }
        },
        error: (err: any) => {
          Swal.fire('Error', 'Could not load stage data.', 'error');
        }
      });
    }
  }
  
  formSubmit(): void {
    if (!this.assignmentId) {
      console.error('Assignment ID is not defined');
      return;
    }

    const payload = {
      stageName: this.stage.stageName,
      status: this.stage.status,
    };

    if (this.isUpdateMode) {
      this.updateStage(payload);
    } else {
      this.addStage(payload);
    }
  }

  addStage(payload: any): void {
    this.mainService.addStage(this.assignmentId, payload).subscribe({
      next: (res: any) => {
        Swal.fire('Successfully Added').then(() => {
          this.closeModal();
        });
      },
      error: (err: any) => {
        Swal.fire('Error', 'There was an issue saving the stage.', 'error');
      }
    });
  }

  updateStage(payload: any): void {
    this.mainService.updateStagesOfAssignment(this.assignmentId, this.stage.stageId!, payload).subscribe({
      next: (res: any) => {
        Swal.fire('Stage successfully updated').then(() => {
          this.closeModal();
        });
      },
      error: (err: any) => {
        Swal.fire('Error', 'There was an issue updating the stage.', 'error');
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
