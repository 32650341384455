import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-meeting',
  templateUrl: './user-meeting.component.html',
  styleUrls: ['./user-meeting.component.css', '/../../../shared/all-style/all-style.component.css']
})
export class UserMeetingComponent implements OnInit {
  categories: any[] = [];
  data: any;
  purpose: any;
  userId: string;
  id = localStorage.getItem('id');
  filteredData: any[] = [];
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  status: string = '';
  startDate: string = '';
  endDate: string = '';
  user_id: string = '';
  inputDateToString: any;
  inputDateFromString: any;
  showFilterForm: boolean = false;
  employees: any;
  constructor(
    private mainService: MainService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchData();
    this.mainService.managerAlllist(this.id, true).subscribe((res: any) => {
      this.employees = res;
    });
  }

  
  togglePresence(data: any,user:any) {
    const meetingId = data.id;
    const updatedIsPresent = !user.isUserPresent;
    this.mainService.updatePresence(meetingId, updatedIsPresent).subscribe(
      response => {
        Swal.fire('Presence updated successfully', response.message, 'success');
        // data.isPresent = updatedIsPresent;
        user.isUserPresent = updatedIsPresent; 
        console.log("helloooooooooooooooooooo"+data.isUserPresent)
      },
      error => {
        Swal.fire('Error', error.error.message || 'An error occurred while updating presence.', 'error');
      }
    );
  }

  fetchData(userId: string = this.user_id || this.id, pageNumber: number = this.pageNumber, pageSize: number = this.pageSize, status: string = this.status, startDate: string = this.startDate, endDate: string = this.endDate): void {
    this.mainService.usergetmeetingbyid(userId, pageNumber, pageSize, status, startDate, endDate).subscribe((res: any) => {
      this.data = res;
      this.filteredData = this.data.content;
      this.totalElements = this.data.totalElements;
      console.log("hello" + JSON.stringify(this.data));
      console.log("localStorage id: " + this.id);

      this.filteredData.forEach((meeting: any) => {
        meeting.assignedUsers.forEach((user: any) => {
          console.log("assignToId: " + user.assignToId);
          console.log("Match: " + (this.id == user.assignToId));
          console.log("Match: "+"LocalStorage Id" + this.id+"AssignId"+user.assignToId);
        });
      });
     
    });
  }
  

  clearFilters(): void {
    this.status = '';
    this.startDate = '';
    this.endDate = '';
    this.user_id = '';
    this.fetchData();
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.user_id || this.id, this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate);
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  onSubmitFilter(): void {
    const userId = this.user_id || this.id;
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.fetchData(userId, this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString);
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }

  navigateToAddUserTask(): void {
    this.router.navigate(['/manager-dashboard/add-meeting']);
  }
}
