import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

interface Data {
  autoStatus: string;
  vacancyId: number;
  name: string;
  phone: string;
  designation: string;
  email: string;
  dob: string;
  pincode: string;
  experience: string;
  currentAddress: string;
  currentCTC: string;
  expectedCTC: string;
  noticePeriod: string;
  urls: string[];
  resume: any;
  progressStatus: 'SELECTED';
}

interface Address {
  country: string;
  city: string;
  state: string;
  pinCode: string;
}

interface Vacancy {
  id: number;
  designation: string;
}

@Component({
  selector: 'app-candidate-save',
  templateUrl: './candidate-save.component.html',
  styleUrls: ['./candidate-save.component.css']
})
export class CandidateSaveComponent implements OnInit {
  id = localStorage.getItem('id');
  resume: File | null = null;
  newKeyword: string = '';
  listVacancy: Vacancy[] = [];
  data: Data = {
    autoStatus: 'SELECTED',
    vacancyId: 0,
    name: '',
    phone: '',
    designation: '',
    email: '',
    dob: '',
    pincode: '',
    experience: '',
    currentAddress: '',
    currentCTC: '',
    expectedCTC: '',
    noticePeriod: '',
    urls: ['', '', ''],
    resume: '',
    progressStatus: 'SELECTED'
  };
  address: Address = {
    country: '',
    city: '',
    state: '',
    pinCode: ''
  };

  constructor(
    public dialogRef: MatDialogRef<CandidateSaveComponent>,
    private mainService: MainService
  ) { }

  ngOnInit(): void {
    console.log("hello" + this.id);
    this.mainService.getAllvacancy().subscribe((res: any) => {
      this.listVacancy = res;
      console.log("vacancyid" + JSON.stringify(this.listVacancy));
    },
    (error) => {
      console.log(error);
      Swal.fire('Error', ' ' + error.error.message, 'error');
    });
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.resume = file;
    }
  }

  resumeSave() {
    const folderName = 'resume';
    const data = new FormData();
    data.append('file', this.resume as Blob);
  
    if (this.resume) {
      return this.mainService.uploadFile(this.resume,folderName).pipe(
        switchMap((res: any) => {
          this.data.resume = res.message; 
          return of(res); 
        })
      );
    } else {
      return of(null); 
    }
  }

  save() {
    this.resumeSave().pipe(
      switchMap(() => {
        return this.mainService.saveHiringBySystem(this.id, this.data);
      })
    ).subscribe(
      (response) => {
        Swal.fire('Vacancy saved successfully');
        this.dialogRef.close();
        window.location.reload();
      },
      (error) => {
        Swal.fire('Error saving vacancy:', error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getPincode(pincode: string) {
    this.mainService.getPincode(pincode).subscribe(
      (address: Address) => {
        this.data.currentAddress = `${address.city}, ${address.state}`;
        this.data.pincode = address.pinCode;
        this.address.city = address.city;
        this.address.state = address.state;
      },
      (error) => {
        console.error('Error fetching address data:', error);
      }
    );
  }
}
