import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-salary',
  templateUrl: './add-salary.component.html',
  styleUrls: ['./add-salary.component.css'],
})
export class AddSalaryComponent implements OnInit {
  private routeSub: Subscription;
  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  userId: string;
  id: string;
  data: any;
  public user = {
    basicSalary: '',
    bonus: '',
    convience: '',
    hra: '',
    medicalFund: '',
    incentive: '',
  };

  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
    });
  }
  formSubmit() {
    if (this.user.basicSalary == '' || this.user.basicSalary == null) {
      this.snack.open('Basic Salary is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.convience == '' || this.user.convience == null) {
      this.snack.open('convience is required !! ', '', {
        duration: 3000,
      });
      return;
    }
    if (this.user.hra == '' || this.user.hra == null) {
      this.snack.open('hra is required !! ', '', {
        duration: 3000,
      });
      return;
    }
    if (this.user.medicalFund == '' || this.user.medicalFund == null) {
      this.snack.open('medicalFund is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    this.mainService.addsalaryprofile(this.id, this.user).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Success')
        this.router.navigate(['admin/show-employee-salary']);
      },
      (error) => {
        console.log(error);

        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
