<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

<div class="card task-card" style="margin-top: 30px;">
  <div class="card-body">
    <form (ngSubmit)="formSubmit()">
      <h2 class="form-heading text-center">Add Task</h2>
      <div class="form-group">
        <label for="employee">Select Employee</label>
        <select name="employee" id="employee" [(ngModel)]="employee" aria-placeholder="Select Employee" class="form-control" required>
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of data" [value]="employee.id">
            {{employee.userName}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="taskDescription">Task Deadline</label>
        <input [(ngModel)]="user.deadLine" name="deadLine"  type="date" id="taskDeadline" class="form-control" placeholder="Enter task deadline" rows="5" required>
      </div>
      <div class="form-group">
        <label for="taskDescription">Task Description</label>
        <textarea [(ngModel)]="user.subject" name="Task" id="taskDescription" class="form-control" placeholder="Enter task description" rows="5" required></textarea>
      </div>
      <div class="form-group">
        <label for="taskFiles">Drop Task Files</label>
        <ngx-dropzone (change)="onSelect($event)" class="custom-dropzone">
          <ngx-dropzone-label><i class="fas fa-cloud-upload-alt"></i><br>Drop Task Files</ngx-dropzone-label>
          <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn" [disabled]="isSubmitting">Save Task</button>
        <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
      </div>
    </form>
  </div>
</div>