<mat-card >
  <mat-action-list>
    <div mat-subheader style="background: #161515; color: white">Menu</div>

    <!-- <div class="callbutton" style="width: 90px">
      <a
        [href]="generateWhatsAppLink('Hi, Welcome to Invest Mango HrConsole.')"
        target="_blank"
        style="float: left"
      >
        <img
          src="../../../../assets/whatsapp.jpg"
          alt="phone"
          target="_blank"
          title="Call NOW"
          style="
            height: 50px;
            margin-bottom: -40px;
            margin-left: 50px;
            width: 50px;
            border-radius: 50%;
          "
          target="_blank"
        />
      </a>
    </div>
    <div class="email" style="width: 90px">
      <a
        [attr.href]="
          generateMailtoLink('nishumishra9161@gmail.com', 'Subject of Email')
        "
        target="_blank"
        style="float: left"
      >
        <img
          src="../../../../assets/email.jpg"
          alt="phone"
          target="_blank"
          title="Call NOW"
          style="
            height: 50px;
            margin-bottom: -40px;
            margin-left: 226px;
            width: 50px;
            border-radius: 50%;
          "
          target="_blank"
        />
      </a>
    </div> -->

    <button routerLink="/admin" mat-list-item>
      <mat-icon mat-list-icon>home</mat-icon> Home
    </button>

    <button routerLink="/admin/profile" mat-list-item>
      <mat-icon mat-list-icon>person</mat-icon> My Profile
    </button>
    <button mat-list-item routerLink="/admin/all-employee-profile">
      <mat-icon mat-list-icon>manage_accounts</mat-icon> Employee's Profile
    </button>

    <!-- <button mat-list-item routerLink="/admin/all-candidate">
      <mat-icon mat-list-icon>person_add</mat-icon> Applicant Pool
    </button> -->
    
   <button mat-list-item routerLink="/admin/hiring-candidate">
      <mat-icon mat-list-icon>person_add</mat-icon> Applicant Pool
    </button>
    <button mat-list-item routerLink="/admin/candidate-update">
      <mat-icon mat-list-icon>person_add</mat-icon> Candidate
    </button>
    <button mat-list-item routerLink="/admin/show-meetings">
      <mat-icon mat-list-icon>voice_chat</mat-icon>Meetings
    </button>
    <!-- <button mat-list-item routerLink="/admin/quizzes">
      <mat-icon mat-list-icon>local_hotel</mat-icon> Holiday
    </button> -->
    <button mat-list-item routerLink="/calender">
      <mat-icon mat-list-icon>event</mat-icon>Calender
    </button>

    <button mat-list-item routerLink="/admin/showuser-task">
      <mat-icon mat-list-icon>wb_incandescent</mat-icon>Tasks
    </button>
    <!-- <button mat-list-item routerLink="/admin/assignment">
      <mat-icon mat-list-icon>assignment</mat-icon>Assignment
    </button> -->
    
    <button mat-list-item routerLink="/admin/project">
      <mat-icon mat-list-icon>assignment</mat-icon>Assignment
    </button>
    <button mat-list-item routerLink="/admin/new-announcement">
      <mat-icon mat-list-icon>announcement</mat-icon>Announcement
    </button>
    <button mat-list-item routerLink="/admin/HrAttendance">
      <mat-icon mat-list-icon>fingerprint</mat-icon>Attendance
    </button>
    <button mat-list-item routerLink="/admin/show-employee-salary">
      <mat-icon mat-list-icon>account_balance_wallet</mat-icon>Payroll Panel
    </button>
    <button mat-list-item routerLink="/admin/showLeave-Request">
      <mat-icon mat-list-icon>accessibility_new</mat-icon>Day Off Applications

    </button>
    <button mat-list-item routerLink="/admin/show-chat">
      <mat-icon mat-list-icon >messages</mat-icon>
      Messages
    </button>
    
    <button mat-list-item routerLink="/admin/show-user-review">
      <mat-icon mat-list-icon>rate_review</mat-icon>Employees’ Performance
    </button>
    <button mat-list-item routerLink="/admin/email">
      <mat-icon mat-list-icon>email</mat-icon>Email
    </button>
    <button mat-list-item routerLink="/admin/forget-password-admin">
      <mat-icon mat-list-icon>lock</mat-icon>Reset Password

    </button>
  <!-- <button mat-list-item routerLink="/admin/about-developer">
    <mat-icon mat-list-icon>developer_board</mat-icon>About Developers
  </button> -->
    <button mat-list-item (click)="logout()">
      <mat-icon mat-list-icon>logout</mat-icon> Sign Off

    </button>
  </mat-action-list>
</mat-card>
