import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-candidate',
  templateUrl: './save-candidate.component.html',
  styleUrls: ['./save-candidate.component.css'],
})
export class SaveCandidateComponent implements OnInit {
  id = localStorage.getItem('id');
  data: any = {
    gender: null,
    designation: null,
  };

  constructor(private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
  }

  formSubmit(event) {
    event.preventDefault(); 
    const target = event.target;
    const arrObj = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    if (
      !this.data ||
      this.data.designation === null ||
      this.data.designation === undefined
    ) {
      Swal.fire('Please select a designation');
      return;
    } else {
      arrObj['designation'] = this.data.designation;
    }

    if (target.querySelector('#name').value.trim() === '') {
      Swal.fire('Please enter your name');
      return;
    } else {
      arrObj['name'] = target.querySelector('#name').value;
    }

    if (target.querySelector('#email').value.trim() === '') {
      Swal.fire('Please enter your email');
      return; 
    } else {
      const emailInput = target.querySelector('#email').value;
      if (emailRegex.test(emailInput)) {
        arrObj['email'] = emailInput;
      } else {
        Swal.fire('Invalid email format');
        return; 
      }
    }

    if (target.querySelector('#mobile').value.trim() === '') {
      Swal.fire('Please enter your mobile number');
      return; 
    } else {
      const phoneInput = target.querySelector('#mobile').value;
      if (phoneRegex.test(phoneInput)) {
        arrObj['mobile'] = phoneInput;
      } else {
        Swal.fire(
          'Invalid phone number format. It should be a 10-digit number.'
        );
        return;
      }
    }

    if (this.data && this.data.gender != null) {
      arrObj['gender'] = this.data.gender;
    }
    if (target.querySelector('#dob').value.trim() === '') {
      Swal.fire('Please enter your Date of Birth');
      return;
    } else {
      arrObj['dob'] = target.querySelector('#dob').value;
    }
    if (target.querySelector('#location').value.trim() === '') {
      Swal.fire('Please enter your Location');
      return;
    } else {
      arrObj['location'] = target.querySelector('#location').value;
    }

    if (target.querySelector('#experience').value.trim() === '') {
      Swal.fire('Please enter your Experience');
      return;
    } else {
      arrObj['experience'] = target.querySelector('#experience').value;
    }
    if (target.querySelector('#currentCTC').value.trim() === '') {
      Swal.fire('Please enter your Current CTC');
      return;
    } else {
      arrObj['currentCTC'] = target.querySelector('#currentCTC').value;
    }
    if (target.querySelector('#expectedCTC').value.trim() === '') {
      Swal.fire('Please enter your Expected CTC');
      return;
    } else {
      arrObj['expectedCTC'] = target.querySelector('#expectedCTC').value;
    }
    if (target.querySelector('#noticeperiod').value.trim() === '') {
      Swal.fire('Please enter your  Notice Period');
      return;
    } else {
      arrObj['noticeperiod'] = target.querySelector('#noticeperiod').value;
    }
    const linkedInInput = target.querySelector('#linkedIn').value.trim();
    arrObj['linkedIn'] = linkedInInput;

    this.mainService.addcandidate(this.id, arrObj).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Successfully done !!', 'success');

        this.router.navigate(['/admin/all-candidate']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
