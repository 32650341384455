<form (ngSubmit)="formSubmit()" enctype="multipart/form-data">
  <mat-card>
    <mat-card-title>Add Event</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <input matInput placeholder="Title" [(ngModel)]="title" name="title" />
      </mat-form-field>
      <mat-form-field>
        <textarea
          matInput
          placeholder="Description"
          [(ngModel)]="description"
          name="description"
        ></textarea>
      </mat-form-field>
    </mat-card-content>
    <mat-card-content>
      <mat-form-field>
        <input
          matInput
          placeholder="Start Date"
          type="date"
          [(ngModel)]="startDate"
          name="startDate"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Start Time"
          type="time"
          [(ngModel)]="startTime"
          name="startTime"
        />
      </mat-form-field>
    </mat-card-content>
    <mat-card-content>
      <mat-form-field>
        <input
          matInput
          placeholder="End Date"
          type="date"
          [(ngModel)]="endDate"
          name="endDate"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="End Time"
          type="time"
          [(ngModel)]="endTime"
          name="endTime"
        />
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        type="submit"
        style="background-color: rgb(206 50 98); color: white"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="onCancel()"
        style="background-color: rgb(206 50 98); color: white"
      >
        Cancel
      </button>
    </mat-card-actions>
  </mat-card>
</form>
