import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-showuser-task',
  templateUrl: './showuser-task.component.html',
  styleUrls: ['./showuser-task.component.css','/../../../shared/all-style/all-style.component.css'],
})
export class ShowuserTaskComponent implements OnInit {
  userId = localStorage.getItem('id');
  status: string = 'Pending';
  value: any;
  data: any[] = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  employees: any;
  user_id: string;
  startDate: string;
  endDate: string;
  showFilterForm: boolean;
  inputDateFromString: number;
  inputDateToString: number;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialog
  ) {}

  navigateToAddUserProfile() {
    this.router.navigate(['admin/previous-task']);
  }

  // assignTaskToUser() {
  //   this.router.navigate(['admin/assign-task']);
  // }

  ngOnInit(): void {
    this.fetchData();
    this.mainService.AllEmployeeProfileList().subscribe((res:any)=>{
      this.employees=res;
    })
  }

  fetchData() {
    this.mainService.Alltask(this.pageNumber, this.pageSize).subscribe((res: any) => {
      if (res && res.content && Array.isArray(res.content)) {
        this.data = res.content;
        this.totalElements = res.totalElements;
      }
      console.log("atdfsa"+JSON.stringify(this.data))
    });
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  toggleFilterForm(){
    this.showFilterForm =!this.showFilterForm;
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  openPopup(fileUrl) {
    window.open(fileUrl, 'Document Viewer', 'resizable=yes,scrollbars=yes');
    return false;
  }

  openUserModal(userId: any, id: any) {
    this.dialog.open(AddCommentComponent, {
      width: '900px',
      data: { userId, id }
    });
  }
  clearFilters() {
    this.user_id = '';
    this.status = 'PENDING';
    this.startDate = '';
    this.endDate = '';
    this.fetchData();
  }
}
