<div class="container1">
  <div class="performance-card">
    <button mat-mini-fab  (click)="previousData()">⇦Prev</button>
    <button  mat-mini-fab (click)="nextData()" style="margin-left: 10px;">Next⇨</button>
    <table class="performance-table" *ngFor="let data of dataToShow">
        <tr>
          <th>CreatedOn</th>
          <th style="color: #f5c60d; font-size: 16px;;background-color: #ce3262;">
            {{ data.createdOn | date:"dd-MMM-yyyy" }}
          </th>
        </tr>
        <tr >
          <th>Team Work</th>
          <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
            {{ data.teamWork | starRating }}
          </th>
        </tr>
        <tr>
          <th>General Conduct</th>
          <th style="color: #f5c60d;font-size: 20px;background-color: #ce3262;">{{ data.generalConduct | starRating }}</th>
        </tr>

      <tr>
        <th>Job Knowledge</th>
        <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
          {{ data.jobKnowledge | starRating }}
        </th>
      </tr>
      <tr >
        <th>Work Quality</th>
        <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
          {{ data.workQuality | starRating }}
        </th>
      </tr>
      <tr>
        <th>Skills</th>
        <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
          {{ data.skills | starRating }}
        </th>
      </tr>

      <tr>
        <th>Attendance</th>
        <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
          {{ data.attendance | starRating }}
        </th>
      </tr>
      <tr>
        <th >Initiative</th>
        <th style="color: #f5c60d; font-size: 20px;background-color: #ce3262;">
          {{ data.initiative | starRating }}
        </th>
      </tr>

      <tr >
        <th
        >Discipline</th>
        <th style="color: #f5c60d;font-size: 20px;background-color: #ce3262;">{{ data.discipline | starRating }}</th>
      </tr>
      <tr style="width: 160px;">
        <th>Performance</th>
        <th style="background:#ce3262;
        color: #f5c60d;">{{ data.overAll }}</th>
      </tr>
      <tr>
        <th >Total Score</th>
        <th >{{ data.totalScore }}</th>
      </tr>
      <tr >
        <th>Obtained Score</th>
        <th >{{ data.obtainedScore }}</th>
      </tr>
      <tr>
        <th>
          Comment:
        </th>
        <th>
          {{ data.comment }}
        </th>
      </tr>
  </table>
  </div>
</div>
