import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-show-email-popup',
  templateUrl: './show-email-popup.component.html',
  styleUrls: ['./show-email-popup.component.css']
})
export class ShowEmailPopupComponent implements OnInit {
  emailDetails = {
    toList: '',
    subject: '',
    body: '',
    ccList: [] as string[],
    bccList: [] as string[],
    attachmentList: [] as File[],
  };

  newCc: string = '';
  newBcc: string = '';
  showCcInput = false;
  showBccInput = false;
  isSubmitting: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      { name: 'quote', class: 'quote' },
      { name: 'redText', class: 'redText' },
      { name: 'titleText', class: 'titleText', tag: 'h1' },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  user: any;

  constructor(private mainService: MainService, public dialogRef: MatDialogRef<ShowEmailPopupComponent>) { }

  ngOnInit(): void {  
    this.mainService.AllEmployeeProfileList().subscribe((res: any) => {
      this.user = res;
      console.log(JSON.stringify(this.user));
    });
  }
  closeEmailPopup(): void {
    this.dialogRef.close();
  }
  sendEmail() {
    this.isSubmitting = true;
    const payload = {
      toList: [this.emailDetails.toList],
      subject: this.emailDetails.subject,
      body: this.emailDetails.body,
      cc: this.emailDetails.ccList,
      bcc: this.emailDetails.bccList,
      attachmentList: this.emailDetails.attachmentList,
    };

    console.log('Payload:', payload);

    // Upload all files before sending the email
    this.uploadAllFiles().then(() => {
      this.mainService.emailSendToUser(payload).subscribe(response => {
        Swal.fire({
          title: 'Success!',
          text: 'Email sent successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.closeEmailPopup();
            window.location.reload();
          }
        });
      }, error => {
        Swal.fire('Error sending email', error);
      })
    });
  }

  async uploadAllFiles() {
    const folderName = 'emails';
    for (const file of this.emailDetails.attachmentList) {
      await this.uploadFile(file, folderName);
    }
  }

  uploadFile(file: File, folderName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (response: any) => {
          console.log(`File uploaded successfully: ${file.name}`);
          resolve();
        },
        (error) => {
          console.error(`File upload failed: ${file.name}`, error);
          Swal.fire('Error', `File upload failed: ${file.name}`, 'error');
          reject();
        }
      );
    });
  }

  toggleCcInput(): void {
    this.showCcInput = !this.showCcInput;
  }

  toggleBccInput(): void {
    this.showBccInput = !this.showBccInput;
  }

  addCc() {
    if (this.newCc) {
      this.emailDetails.ccList.push(this.newCc);
      this.newCc = '';
    }
  }

  removeCc(index: number) {
    this.emailDetails.ccList.splice(index, 1);
  }

  addBcc() {
    if (this.newBcc) {
      this.emailDetails.bccList.push(this.newBcc);
      this.newBcc = '';
    }
  }

  removeBcc(index: number) {
    this.emailDetails.bccList.splice(index, 1);
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.emailDetails.attachmentList.push(files[i]);
    }
    console.log('Selected files:', this.emailDetails.attachmentList);
  }

  removeAttachment(index: number) {
    this.emailDetails.attachmentList.splice(index, 1);
  }
}
