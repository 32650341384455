import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-update-status-modal',
  templateUrl: './update-status-modal.component.html',
  styleUrls: ['./update-status-modal.component.css', '/../../../shared/all-style/add-candidate-modal.css'],
})
export class UpdateStatusModalComponent implements OnInit {
  role: string = 'MANAGER';
  managerList: any;
  isEnabled: boolean = true;
  userId: any;
  vacancyId: any;
  isActiveVacancy: any;
  hiringAIStatus: any;
  status: any;
  startDate: any;
  designation: any;
  endDate: any;
  hiring: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  selectedManagerId: any;
  selectedStatus: string = 'SELECTED';
  comments: string = '';
  interviewDate: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateStatusModalComponent>,
    private mainService: MainService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { id: number },
    public toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.mainService
      .AllEmployeeProfileListUsingRole(this.role, this.isEnabled)
      .subscribe((res: any) => {
        this.managerList = res;
        console.log(JSON.stringify(res));
      });
    this.fetchData();
  }

  fetchData(): void {
    this.mainService.getAllhiring(this.userId, this.vacancyId, this.isActiveVacancy, this.hiringAIStatus, this.status, this.startDate, this.designation, this.endDate, this.pageNumber, this.pageSize).subscribe((res: any) => {
      this.hiring = res.content;
      this.totalElements = res.totalElements;
      console.log(JSON.stringify(this.hiring));
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  save(): void {
    const payload = {
      id: this.dialogData.id,
      assignToId: this.selectedManagerId,
      progressStatus: this.selectedStatus,
      comments: this.comments,
      interviewDate: new Date(this.interviewDate).getTime() 
    };

    console.log('Payload:', payload);
    this.mainService.updateStatus(this.dialogData.id, payload).subscribe(
      (res: any) => {
        this.toast.success('Hiring Status Successfully updated');
        this.dialogRef.close(payload);
        window.location.reload();
      },
      (error) => {
        this.toast.error('Error updating Hiring Status', error);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
