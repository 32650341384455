import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-employee-task',
  templateUrl: './employee-task.component.html',
  styleUrls: ['./employee-task.component.css']
})
export class EmployeeTaskComponent implements OnInit {
  @ViewChild('myChart') barChartRef!: ElementRef;
  @ViewChild('myPieChart') pieChartRef!: ElementRef;

  constructor(private mainService: MainService) {}

  pendingTasksCount: number = 0;
  completedTasksCount: number = 0;
  totalTasksCount: number = 0;
  id = localStorage.getItem('id');
  assignedTaskCount:number = 0;
  ngOnInit(): void {
    this.loadPieChartData();
  }

  loadPieChartData() {
    this.mainService.tasklist(this.id, true).subscribe((res: any) => {
      this.processTaskData(res.content);
      this.updatePieChart();
    });
  }

  processTaskData(tasks: any[]) {
    this.pendingTasksCount = tasks.filter(task => task.status === 'PENDING').length;
    this.completedTasksCount = tasks.filter(task => task.status === 'DONE').length;
    this.assignedTaskCount = tasks.filter(tasks => tasks.status === 'ASSIGNED').length;
    this.totalTasksCount = tasks.length;
  }

  updatePieChart() {
    const ctx = document.getElementById('myPieChart') as HTMLCanvasElement;

    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Pending Task', 'Completed Task', 'Assigned Task','Total Task'],
        datasets: [
          {
            label: 'Task Data',
            data: [
              this.pendingTasksCount,
              this.completedTasksCount,
              this.assignedTaskCount,
              this.totalTasksCount, 
            ],
            backgroundColor: ['#a0284d', 'darkgreen','#44856f', 'darkblue'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
}
