import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-assign',
  templateUrl: './user-assign.component.html',
  styleUrls: ['./user-assign.component.css']
})
export class UserAssignComponent implements OnInit {

  userId: string;
  data: any;
  constructor(
    private _snack: MatSnackBar,
    private mainService: MainService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router:Router
  ) {}

  id = localStorage.getItem('id');
  phone = localStorage.getItem('phone');

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
  }

  assigment(event) {
    let target = event.target;
    var arrObj = {};

    if (target.querySelector('#task').value != null) {
      arrObj["task"] = target.querySelector('#task').value;
    }
    if (target.querySelector('#description').value != null) {
      arrObj["description"] = target.querySelector('#description').value;
    } else {
      arrObj["description"] = null;
    }
    if (target.querySelector('#deadline').value != null) {
      arrObj["deadline"] = target.querySelector('#deadline').value;
    }
    this.mainService.savAssignment(this.id, arrObj).subscribe(res => {
      this.data = res;
      Swal.fire("Successfully updated.")

        this.router.navigate(['/user-assignment']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}


