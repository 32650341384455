<mat-dialog-content>
    <div (click)="closeModal()"></div>
    <div class="modal-content">
      <span class="close" (click)="closeModal()" style="color: white; cursor: pointer; float: right;">&times;</span>
      <h2 style="color: white;">{{ storyIndex !== null ? 'Update Story' : 'Add Story' }}</h2>
      <form (ngSubmit)="formSubmit()" #storyForm="ngForm">
        <label for="heading">Story</label>
        <textarea id="heading" name="heading" [(ngModel)]="story" required></textarea>
        <button type="submit" [disabled]="!storyForm.form.valid">Save</button>
      </form>
    </div>
  </mat-dialog-content>
  