import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
interface Status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-leave-approve',
  templateUrl: './leave-approve.component.html',
  styleUrls: ['./leave-approve.component.css']
})
export class LeaveApproveComponent implements OnInit {
  private routeSub: Subscription;
  userId: string;
  data: any;
  leaveId: any;
  status: string;
  selectedStatus: string;
  statuses: Status[] = [
    { value: 'APPROVED', viewValue: 'Approved' },
    { value: 'REJECTED', viewValue: 'Rejected' },
    { value: 'PENDING', viewValue: 'Pending' },
  ];
  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  comment: any;
  ngOnInit(): void {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.userId = params['id'];
      this.comment = params['comment'];
      this.leaveId = params['leave_id'];
      console.log('UserId : ' + this.userId + ' LeaveId : ' + this.leaveId); 
    });
  }

  formSubmit(event) {
    let target = event.target;
    var arrObj = {};

    arrObj['id'] = this.leaveId;
    if (target.querySelector('#id').value != null) {
      arrObj['id'] = target.querySelector('#id').value;
    }
    if (target.querySelector('#comment').value != null) {
      arrObj['comment'] = target.querySelector('#comment').value;
    }
    if (this.selectedStatus != null) {
      arrObj['managerStatus'] = this.selectedStatus;
    }
    else {
      Swal.fire('Error', 'Manager status cannot be null', 'error');
      return;
    }
    this.mainService.managerleaveapproval(this.userId, arrObj).subscribe(
      (data: HttpResponse<any>) => {
        Swal.fire('Successfully done !!', 'success');
        this.router.navigate(['/manager-dashboard/approve-leave']);
      },
      (error) => {
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
