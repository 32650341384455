import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { TotalSalaryComponent } from 'src/app/pages/admin/total-salary/total-salary.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-show-employee-salary',
  templateUrl:'./show-employee-salary.component.html',
  styleUrls: ['./show-employee-salary.component.css'],
})
export class ShowEmployeeSalaryComponent implements OnInit {
  totalElements: number = 0;
  pageNumber: number = 0;
  pageSize: number = 10;
  dialogRef: any;
  userId:any;
  isEnabled:any;
  salary:any
  redirectToSalaryCycle(): void {
    this.router.navigate(['/admin/salary-cycle']);
}
  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  id = localStorage.getItem('id');
  phone = localStorage.getItem('phone');
  data: any;
  ngOnInit(): void {
   this.fetchSalary();
  }

  fetchSalary(){
    this.mainService.showAllSalary( this.userId,this.isEnabled, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
     this.salary= this.data.content;
      this.totalElements = this.data.totalElements;
    });
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchSalary();
  }
  ondelete(id) {
    if (confirm('Are you sure want to delete ')) {
      this.mainService.deletesalary(id).subscribe((res) => {
        console.log('Successfully deleted');
        location.reload();
        this.router.navigate(['admin/show-employee-salary']);
      });
    }
  }

  openDialog(userId: any) {
    this.dialogRef = this.dialog.open(TotalSalaryComponent, {
      data: { userId },
    });
  }
  downloadpdf() {
    this.mainService.downloadsalaryadminpdf().subscribe((blobData: Blob) => {
      const blob = new Blob([blobData], { type: 'application/pdf' });

      saveAs(blob, 'salary.pdf');

      Swal.fire({
        title: 'Downloaded',
        text: 'The PDF has been downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      });
      (error) => {
        console.error('Pdf or Excel Not Downloaded: ', error);
      };
    });
  }
  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
}
