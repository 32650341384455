import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent implements OnInit {
  events: any[];

  constructor(private mainService: MainService, private dialogRef: MatDialogRef<NewComponent>) { }

  ngOnInit(): void {
    this.loadEvents();
  }

  loadEvents(): void {
    this.mainService.newevent().subscribe(
      (res: any[]) => {
        this.events = res;
      },
      (error) => {
        console.error('Error fetching announcement:', error);
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
