import { Component, OnInit } from '@angular/core';
// import { ProjectAssignModalComponent } from '../project-assign-modal/project-assign-modal.component';
import { MatDialog } from '@angular/material/dialog';
// import { AddProjectComponent } from '../add-project/add-project.component';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
// import { UpdateAssignmentComponent } from '../update-assignment/update-assignment.component';

interface User {
  assignToId: number;
  assignToName: string;
  assignToPhone: string;
  assignToEmail: string;
  isUserPresent: boolean;
  assignToProfile: string;
}

interface Assignment {
  id: number;
  createdDate: number;
  subject: string;
  comment: string | null;
  deadLine: number;
  headings: string | null;
  description: string;
  updatedTime: number;
  files: string[];
  status: string;
  Users: User[];
  userId: number;
  userName: string;
  userEmail: string;
  userPhone: string;
  userProfile: string;
}

interface AssignmentResponse {
  totalSubtasksCount: number;
  pendingSubtasksCount: number;
  doneAssignments: Assignment[];
  allAssignments: Assignment[];
  pendingAssignments: Assignment[];
  doneAssignmentCount: number;
  pendingAssignmentCount: number;
  pendingSubTasks: any[];
  doneSubTasks: any[];
  recentSubTasks: any[];
  
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
 
    currentYear: number;
    currentMonth: number;
    currentDay: Date;
    weekDayNames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    visibleWeekDays: Date[] = [];
    selectedDay: Date | null = null;
    monthNames: string[] = ["January", "February", "March", "April", "May", "June", 
                            "July", "August", "September", "October", "November", "December"];
    currentSlide = 0;
    assignment:any;
    startDate: string = '';
    endDate: string = '';
    totalElements: number = 0;
    pageSize: number = 10;
    pageNumber: number = 0;
    userId=localStorage.getItem('id')
    status:any;
    assignmentRes:any;
    recentSubTasks: any[] = [];
    constructor(private dialog:MatDialog,private mainService:MainService,private router :Router) { }
  
    ngOnInit(): void {
      this.currentDay = new Date();
      this.currentYear = this.currentDay.getFullYear();
      this.currentMonth = this.currentDay.getMonth();
      this.generateWeekDays(this.currentDay);
      // this.fetchData();
      this.consolidated();
    }
    consolidated() {
      this.mainService.consolidatedAssignment().subscribe((res: AssignmentResponse[]) => {
        if (res.length > 0) {
          const data = res[0];
          this.assignmentRes = data;
          this.recentSubTasks = data.recentSubTasks.slice(0, 5);
          this.assignment = data.allAssignments; // or data.pendingAssignments, depending on the requirement
    
          console.log("Full Response:", JSON.stringify(res));
          console.log("Recent Sub Tasks:", this.recentSubTasks);
          console.log("All Assignments:", this.assignment);
        } else {
          console.warn("Empty response received.");
        }
      }, error => {
        console.error("Error fetching consolidated data:", error);
      });
    }
    
  //   fetchData() {
  //     this.mainService.getAllAssigmentbyUser(this.userId, this.status, this.startDate, this.endDate, this.pageNumber, this.pageSize).subscribe((res: any) => {
  //       this.assignment = res.content; // Assuming the API returns an object with assignments array
  //       this.totalElements = res.totalElements; 
  //       console.log(JSON.stringify(res));
  //     });
  //   }
  
    generateWeekDays(day: Date): void {
      const startOfWeek = new Date(day);
      startOfWeek.setDate(day.getDate() - day.getDay()); 
      this.visibleWeekDays = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        return date;
      });
      this.currentMonth = this.visibleWeekDays[0].getMonth();
      this.currentYear = this.visibleWeekDays[0].getFullYear();
    }
  
    prevWeek(): void {
      const firstDayOfWeek = this.visibleWeekDays[0];
      const prevWeekDate = new Date(firstDayOfWeek);
      prevWeekDate.setDate(firstDayOfWeek.getDate() - 7);
      this.generateWeekDays(prevWeekDate);
    }
  
    nextWeek(): void {
      const lastDayOfWeek = this.visibleWeekDays[this.visibleWeekDays.length - 1];
      const nextWeekDate = new Date(lastDayOfWeek);
      nextWeekDate.setDate(lastDayOfWeek.getDate() + 1);
      this.generateWeekDays(nextWeekDate);
    }
  
    selectDay(day: Date): void {
      this.selectedDay = day;
    }
  
    isToday(day: Date): boolean {
      const today = new Date();
      return day.getDate() === today.getDate() &&
             day.getMonth() === today.getMonth() &&
             day.getFullYear() === today.getFullYear();
    }
    prevSlide() {
      if (this.currentSlide === 0) {
        if (this.pageNumber > 0) {
          this.pageNumber--;
          this.consolidated();
        }
      } else {
        this.currentSlide--;
        this.updateCarousel();
      }
    }
  
    nextSlide() {
      const totalSlides = this.assignment.length;
      if (this.currentSlide === totalSlides - 1) {
        if (this.pageSize * (this.pageNumber + 1) < this.totalElements) {
          this.pageNumber++;
          this.consolidated();
        }
      } else {
        this.currentSlide++;
        this.updateCarousel();
      }
    }
    updateCarousel() {
      const carouselInner = document.querySelector('.carousel-inner') as HTMLElement;
      carouselInner.style.transform = `translateX(-${this.currentSlide * 100}%)`;
    }
    // openDialog(): void {
    //   this.dialog.open(ProjectAssignModalComponent, {
    //     width: '800px', 
    //   });
    // }
    // openDialogproject():void{
    //   this.dialog.open(AddProjectComponent,{ 
    //     width:'600px'
    //   })
    // }
    // updateProject(projectId):void{
    //   this.dialog.open(AddProjectComponent,{
    //     width:'500px',
    //     data:{
    //       projectId:projectId
    //     }
  
    //   })
    // }
    calculateDaysLeft(deadline: number): number {
      const now = new Date().getTime();
      const timeDiff = deadline - now;
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
  //   logAssignmentDetails(assignmentItem: any) {
  //     console.log('Assignment Details:', assignmentItem);
  //     console.log('Navigating to Project ID:', assignmentItem.id);
  // }
  }
  