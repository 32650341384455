
<div class="container2">
    <div class="container">
    <h2 style="color: white;">Assigment ><span>Task & Projects</span></h2><br>
</div>
<div class="container" >
  <div class="card">
    <div class="card-header">
      <h3>Task Growth</h3>
      
    </div>
    <div class="card-content" >
        <img src="../../../../assets/arrow (2).png" alt="Arrow Up">
      {{assignmentRes.totalSubtasksCount}}
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h3>Pending Task</h3>
     
    </div>
    <div class="card-content">
      {{assignmentRes.pendingSubtasksCount}}
      <img src="../../../../assets/second-card.png" alt="Refresh">
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h3>Completed projects</h3>
     
    </div>
    <div class="card-content">
      {{assignmentRes.doneAssignmentCount}}
      <img src="../../../../assets/clock.png" alt="Resize Vertical">
    </div>
  </div>
  <div class="calendar">
    <div class="calendar-header">
      <button (click)="prevWeek()">&lt;</button>
      <h3>{{ monthNames[currentMonth] }} {{ currentYear }}</h3>
      <button (click)="nextWeek()">&gt;</button>
    </div>
    <div class="calendar-body">
      <div class="calendar-day-name" *ngFor="let dayName of weekDayNames">
        {{ dayName }}
      </div>
      <div class="calendar-day" 
           *ngFor="let day of visibleWeekDays" 
           [class.today]="isToday(day)" 
           (click)="selectDay(day)">
        {{ day.getDate() }}
      </div>
    </div>
  </div>
  
  
</div>
<div class="task-list-header container" style="padding: 8px; ">
    <h3>Current Task</h3>
    <!-- <h3>Assigned Task</h3> -->
  </div>
  
  <div class="container">
    <div class="task-list" style="width: 100%;">
      <div class="task-list-item" style="display: flow; color: white; font-size: 12px;">
        <h2 style="color:white">
          <img src="../../../../assets/Board Icon.png" alt="task"/>New Tasks
        </h2>
      </div>
      
      <div class="task-list-item" *ngFor="let task of recentSubTasks">
        <!-- Task Name -->
        <span>{{ task.name }}
          <div *ngIf="task.fileUrl">
            <a href="{{ task.fileUrl }}" target="_blank" style="color: blue; text-decoration: underline;">
              <img src="../../../../assets/attach-file.png" alt="Assignment files">
            </a>
          </div>
        </span>
        
        <!-- Deadline -->
        <span style="float: right;">{{ task.deadline | date:'dd MMMM yyyy' }}</span>
        
        <!-- Creation Date -->
        <!-- <div>
          <span>Created On: {{ task.createdate | date:'dd MMMM yyyy' }}</span>
        </div> -->
        
        <!-- File URL (only show if it exists) -->
        
      </div>
      
      <!-- <button class="add-task-button" (click)="openDialog()">+ Task</button> -->
    </div>
  
    <!-- <div class="task-list">
      <div class="task-list-header">
        <h3> Tasks Stats</h3>
      </div> 
      <div class="no-task">
        <h2><span style="color: red">!</span> No Task Assigned</h2>
      </div>
    </div> -->
  </div>
  
<div class="container" style="    display: flex;
padding: 6px;
gap: 10px;
margin-top: 20px;
color: white;">
<h1>Projects</h1>
<button class="add-task-button" (click)="openDialogproject()">
  + Add Project
</button>
</div>
<div class="container">
    <div class="task-list" >
      <div class="projects-container">
        <div class="carousel">
          <div class="carousel-inner">
            <div *ngFor="let assignmentItem of assignment; let i = index" class="carousel-item" [ngClass]="{'active': i === currentSlide}">
              <button  class="more-vert-button" (click)="updateProject(assignmentItem.id)"><mat-icon>more_vert</mat-icon></button>
              <div class="project" 
              [routerLink]="['/manager-dashboard/details-project/']" 
              [queryParams]="{ id: assignmentItem.id }">
        
                <div class="project-image"> 
                  <img [src]="assignmentItem.banner" alt="Project Image">
                </div>
                <div class="project-details">
                  <h3>{{assignmentItem.subject}}</h3>
                  <p>{{assignmentItem.headings}}</p>
                  <div class="progress-bar">
                    <div class="progress" style="width: 75%"></div>
                  </div>
                  <div class="project-info">
                    <div class="project-time">
                      {{calculateDaysLeft(assignmentItem.deadLine)}} Days Left
                    </div>
                    <div class="project-members">
                      <img *ngFor="let user of assignmentItem.Users" [src]="user.assignToProfile" alt="{{user.assignToName}}" class="member-image">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" (click)="prevSlide()">&#10094;</button>
          <button class="carousel-control-next" (click)="nextSlide()">&#10095;</button>
        </div>
      </div>
    </div>
  </div>
  
  
</div>
  
 
  
