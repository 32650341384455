<!-- <h4> Update Task</h4>
<mat-card style="margin-top: 90px;">
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (ngSubmit)="onfromSubmit($event)">
            <mat-form-field class="w100" appearance="outline" style="margin-top: 16px">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="user.status" id="status" name="status">
                <mat-option value="PENDING">PENDING</mat-option>
                <mat-option value="DONE">DONE</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card> -->
<mat-dialog-content >
  <div class="modal-overlay" (click)="closeModal()"></div>
  <div class="modal-content" style="width: 34%;">
      <span class="close" (click)="closeModal()">&times;</span>
      <form (ngSubmit)="onformSubmit($event)">
        <mat-form-field class="w100" appearance="outline" style="margin-top: 16px">
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="user.status" id="status" name="status">
            <mat-option value="PENDING">PENDING</mat-option>
            <mat-option value="DONE">DONE</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w100" appearance="outline" style="margin-top: 34px; width: 100%;">
          <mat-label >Task</mat-label>
          <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="  width: 100%;" rows="14"></textarea>
        </mat-form-field>
        <div class="container text-center mt15">
          <button mat-raised-button color="accent">Add</button>
        </div>
      </form>
      
  </div>
</mat-dialog-content>

