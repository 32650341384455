import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
interface InvitationRequest {
  email: any;
  organizerEmail: any;
  startTime: any;
  endTime: any;
  location: any;
  description: any;
  summary: any;
}

@Component({
  selector: 'app-calendar-invite',
  templateUrl: './calendar-invite.component.html',
  styleUrls: ['./calendar-invite.component.css'],
})
export class CalendarInviteComponent implements OnInit {
  id = localStorage.getItem('id');
  data: any;
  invitationRequest: InvitationRequest = {
    email: '',
    organizerEmail: '',
    startTime: '',
    endTime: '',
    location: '',
    description: '',
    summary: '',
  };

  attachmentFile: File | null = null;

  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe((res) => {
      this.data = res;
      console.log('++++++++  ' + JSON.stringify(this.data));
      console.log('User ID:', this.id);
    });

    this.route.queryParams.subscribe((params) => {
      this.invitationRequest.email = params['email'];
      this.invitationRequest.organizerEmail = params['organizerEmail'];
      this.invitationRequest.startTime = params['startTime'];
      this.invitationRequest.endTime = params['endTime'];
      this.invitationRequest.location = params['location'];
      this.invitationRequest.description = params['description'];
      this.invitationRequest.summary = params['summary'];
    });
  }

  onAttachmentFileChange(event: any) {
    this.attachmentFile = event.target.files[0];
  }

  getUserIdByName(name: string) {
    const employee = this.data.find((employee) => employee.firstName === name);
    if (employee) {
      return employee.email;
    }
    return null;
  }

  formSubmit(event) {
    event.preventDefault();
    const target = event.target;

    const invitationData = {
      email: target.querySelector('#email').value,
      organizerEmail: target.querySelector('#organizerEmail').value,
      startTime: target.querySelector('#startTime').value,
      endTime: target.querySelector('#endTime').value,
      location: target.querySelector('#location').value,
      description: target.querySelector('#description').value,
      summary: target.querySelector('#summary').value,
    };

    this.mainService.calenderInvite(this.id, invitationData).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Successfully done!', 'success');

        this.router.navigate(['/admin/show-meetings']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
}
