<div class="bootstrap-wrapper" style="margin-right:15px">
  <div [ngStyle]="{ 'width': hideSidebar ? '100%' : '120%' }">
    <div class="row">
      <div class="col-md-2 scroll slidebar" 
      [@sidebarAnimation]="hideSidebar.toString()" 
      style="margin-top: 54px;"
      *ngIf="hideSidebar">
      <app-sidebar></app-sidebar>
    </div>

      <div class="col-md-10 scroll" style="background: #161515;transition: transform 0.3s ease;margin-top: 64px;">
        <router-outlet>
          <!-- <a [routerLink]="['/admin']"> <button mat-mini-fab>  <mat-icon>keyboard_arrow_left</mat-icon> </button> </a>
          <a class="theme-btn" onclick="history.go(-1)"><button mat-mini-fab><mat-icon>keyboard_arrow_right</mat-icon> </button></a> -->
        </router-outlet>
      </div>
    </div>
  </div>
</div>

