<div class="container2">
  <table class="user-table" >
    <thead>
      <tr>
        <th style="width: 97px;background: black;"><mat-icon>people</mat-icon>User</th>
        <th>User Name</th>
        <th>Online/Offline</th>
        <th>Joining Date</th>
        <th>Performance</th>
        <th>Activity Logs</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of data" >
        <td> <img class="myImage" style="height: 69px; width: 69px; border-radius: 50%;" [src]="user.profile" alt="Profile Picture" *ngIf="user && user.profile"/>
        </td>
        <td> {{user.Name }} <br><span style="font-size: 12px;">Birthday {{ isValidDate(user.dob) ? (user.dob | date: 'dd-MMM-yyyy') : 'Invalid Date' }} </span> </td>
        <td><span [ngClass]="{'active-dot': user.active, 'inactive-dot': !user.active}"    [ngStyle]="{'color': user.active ? 'green' : 'red'}" ></span> {{ user.active ? 'Online' : 'Offline' }} </td>
        <td>{{ user.date | date: 'dd-MMM-yyyy ' }}</td>
        <td>{{user.Performance}} </td>
        <td style="font-size: 12px;">{{ formatDateWithAbbreviatedMonth(user.lastLogin) }}</td>
    </tr>
    </tbody>
  </table>
</div>
