import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login.service';
import { SidebarService } from '../../../services/sidebar.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar-user',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  categories;
  hideSidebar: boolean = true;
  colMdClass: string = 'col-md-10';
  userRole: any;
  constructor( private _snack: MatSnackBar,private login: LoginService,private sidebarService: SidebarService,private router:Router) {}

  ngOnInit(): void {
    this.userRole = this.login.getUserRole();
    this.sidebarService.isSidebarHidden().subscribe((hidden) => {
      this.hideSidebar = hidden;
    });

  }
  public getHomeRoute(): string {
    return this.userRole === 'MANAGER' ? '/manager-dashboard' : '/user-dashboard';
  }
  public logout() {
    Swal.fire({
      title: 'Confirm Sign Off',
      text: 'Are you sure you want to sign off?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Sign off',
      confirmButtonColor: '#ff4081',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.login.logout();
  
        // Navigate to the root URL
        this.router.navigate(['/']).then(() => {
          // Reload the page
          window.location.reload();
        });
      }
    }
    )
  }
}


  