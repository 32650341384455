<h4> User Performance</h4>
<div class="magic" style="margin-left: 350px">
  <div
    *ngFor="let category of categories"
    class="category"
    style="font-family: Arial, sans-serif;margin-bottom: 10px;text-align: center;border: 1px solid #ccc; background-color: #f9f9f9;width: 50%;width:50%;"
  >
    {{ category }}:
    <div class="stars">
      <mat-icon
        *ngFor="let icon of maxRatingArr; let index = index"
        (mouseenter)="HandelMouseEnter(category, index)"
        (mouseleave)="HandelMouseLeave(category)"
        (click)="Rating(category, index)"
        [ngClass]="{ checked: isCategoryRatingHigher(category, index) }"
        >star</mat-icon
      > 
    </div>
  </div>
</div>
<div class="specificAchievement" style="margin-left: 350px">
  <textarea [(ngModel)]="specificAchievement" placeholder=" Specific Achievement:"></textarea>
</div>
<div class="comment" style="margin-left: 350px">
  <textarea [(ngModel)]="comment" placeholder=" Comment:"></textarea>
</div>

<button
  class="button1"
  style="margin-left: 529px; width: 137px; margin-top: 10px"
  (click)="submitReview()"
>
  Submit
</button>
