<h4>Users Review</h4>
<div class="allContainer">
  <div class="scrollable-content">
<div class="container">
  
  <div class="timeline-container col-md-6">
    <div class="timeline-header">
      <div  class="heading">Career Timeline</div>
      <div class="year-selector">
        <!-- <select >
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select> -->
      </div>
    </div>
    <div class="timeline-content" #timelineContent>

 
      <div class="timeline-content-1" #timelineContent>
        <div *ngFor="let timelinec of timeline" class="timeline-item">
          <p class="timeline-date">{{ timelinec.date | date: 'd MMM' }}</p>
          <div class="timeline-marker"></div>
          
          <h4 class="timeline-title">{{ timelinec.achievement }}</h4>
          <!-- <p class="timeline-description">{{ timelinec.description }}</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="task-insights-container col-md-5" >
    <p  class="heading">Task Insights</p>
    <div class="content2">
    <div class="task-insights-content">
      <div class="task-insight" style="background-color: #FFBD59;" >
        <div class="task-icon" >
          <img src="../../../assets/square (1).png" alt="icon">
        </div>
        <div class="task-details">
          <p class="task-title">Assigned</p>
          <p class="task-count">{{data.assignedTasks}}</p>
        </div>
      </div>
   

   
      <div class="task-insight"  style="background-color: #6D9BFF;">
        <div class="task-icon" >
          <img src="../../../assets/square (3).png" alt="icon">
        </div>
        <div class="task-details">
          <p class="task-title">Done</p>
          <p class="task-count">{{data.completedTasks}}</p>
        </div>
      </div>
    
      <div class="task-insight"  style="background-color: #F67E71;">
        <div class="task-icon">
          <img src="../../../assets/square (2).png" alt="icon" style="    padding: 6px">
        </div>
        <div class="task-details">
          <p class="task-title">Pending</p>
          <p class="task-count">{{data.pendingTasks}}</p>
        </div>
      </div>
     
    </div>
    <div class="completion-rate">
      <p class="rate-title">On-time Completion Rate <span class="rate-value">{{data.onTimeTasks}} %</span></p>
      
    </div>
  </div>
   
  </div>
</div>

  
<div class="box1" style="width: 90%;border: 1px solid #222222;border-radius: 14px;margin-left: 61px;">
  <div class="heading">Performance Stats</div>
  <div class="container-fulid" >
    <div class="row">
      <div class="col-md-5">
        <div class="chart-container" style="height: 257px; width: 257px; margin-left: 108px;">
          <div class="progress-circle">
            <svg viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" fill="none" stroke="#BDBDBD" stroke-width="10"></circle>
              <circle cx="50" cy="50" r="45" fill="none" stroke-width="10" 
                [attr.stroke-dasharray]="circleDasharray" 
                [attr.stroke-dashoffset]="circleDashoffset"
                [attr.stroke]="strokeColor"></circle>
            </svg>
            <span class="progress-text" style="color: white;"><span  style="color:white;font-size: 16px;font-weight: bold;">Obtained Score</span><br>{{ data.overAllPerformace | number:'1.2-2' }}%</span>

          </div>
        </div>  
    </div>
    <div class="col-md-7" style="display: flex; flex-direction: column;">
      <div style="display: flex; flex: 1;">
        <div class="chart-container" style="flex: 4;">
          <div class="data-list">
            <div class="data-item">
              <div class="data-label">User Reviews</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': userReviews + '%'}"></div>
              </div>
              <div class="data-value">{{ userReviews }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Team work</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': teamWork + '%'}"></div>
              </div>
              <div class="data-value">{{ teamWork }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">General Conduct</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': generalConduct + '%'}"></div>
              </div>
              <div class="data-value">{{ generalConduct }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Job Knowledge</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': jobKnowledge + '%'}"></div>
              </div>
              <div class="data-value">{{ jobKnowledge }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Work Quality</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': workQuality + '%'}"></div>
              </div>
              <div class="data-value">{{ workQuality }}%</div>
            </div>
          </div>
        </div>
        <div class="chart-container" style="flex: 4;">
          <div class="data-list">
            <div class="data-item">
              <div class="data-label">Skills</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': skills + '%'}"></div>
              </div>
              <div class="data-value">{{ skills }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Assistance</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': assistance + '%'}"></div>
              </div>
              <div class="data-value">{{ assistance }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Attendance</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': attendance + '%'}"></div>
              </div>
              <div class="data-value">{{ attendance }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Work Delivery</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': workDelivery + '%'}"></div>
              </div>
              <div class="data-value">{{ workDelivery }}%</div>
            </div>
            <div class="data-item">
              <div class="data-label">Performance</div>
              <div class="data-bar">
                <div class="data-bar-fill" [ngStyle]="{'width': performance + '%'}"></div>
              </div>
              <div class="data-value">{{ performance }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ratings">
        <p class="rate-title">{{ performanceMessage }}</p> 
      </div>
    </div>
    
    </div>
  </div>

</div>
<app-review-first [userId]="userId"></app-review-first>
<app-review-second [userId]="userId"></app-review-second>
<app-review-third [userId]="userId"></app-review-third>

</div>

</div>
