import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-assignment',
  templateUrl: './single-assignment.component.html',
  styleUrls: ['./single-assignment.component.css']
})
export class SingleAssignmentComponent implements OnInit {

  id = localStorage.getItem('id');
  status: string = 'Pending';
  value: any;
  data: any;
  userId : any;

  constructor( private http: HttpClient, private mainService : MainService,private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    console.log("json Id "+this.userId)

    this.mainService.getallAssignmentByUser(this.userId).subscribe(
      (res: any )=>{
        this.data = res;

        JSON.stringify(this.data)
  }
  );
}

  formatDateTime(dateTime: number[]): string {
    const [year, month, day, hours, minutes, seconds, milliseconds] = dateTime;
    const date = new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
    const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const formattedTime = `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}:${this.padZero(date.getSeconds())}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }


  padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }






 }

