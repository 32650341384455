import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css','/../../shared/all-style/profile-style.component.css'],
})
export class ProfileComponent implements OnInit {
  user: any = null;
  isSubmitting = false;
  @ViewChild('fileInput') fileInput: any;
  selectedFile: File | null = null;
  constructor(private login: LoginService, private mainService: MainService,private snackBar:MatSnackBar) {}

  ngOnInit(): void
  {
    this.user = this.login.getUser();

  }
  onEditClick() {
    this.fileInput.nativeElement.click();
  }

  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  //   console.log("Selected file: ", this.selectedFile);
  
  //   if (this.selectedFile) {
  //     const data = new FormData();
  //     data.append('file', this.selectedFile as Blob);
  //     // data.append('file', this.selectedFile);
  //     // data.append('upload_preset', 'profile');
  
  //     this.isSubmitting = true;
  //     this.mainService.uploadImage(data).subscribe(
  //       response => {
  //         console.log("Upload response: ", response);
  //         const imageUrl = response.secure_url;
  //         console.log("Image URL: ", imageUrl); // Log image URL
  //         this.updateUserProfileImage(imageUrl);
  //       },
  //       error => {
  //         console.error("Upload error: ", error);
  //         this.isSubmitting = false;
  //         this.snackBar.open('Failed to upload image', 'Close', { duration: 3000 });
  //       }
  //     );
  //   }
  // }
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    console.log("Selected file: ", this.selectedFile);
    const folderName = 'profilePhotos';
    const data = new FormData();
    data.append('file', this.selectedFile as Blob);
  
    this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
      (response: any) => {
        const filePath = response.message;
        this.updateUserProfileImage(filePath); 
      },
      (error) => {
        console.error('File upload failed:', error);
        Swal.fire('Error', 'File upload failed.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  updateUserProfileImage(filePath: string) {
    console.log("Updating user profile image with URL: ", filePath); 
    this.mainService.updateProfileImage(this.user.id, filePath).subscribe(
      response => {
        console.log("API response:", response);
        Swal.fire('Success', 'Profile image updated successfully', 'success');
        this.user.profileImage = filePath;
        this.isSubmitting = false;
      },
      error => {
        console.error("Update profile image error:", error); 
        Swal.fire('Error', 'Could not update profile image', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  // onDeleteImage(imageUrl: string) {
  //   const publicId = this.getPublicIdFromUrl(imageUrl);
  //   console.log("publicId: " + publicId);
  //   console.log("hello" + imageUrl);
  
  //   if (publicId) {
  //     this.mainService.deleteImage(publicId).subscribe(
  //       response => {
  //         console.log("Deleted image:", response);
  //         Swal.fire('Success', 'Image deleted successfully', 'success');
  //         // After successful deletion from Cloudinary, update the profile image URL in the database to an empty string
  //         this.updateUserProfileImage('');
  //       },
  //       error => {
  //         console.error("Delete image error:", error);
  //         Swal.fire('Error', 'Could not delete image', 'error');
  //       }
  //     );
  //   }
  // }
  
  // getPublicIdFromUrl(imageUrl: string): string | null {
  //   if (!imageUrl) {
  //     return null;
  //   }
  //   const regex = /\/v\d+\/(.+?)\.\w+$/;
  //   const match = imageUrl.match(regex);
  //   if (match && match[1]) {
  //     console.log("Extracted publicId: ", match[1]);
  //     return match[1];
  //   } else {
  //     console.log("No publicId found in URL: ", imageUrl);
  //     return null;
  //   }
  // }
}

