import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  email: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  constructor(private router: Router, private mainService: MainService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'] || '';
    });
  }

  resetPassword() {
    if (this.newPassword !== this.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please make sure the passwords match.',
      });
      return;
    }
    const user = {
      email: this.email,
      newPassword: this.newPassword
    };

    this.mainService.changePassword(this.email, this.newPassword).subscribe((res: any) => {
        Swal.fire({ icon: 'success',title: 'Password Changed', text: 'Your password has been successfully changed.',
        }).then(() => {
          this.router.navigate(['/login']);
        });
      },
      (error) => {
        console.error('Error resetting password:', error);
        Swal.fire({ icon: 'error', title: 'Error Resetting Password', text: 'An error occurred while resetting your password. Please try again later.',
        });
      }
    );
  }
}
