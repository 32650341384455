import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
// import { ToastrService } from 'ngx-toastr';
interface Data {
  id: number;
  createdDate: number;
  updatedDate: number;
  designation: string;
  location:string;
  experience:string;
  keywords: string[];
  designationkeywords: string[];
  budget: string;
  isActive: boolean;

}
@Component({
  selector: 'app-candidate-modal',
  templateUrl: './candidate-modal.component.html',
  styleUrls: ['./candidate-modal.component.css','/../../../shared/all-style/add-candidate-modal.css']
})
export class CandidateModalComponent implements OnInit {
  newKeyword: string = '';
  candidate:any;
  data: Data = {
    id: 0,
    createdDate: 0,
    updatedDate: 0,
    designation: '',
    experience:'',
    location:'',
    keywords: [],
    designationkeywords: [],
    budget: '',
    isActive: true
  };
  designations = [
    { value: 'General Manager', label: 'General Manager' },
    { value: 'Administrative Officer', label: 'Administrative Officer' },
    { value: 'Chief Executive Officer (CEO)', label: 'Chief Executive Officer (CEO)' },
    { value: 'Strategic Lead', label: 'Strategic Lead' },
    { value: 'Chief Operating Officer (COO)', label: 'Chief Operating Officer (COO)' },
    { value: 'Chief Financial Officer (CFO)', label: 'Chief Financial Officer (CFO)' },
    { value: 'HR Executive', label: 'HR Executive' },
    { value: 'HR Manager', label: 'HR Manager' },
    { value: 'Recruiter', label: 'Recruiter' },
    { value: 'Accountant', label: 'Accountant' },
    { value: 'Finance Manager', label: 'Finance Manager' },
    { value: 'Account Executive', label: 'Account Executive' },
    { value: 'Sales Executive', label: 'Sales Executive' },
    { value: 'Sales Manager', label: 'Sales Manager' },
    { value: 'Senior Sales Manager', label: 'Senior Sales Manager' },
    { value: 'P&L Manager', label: 'P&L Manager' },
    { value: 'Customer Relationship Manager (CRM)', label: 'Customer Relationship Manager (CRM)' },
    { value: 'Real Estate Aspirant', label: 'Real Estate Aspirant' },
    { value: 'Customer Care Representative', label: 'Customer Care Representative' },
    { value: 'Portfolio Manager', label: 'Portfolio Manager' },
    { value: 'Sales Head', label: 'Sales Head' },
    { value: 'Digital Marketing Manager', label: 'Digital Marketing Manager' },
    { value: 'SEO Executive', label: 'SEO Executive' },
    { value: 'Search Engine Optimization (SEO)', label: 'Search Engine Optimization (SEO)' },
    { value: 'Intern', label: 'Intern' },
    { value: 'Campaign Manager', label: 'Campaign Manager' },
    { value: 'Content Writer', label: 'Content Writer' },
    { value: 'Social Media Analyst', label: 'Social Media Analyst' },
    { value: 'Marketing Specialist', label: 'Marketing Specialist' },
    { value: 'Marketing Analyst', label: 'Marketing Analyst' },
    { value: 'Public Relation Manager', label: 'Public Relation Manager' },
    { value: 'Brand Manager', label: 'Brand Manager' },
    { value: 'Graphic Designer', label: 'Graphic Designer' },
    { value: 'Software Development Engineer', label: 'Software Development Engineer' },
    { value: 'Senior Web Developer', label: 'Senior Web Developer' },
    { value: 'Junior Web Developer', label: 'Junior Web Developer' },
    { value: 'Full Stack Developer', label: 'Full Stack Developer' },
    { value: 'Senior Web Designer', label: 'Senior Web Designer' },
    { value: 'Junior Web Designer', label: 'Junior Web Designer' },
    { value: 'Backend Developer', label: 'Backend Developer' },
    { value: 'Frontend Developer', label: 'Frontend Developer' },
    { value: 'Senior App Developer/ Android Developer', label: 'Senior App Developer/ Android Developer' },
    { value: 'Junior App Developer/ Android Developer', label: 'Junior App Developer/ Android Developer' }
  ];
  
  constructor(public dialogRef: MatDialogRef<CandidateModalComponent>,private mainService:MainService,@Inject(MAT_DIALOG_DATA) public dialogData:{id:number},
) { }

  ngOnInit(): void {}
    save() {
      if(this.dialogData.id){
        this.mainService.updatevacancyformodel(this.dialogData.id,this.data).subscribe((res:any)=>{
          this.data = res;
          // this.toast.success("Vacancy Successflly updated");
          this.dialogRef.close();
          window.location.reload();
          
        },
      (error)=>{
        // this.toast.error('Error updating Vacancy',error);
      })
    
      }else{
      this.mainService.saveNewVacancy(this.data).subscribe(
        (response) => {
          console.log('Vacancy saved successfully:', response);
          this.dialogRef.close();
          window.location.reload();
        },
        (error) => {
          console.error('Error saving vacancy:', error);
        }
      );
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  // addKeyword() {
  //   const keywordInput = document.getElementById(
  //     'keywordInput'
  //   ) as HTMLInputElement;
  //   if (keywordInput.value) {
  //     this.data.keywords.push(keywordInput.value);
  //     keywordInput.value = '';
  //   }
  // }
  addKeyword() {
    if (this.newKeyword.trim()) {
      this.data.keywords.push(this.newKeyword.trim());
      this.newKeyword = ''; // Clear the input field after adding
    }
  }

  
  removeKeyword(index: number) {
    this.data.keywords.splice(index, 1);
  }

  addDesignationKeyword() {
    const designationKeywordInput = document.getElementById(
      'designationKeywordInput'
    ) as HTMLInputElement;
    if (designationKeywordInput.value) {
      this.data.designationkeywords.push(
        designationKeywordInput.value
      );
      designationKeywordInput.value = '';
    }
  }

  removeDesignationKeyword(index: number) {
    this.data.designationkeywords.splice(index, 1);
  }





 
}

