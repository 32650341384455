<h4>Request For Leave</h4>
<form (ngSubmit)="saveLeaveForm()" #leaveForm="ngForm">
  <div class="form-group">
    <label for="leaveType">Leave Type:</label>
    <mat-select
      id="leaveType"
      placeholder="Leave Type"
      name="leaveType"
      [(ngModel)]="leaveData.leaveType"
      required
      style="border: 1px solid #cccccc; border-radius: 4px; padding: 10px;"
    >
      <mat-option value="ABSENT" style="font-weight: 600">Absent</mat-option>
      <mat-option value="HALF_DAY" style="font-weight: 600">Half Day</mat-option>
      <mat-option value="LATE" style="font-weight: 600">Late</mat-option>
      <!-- <mat-option value="PAID_LEAVE" style="font-weight: 600">Paid Leave</mat-option> -->
    </mat-select>
  </div>

  <div class="form-group">
    <label>Upload Documents</label>
    <input
      type="file"
      name="fileUrl"
      id="fileUrl"
      (change)="onSelect($event)"
    />
  </div>

  <div class="form-group">
    <label for="leaveDates">Dates:</label>
    <input
      type="date"
      id="leaveDates"
      name="leaveDates"
      [(ngModel)]="selectedDate"
    />
    <button
      mat-raised-button
      style="background-color: #ff4081; color: white; margin-top: 10px;"
      type="button"
      (click)="addSelectedDate()"
    >
      Add Date
    </button>
  </div>

  <div *ngIf="leaveData.leaveDates.length > 0">
    <label>Selected Dates:</label>
    <ul
      style="border: 1px solid #cccccc; background: #ce3262; padding: 10px;"
    >
      <li
        style="color: white; background: black; border-radius: 6px; margin-top: 5px; padding: 5px; display: flex; justify-content: space-between; align-items: center;"
        *ngFor="let date of leaveData.leaveDates"
      >
        <span>{{ date }}</span>
        <button
          style="background: none; border: none; color: white; cursor: pointer;"
          (click)="removeDate(date)"
        >
          X
        </button>
      </li>
    </ul>
  </div>

  <div class="form-group">
    <label for="reason">Reason:</label>
    <textarea
      id="reason"
      name="reason"
      [(ngModel)]="leaveData.reason"
      required
      class="form-control"
      style="border: 1px solid #cccccc; border-radius: 4px; padding: 10px;"
    ></textarea>
  </div>

  <button
    mat-raised-button
    color="accent"
    type="submit"
    [disabled]="!leaveForm.valid"
  >
    Save
  </button>
</form>
