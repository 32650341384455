import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-event-chart',
  templateUrl: './event-chart.component.html',
  styleUrls: ['./event-chart.component.css'],
})
export class EventChartComponent implements OnInit {
  [x: string]: any;
  @ViewChild('myChart') barChartRef!: ElementRef;
  @ViewChild('myPieChart') pieChartRef!: ElementRef;

  constructor(private mainService: MainService) {}
  labelData: any[] = [];
  realData: any[] = [];
  initialData: any[] = [];
  user_id: any;
  piedata: any;
  department: string = 'IT & Development';
  pieChart: any[] = [];
  pendingTasks: any[] = [];
  completedTasks:any[] = [];

  ngOnInit(): void {
    this.loadBarChartData();
    this.loadPieChartData(this.department);
  }

  loadBarChartData() {
    this.mainService.taskgraph().subscribe((res) => {
      this.data = res;
      if (this.data != null) {
        for (let i = 0; i < this.data.length; i++) {
          this.labelData.push(this.data[i].Month);
          this.realData.push(this.data[i]['Completed Tasks']);
          this.initialData.push(this.data[i]['Initialized Tasks']);
        }
        this.drawStuff(this.labelData, this.realData, this.initialData);
      }
    });
  }

  loadPieChartData(department: string) {
    this.department = department;
    const apiDepartment =
      department === 'Digital Marketing' ? 'Marketing' : department;
    this.mainService.taskpietask(apiDepartment).subscribe((res: any) => {
      this.piedata = res;
      this.updatePieChart();
    });
  }

  drawStuff(labelData: any, realData: any, initialData: any) {
    const ctx = document.getElementById('myChart');
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labelData,
        datasets: [
          {
            label: 'Initial Tasks',
            data: initialData,
            backgroundColor: '#a0284d',
            borderWidth: 1,

          },
          {
            label: 'Completed Tasks',
            data: realData,
            backgroundColor: 'darkgreen',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: 'white',
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              color: 'white',
            },
          },
        },
      },
    });
  }

  updatePieChart() {
    const ctx = document.getElementById('myPieChart');

    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Pending Task', 'Completed Task', 'Total Task'],
        datasets: [
          {
            label: 'Task Data',
            data: [
              this.piedata['Pending Task'],
              this.piedata['Completed Task'],
              this.piedata['Total Task'],
            ],
            backgroundColor: ['#a0284d', 'darkgreen', 'darkblue'],
            borderWidth: 1,

          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

}
