import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-report-attendance-meeting',
  templateUrl: './report-attendance-meeting.component.html',
  styleUrls: ['./report-attendance-meeting.component.css']
})
export class ReportAttendanceMeetingComponent implements OnInit {
  user_id = localStorage.getItem('id');
  month: any;
  attendanceData: any;

  @ViewChild('attendanceChart') attendanceChartRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('meetingChart') meetingChartRef: ElementRef<HTMLCanvasElement>;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    console.log('ngOnInit called');
    this.mainService.performance(this.user_id, this.month).subscribe((res: any) => {
      console.log('API response:', res);
      this.attendanceData = res;
      this.createAttendanceChart();
      this.createMeetingChart();
    }, error => {
      console.error('API error:', error);
    });
  }

  createAttendanceChart(): void {
    console.log('Creating attendance chart with data:', this.attendanceData);
    const attendanceChartCanvas = this.attendanceChartRef.nativeElement;
    new Chart(attendanceChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['Attendance', 'Half Day', 'Absent','Late'],
        datasets: [{
          data: [this.attendanceData.presentDays, this.attendanceData.halfDay, this.attendanceData.absentDays],
          backgroundColor: ['#007bff', '#ffc107', '#333','#FF0000'],
          borderWidth: 0
        }]
      },
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  createMeetingChart(): void {
    console.log('Creating meeting chart with data:', this.attendanceData);
    const meetingChartCanvas = this.meetingChartRef.nativeElement;
    new Chart(meetingChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['Presence', 'Absent'],
        datasets: [{
          data: [this.attendanceData.presentMeetings, this.attendanceData.absentMeetings],
          backgroundColor: ['#007bff', '#ffc107'],
          borderWidth: 0
        }]
      },
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
}
