<div class="col-md-12 container2" style="width: 100%;position: relative;">
 <div class="row">
  <div class="col-md-3">
    <div class="card mb-4">
        <a [routerLink]="['task']"> 
            <div class="card-body">
                <p>Average Punch In Time</p>
                <p class="data">{{ perform.averagePunchInTime }}</p>
                <div class="employee-data" style="display:flex">
                <p class="data2">Employee</p>
                <div class="rate-info"><img src="assets/Vector.png">Done</div>
              </div>
            </div>
        </a>
    </div>
</div>
  <div class="col-md-3">
    <div class="card mb-4">
        <a [routerLink]="['task']"> 
            <div class="card-body">
                <p>Task Efficiency</p>
                <p class="data">{{ totalPendingTasks }}</p>
                <div class="employee-data" style="display:flex">
                <p class="data2">Employee</p>
                <div class="rate-info"><img src="assets/Vector.png">Done</div>
              </div>
            </div>
        </a>
    </div>
</div>

        <div class="col-md-3">
            <div class="card mb-4" >
              <a [routerLink]="['user-meeting']">
                <div class="card-body">
                    <p> Meetings Aligned</p>
                   <p class="data"> {{todayMeetingCount}}</p>
                   <div class="employee-data" style="display:flex">
                    <p class="data2">Meetings</p>
                  </div>
                </div></a>
            </div>
        </div>
        <!-- <div class="col-md-3">
            <div class="card mb-4">
              <a [routerLink]="['attendance']">
                <div class="card-body">
                    <p> Days Present</p>
                    <p class="data"> {{userMonthlyAttendance}}</p>
                    <div class="employee-data" style="display:flex">
                      <p class="data2">Attendance</p>
                    </div>
                </div>
              </a>
            </div>
        </div> -->
        <div class="col-md-3">
          <div class="card mb-4" >
            <a [routerLink]="['user-leave']"> 
              <div class="card-body">
                  <p>Leaves Report</p>
                  <p class="data"> {{userAllRequest}}</p>
                  <div class="employee-data" style="display:flex">
                    <p class="data2">Leaves</p>
                   
                  </div>
              </div></a>
          </div>
      </div>
    </div>
    <!-- <app-task-statistics></app-task-statistics> -->
    <div class="row" style="margin-top: 20px; margin-left:0px;height:200px;width: 100%;">
      <div class="col-md-7">
        <app-employee-status></app-employee-status>
      </div>
      <div class="col-md-4 gap-box" >
     <app-employee-task></app-employee-task>
      </div>
    </div>
  
</div>