 <h4>Employee Details</h4>
  <hr class="animated-line">
  <div class="container" style="margin-top: 26px; display: flex; align-items: center;">
      <input type="text" id="filterInput" class="filterJoining" placeholder="Search" (input)="filterTable()"
          style="padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); transition: border-color 0.3s ease; width: 80%;" />
      <a [routerLink]="['/admin/add-user-profile']" style="margin-left: 10px;">
          <button mat-mini-fab>
              <mat-icon>person_add</mat-icon>
          </button>
      </a>
      <div class="dropdown" [matMenuTriggerFor]="downloadMenu" style="margin-left: 10px;">
          <button mat-mini-fab>
              <mat-icon>file_download</mat-icon>
          </button>
          <mat-menu #downloadMenu="matMenu">
              <button mat-menu-item (click)="downloadpdf()">Download as PDF</button>
              <button mat-menu-item (click)="downloadExcel()">Download as Excel</button>
          </mat-menu>
      </div>
  </div>
  <div  class="container2" style="margin-top: 20px;">
    <div class="scrollable-content">
      <table>
          <thead>
              <tr>
                  <th>Id</th>
                  <th>Joining Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Date of Birth</th>
                  <th>Alternative Phone</th>
                  <th>Username</th>
                  <th>Current Address</th>
                  <th>Permanent Address</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Manager Name</th>
                  <th>Assign User</th>

                  <th>Role</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let data  of employeeDetails" class="data-row" style="color:white">
                  <td class="table-cell">{{ data.id }}</td>
                  <td class="table-cell">{{ data.createdDate | date: 'dd-MMM-yyyy' }}</td>
                  <td class="table-cell">{{ data.firstName }}</td>
                  <td class="table-cell">{{ data.lastName }}</td>
                  <td class="table-cell">{{ data.dob | date: 'dd-MMM-yyyy' }}</td>
                  <td class="table-cell" >{{ data.phone }}</td>
                  <td class="table-cell">{{ data.userName }}</td>
                  <td class="table-cell">{{ data.currentAddress }}</td>
                  <td class="table-cell">{{ data.permanentAddress }}</td>
                  <td class="table-cell">{{ data.gender }}</td>
                  <td class="table-cell">{{ data.email }}</td>
                  <td class="table-cell">{{ data.department }}</td>
                  <td class="table-cell">{{ data.designation }}</td>
                  <!-- <td class="table-cell">
                    <mat-form-field appearance="fill">
                      <mat-label>Select User</mat-label>
                      <mat-select  (click)="updateUser(managerId, list.userId)">
                        <mat-option *ngFor="let list of lists" [value]="list.userId" >
                          {{ list.userName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td> -->
                  <td>{{data.managerName}}</td>
                  <td>
                    <select (change)="assignManagerToUser($event.target.value, data.id)" style="width: 100%;">
                        <option disabled selected>Select User</option>
                      <option *ngIf="data.managerName != null">
                            {{
                            data.managerName
                            }}
                          </option>
                      <option *ngFor="let list of lists" [value]="list.id">
                        {{ list.userName }}
                      </option>
                    </select>
                  </td>
                  
                  <td class="table-cell">
                    {{ data.authorities[0]?.authority || 'No Authority' }}
                  </td>
                  
                  <!-- <td class="table-cell" *ngFor="let list of lists" onchange="updateUser">{{list.userName}}</td> -->
                  <td class="table-cell">
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon class="more-icon">more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                          <button mat-menu-item [routerLink]="['/admin/admin-document-show']"
                          [queryParams]="{ id: data.id, name: data.firstName + ' ' + data.lastName, email: data.email }">
                              <mat-icon mat-list-icon>description</mat-icon>
                              <span>Documentation</span>
                          </button>
                          <button mat-menu-item [routerLink]="['/admin/rating']" [queryParams]="{ id: data.id }">
                              <mat-icon mat-list-icon>star</mat-icon>
                              <span>Rating</span>
                           </button>
                          <button mat-menu-item [routerLink]="['/admin/add-salary']" [queryParams]="{ id: data.id }">
                              <mat-icon mat-list-icon style="color: black;">attach_money</mat-icon>
                              <span>Add Salary</span>
                          </button>
                          <button mat-menu-item [routerLink]="['/admin/profile-update']"
                              [queryParams]="{ id: data.id }">
                              <mat-icon mat-list-icon>update</mat-icon>
                              <span>Update</span>
                          </button>
                          <button mat-menu-item (click)="onDelete(data.id)">
                              <mat-icon mat-list-icon>delete</mat-icon>
                              <span>Delete</span>
                          </button>
                      </mat-menu>
                  </td>
              </tr>
          </tbody>
      </table>
</div>

</div>
<div class="pagination-container">
    <!-- <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} elements (Page {{ pageNumber + 1 }})
    </div> -->
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
</div>


