import { DatePipe } from '@angular/common';
import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-review-third',
  templateUrl: './review-third.component.html',
  styleUrls: ['./review-third.component.css']
})
export class ReviewThirdComponent implements OnInit {

  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef;
  // user_id = localStorage.getItem('id');
  @Input() userId: any;
  data: any;
review:any={};
name=localStorage.getItem('userName')
formattedDate: string = '';
  constructor(private mainService: MainService,private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.mainService.dataPerformance(this.userId).subscribe((res: any) => {
      this.data = res;
      this.renderChart(); 
    });
    this.report();
  }
  report() {
    this.mainService.report(this.userId).subscribe((res: any) => {
      this.review = res[0];
      this.formattedDate = this.formatDate(this.review.createdOn);
    });
  }

formatDate(timestamp: number): string {
  return this.datePipe.transform(timestamp, 'MMMM d \'at\' h:mm a') ?? '';
}
  renderChart() {
    const monthMapping: { [key: string]: string } = {
      'JANUARY': 'Jan',
      'FEBRUARY': 'Feb',
      'MARCH': 'Mar',
      'APRIL': 'Apr',
      'MAY': 'May',
      'JUNE': 'Jun',
      'JULY': 'Jul',
      'AUGUST': 'Aug',
      'SEPTEMBER': 'Sep',
      'OCTOBER': 'Oct',
      'NOVEMBER': 'Nov',
      'DECEMBER': 'Dec'
    };
    const months = this.data.map((entry: any) => monthMapping[entry.Month]);
    const scores = this.data.map((entry: any) => entry['Obtained Score']);

    const chartData = {
      labels: months,
      datasets: [{
        label: 'Monthly Report',
        data: scores,
        fill: true,
        borderColor: '#6391ff',
        backgroundColor: 'rgba(99, 145, 255, 0.2)',
      }]
    };

    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        }
      }
    };

    new Chart(this.chartCanvas.nativeElement, {
      type: 'line',
      data: chartData,
      options: chartOptions,
    });
  }
}
