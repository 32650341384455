import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-user-document',
  templateUrl: './user-document.component.html',
  styleUrls: ['./user-document.component.css']
})
export class UserDocumentComponent implements OnInit {
  
  userDocument: any;
  id = localStorage.getItem('id');
  files: { [key: string]: File[] } = {
    aadhar: [],
    pan: [],
    metriculation: [],
    intermediate: [],
    graduation: [],
    postGraduation: [],
    experience: [],
    lastCompanyOfferLetter: [],
    salarySlip: [],
    appointmentLetter: [],
    doc1: [],
    doc2: [],
    doc3: [],
    doc4: [],
    doc5: [],
  };
  user: any;
  documentVerified: boolean = false; 
  constructor(private uploadService: MainService, private mainService: MainService) { }

  ngOnInit(): void {
    this.mainService.showDocs(this.id).subscribe(
      (res: any) => {
        this.documentVerified = res.documentVerified; 
      },
      (error) => {
        console.error('Error fetching documents:', error);
      }
    );
  }

  onSelect(documentType: string, event: any) {
    const maxFileSize = 300 * 1024; // 300 KB in bytes
    const addedFiles = event.addedFiles.filter((file: File) => {
      return file.size <= maxFileSize && !file.type.includes('pdf');
    });

    if (addedFiles.length === 0) {
      if (event.addedFiles.some((file: File) => file.type.includes('pdf'))) {
        Swal.fire('Error', 'You cannot upload PDF files.', 'error');
      } else {
        Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      }
      return;
    }

    this.files[documentType] = addedFiles;
  }

  onRemove(documentType: string, file: File) {
    this.files[documentType] = this.files[documentType].filter((f) => f !== file);
  }

  uploadFiles() {
    if (Object.values(this.files).every(files => files.length === 0)) {
      alert("No Files Selected");
      return;
    }

    const documentsArray: { category: string, imageUrl: string }[] = [];
    let processedFiles = 0;

    for (const documentType in this.files) {
      if (this.files.hasOwnProperty(documentType)) {
        const fileArray = this.files[documentType];

        fileArray.forEach(file => {
          const data = new FormData();
          data.append('file', file);
          const folderName="userDocuments";

          this.uploadService.uploadFile(file,folderName).subscribe(
            (res) => {
              const secureUrl = res.message;
              documentsArray.push({ category: documentType, imageUrl: secureUrl });

              if (++processedFiles === Object.values(this.files).reduce((acc, files) => acc + files.length, 0)) {
                const userData = {
                  urls: documentsArray,
                  documentVerified: true,
                };

                this.uploadService.savedocs(this.id, userData).subscribe(
                  (patchRes) => {
                    Swal.fire('Success', 'Form submitted successfully', 'success');
                  },
                  (patchError) => {
                    console.error(patchError);
                    Swal.fire('Error', patchError.error.message, 'error');
                  }
                );
              }
            },
            (error) => {
              console.error(error);
            }
          );
        });
      }
    }
  }

  updateFiles() {
    if (Object.values(this.files).every(files => files.length === 0)) {
      alert("No Files Selected");
      return;
    }

    const documentsArray: { category: string, imageUrl: string }[] = [];
    let processedFiles = 0;

    for (const documentType in this.files) {
      if (this.files.hasOwnProperty(documentType)) {
        const fileArray = this.files[documentType];

        fileArray.forEach(file => {
          const data = new FormData();
          data.append('file', file);
          const folderName="userDocuments"

          this.uploadService.uploadFile(file,folderName).subscribe(
            (res) => {
              const secureUrl = res.message;
              documentsArray.push({ category: documentType, imageUrl: secureUrl });

              if (++processedFiles === Object.values(this.files).reduce((acc, files) => acc + files.length, 0)) {
                const userData = {
                  urls: documentsArray,
                  documentVerified: true,
                 
                };

                this.uploadService.updatefiles(this.id, userData).subscribe(
                  (patchRes) => {
                    Swal.fire('Success', 'Form submitted successfully', 'success');
                  },
                  (patchError) => {
                    console.error(patchError);
                    Swal.fire('Error', patchError.error.message, 'error');
                  }
                );
              }
            },
            (error) => {
              console.error(error);
            }
          );
        });
      }
    }
  }
}
