import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
}

interface MeetingData {
  purpose: string;
  date: string;
  time: string;
  description: string;
  location: string;
  userIds: number[];
}

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.css','/../../../shared/all-style/add-meeting-style.component.css'],
})
export class MeetingsComponent implements OnInit {
  
  id: string; // Assuming 'id' is a string here
  private routeSub: Subscription;
  leaveData: MeetingData = {
    purpose: '',
    date: '',
    time: '',
    description: '',
    userIds: [],
    location: '',
  };
  selectedEmployeesControl = new FormControl();
  data: Employee[] = [];

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.id = localStorage.getItem('id');
    this.mainService.AllEmployeeProfileList().subscribe((res: Employee[]) => {
      this.data = res;
    });
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.leaveData.purpose = params['purpose'];
      this.leaveData.date = params['date'];
      this.leaveData.time = params['time'];
      this.leaveData.description = params['description'];
      this.leaveData.userIds = params['userIds'];
      this.leaveData.location = params['location'];
    });
  }

  formSubmit(event) {
    event.preventDefault(); 
  
    let target = event.target;
   
    const arrObj = {
      purpose: target.querySelector('#purpose').value.trim(),
      date: target.querySelector('#date').value.trim(),
      time: target.querySelector('#meetingTime').value.trim(),
      description: target.querySelector('#description').value.trim(),
      location: target.querySelector('#location').value.trim(),
      userIds: this.selectedEmployeesControl.value.map((employee: Employee) => employee.id),
    };
  
    // Check if any required field is empty or no employee is selected
    if (!arrObj.purpose || !arrObj.date || !arrObj.time || !arrObj.description || !arrObj.location || arrObj.userIds.length === 0) {
      Swal.fire('Please fill in all fields and select at least one employee.');
      return;
    }
  
    // Check if the meeting time is entered
    if (!arrObj.time) {
      Swal.fire('Please enter the time');
      return;
    } else {
      const selectedDate = new Date(arrObj.date);
      const [hours, minutes] = arrObj.time.split(':');
      selectedDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
      arrObj['meetingTime'] = selectedDate.getTime(); 
    }
  
    // Proceed with form submission
    this.mainService.meetings(this.id, arrObj).subscribe(
      (data: any) => {
        console.log(data);
        Swal.fire('Successfully done !!', 'success');
  
        this.router.navigate(['/admin/show-meetings']);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }
  
}
