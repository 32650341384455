import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.css']
})
export class AddNotesComponent implements OnInit {
  note: string = '';
  projectId: string;
  noteIndex: number | null = null;
  constructor(
    private mainService: MainService,
    private dialogRef: MatDialogRef<AddNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string, note?: string, noteIndex?: number } // Inject dialog data
  ) {
    this.projectId = data.projectId;
    if (data.note) {
      this.note = data.note;
    }
    if (data.noteIndex !== undefined) {
      this.noteIndex = data.noteIndex;
    }
  }

  ngOnInit(): void { }
  formSubmit(): void {
    if (!this.projectId) {
      console.error('Project ID is not defined');
      return;
    }
    this.mainService.getAssignmentById(this.projectId).subscribe({
      next: (res: any) => {
        let notes = res.notes;
        if (this.noteIndex !== null && this.noteIndex >= 0) {
          notes[this.noteIndex] = this.note;
        } else {
          notes.push(this.note);
        }
        const payload = { notes: notes };
        this.mainService.updateNotes(this.projectId, payload).subscribe({
          next: (res: any) => {
            const message = this.noteIndex !== null ? 'Note updated successfully' : 'Note added successfully';
            Swal.fire(message, '', 'success').then(() => {
              window.location.reload();
            });
          },
          error: (err: any) => {
            console.error('Error updating notes', err);
          }
        });
      },
      error: (err: any) => {
        console.error('Error fetching notes', err);
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
