import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-previous-assignment',
  templateUrl: './previous-assignment.component.html',
  styleUrls: ['./previous-assignment.component.css'],
})
export class PreviousAssignmentComponent implements OnInit {
  id = localStorage.getItem('id');
  status: string = 'Pending';
  value: any;
  data: any[];
  userId: string;
  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.userId = this.route.snapshot.paramMap.get('id');
    // this.mainService.previousAssign(this.id).subscribe((res: any[]) => {
    //   if (Array.isArray(res)) {
    //     this.data = res.map((item: any) => {
    //       item.dateTime = this.formatDateTime(item.dateTime);
    //       return item;
    //     });
    //   } else {
    //     // Handle the case when res is not an array
    //   }
    // });
  }
  formatDateTime(dateTime: number[]): string {
    const [year, month, day, hours, minutes, seconds, milliseconds] = dateTime;
    const date = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    const formattedDate = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}`;
    const formattedTime = `${this.padZero(date.getHours())}:${this.padZero(
      date.getMinutes()
    )}:${this.padZero(date.getSeconds())}`;
    const formattedDateTime = `${formattedDate} ,(${formattedTime})`;
    return formattedDateTime;
  }

  padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
