import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MainService } from 'src/app/MyService/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css','/../../../shared/all-style/profile-style.component.css'],
})
export class UserProfileComponent implements OnInit {
  user: any = null;
  userName = localStorage.getItem('userName');
  selectedFile: File | null = null;
  @ViewChild('fileInput') fileInput: any;
  isSubmitting = false;
data:any;
id = localStorage.getItem('id');
loginUserId: string | null = localStorage.getItem('id'); 
selectedEmployee: string | null = null;
private cloudinaryApiKey = '974981595445112';
private cloudinaryApiSecret = '4URnjaut9IehzWDZZ8_AVH8pKoQ';
private cloudinaryDeleteUrl = 'https://api.cloudinary.com/v1_1/dzvsmmraz/image/destroy';


  constructor(
    private http: HttpClient,
    private login: LoginService,
    private mainService: MainService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.user = this.login.getUser();
    this.mainService.managerAlllist(this.id,true).subscribe((res:any)=>{
      this.data=res;
      const defaultEmployee = this.data.find((employee: any) => employee.userName === this.userName);
      if (defaultEmployee) {
        this.selectedEmployee = defaultEmployee.id;
        this.onEmployeeSelect({ target: { value: defaultEmployee.id } });
        console.log("sleect emplyee id"+this.selectedEmployee) 
      }
   
    });
    
  }
  onEmployeeSelect(event: any) {
    const selectedEmployeeId = event.target.value;
    this.mainService.showuserbyId(selectedEmployeeId).subscribe((res: any) => {
      this.user = res;
    });
  }

  onEditClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    console.log("Selected file: ", this.selectedFile);
    const folderName = 'profilePhotos';
    const data = new FormData();
    data.append('file', this.selectedFile as Blob);
  
    this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
      (response: any) => {
        const filePath = response.message;
        this.updateUserProfileImage(filePath); 
      },
      (error) => {
        console.error('File upload failed:', error);
        Swal.fire('Error', 'File upload failed.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  
  updateUserProfileImage(filePath: string) {
    console.log("Updating user profile image with URL: ", filePath); // Log the image URL before updating
    this.mainService.updateProfileImage(this.user.id, filePath).subscribe(
      response => {
        console.log("API response:", response);
        Swal.fire('Success', 'Profile image updated successfully', 'success');
        this.user.profileImage = filePath;
        this.isSubmitting = false;
      },
      error => {
        console.error("Update profile image error:", error); // Log any errors during profile image update
        Swal.fire('Error', 'Could not update profile image', 'error');
        this.isSubmitting = false;
      }
    );
  }
  
  // onDeleteImage(imageUrl: string) {
  //   const publicId = this.getPublicIdFromUrl(imageUrl);
  //   if (publicId) {
  //     const timestamp = Math.floor(Date.now() / 1000).toString();
  //     const signatureString = `public_id=${publicId}&timestamp=${timestamp}${this.cloudinaryApiSecret}`;
  //     const signature = CryptoJS.SHA1(signatureString).toString();
  //     const body = new URLSearchParams();
  //     body.set('public_id', publicId);
  //     body.set('timestamp', timestamp);
  //     body.set('api_key', this.cloudinaryApiKey);
  //     body.set('signature', signature);

  //     this.http.post(this.cloudinaryDeleteUrl, body.toString(), {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       })
  //     }).subscribe(
  //       response => {
  //         console.log("Deleted image:", response);
  //         Swal.fire('Success', 'Image deleted successfully', 'success');
  //         this.updateUserProfileImage('');
  //       },
  //       error => {
  //         console.error("Delete image error:", error);
  //         Swal.fire('Error', 'Could not delete image', 'error');
  //       }
  //     );
  //   } else {
  //     Swal.fire('Error', 'No image found to delete', 'error');
  //   }
  // }

  
  
  // getPublicIdFromUrl(imageUrl: string): string | null {
  //   if (!imageUrl) {
  //     return null;
  //   }
  //   const regex = /\/v\d+\/(.+?)\.\w+$/;
  //   const match = imageUrl.match(regex);
  //   if (match && match[1]) {
  //     return match[1];
  //   } else {
  //     return null;
  //   }
  // }
}
