<h4>Assign Task User</h4>
<mat-card style="background: #2d2d2d;">
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (submit)="formSubmit($event)">
            <label for="userIds" style="color: white;">Select Employee:</label>
            <div class="form-field" style="background: rgb(49, 48, 48);">
              
              <select id="userIds" name="employeeSelector" style="background-color: #2d2d2d;    border-radius: 6px; color: white;width: 100%; height: 39px; padding: 8px" [(ngModel)]="user_id">
                <option *ngFor="let employee of data" [value]="employee.id">
                  {{ employee.userName }}
                </option>
              </select>
            </div>
            <mat-form-field class="w100" appearance="outline" style="margin-top: 34px">
              <mat-label style="color: white;">Task</mat-label>
              <textarea required autofocus matInput placeholder="Enter here" rows="14" [(ngModel)]="subject" style="color: white; background: #ce32622e;"></textarea>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
