import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  id = localStorage.getItem('id');
  userId: any;
  isSubmitting = false;
  selectedEmployee: any;
  participants: any[] = [];
  data: any;
  selectedFiles: File[] = []; // Array to hold selected files
  filePreviews: { name: string; url: string }[] = []; 
  projectId: string | null = null;
  isUpdating: boolean = false;

  public user = {
    projectName: '',
    heading: '',
    subject: '',
    description: '',
    url: '',
    status: '',
    deadline: '',
    fileUrl: '',
    memberIds: [] as number[],
    files: [] as string[], 
    priorityLevel: '',
    banner: '',
  };

  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) private projectDetail: any
  ) {
    this.projectId = projectDetail.projectId;
  }
  ngOnInit(): void {
    if (this.projectId) {
      this.isUpdating = true;
      this.mainService.getAssignmentById(this.projectId).subscribe((projectData: any) => {
        // Populate the form fields with existing data
        this.user.heading = projectData.headings;
        this.user.subject = projectData.subject;
        this.user.description = projectData.description;
        this.user.status = projectData.status;
        this.user.priorityLevel = projectData.priorityLevel;
        this.user.banner = projectData.banner;
        this.user.files = projectData.files || [];
  
        // Initialize participants list
        this.participants = projectData.Users.map((user: any) => ({
          assignToProfile: user.assignToProfile,
          assignToName: user.assignToName,
          id: user.assignToId
        }));
        this.user.memberIds = this.participants.map(p => p.id);
  
        // Initialize file previews with existing files
        this.filePreviews = projectData.files.map((fileUrl: string) => ({
          name: this.extractFileName(fileUrl),
          url: fileUrl
        }));
  
        console.log("Project data loaded for update:", this.user);
      });
    } else {
      this.isUpdating = false;
    }
  
    this.mainService.managerAlllist(this.id, true).subscribe((res: any) => {
      this.data = res;
      console.log("Manager list loaded:", this.data);
    });
  }
  
  // Helper function to extract the file name from the URL
  extractFileName(url: string): string {
    return url.split('/').pop() || '';
  }
  
  onBannerImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const folderName = 'messages';
          const response: any = await this.mainService.uploadFile(file, folderName).toPromise();
          this.user.banner = response.message; 
        } catch (error) {
          Swal.fire('Error', 'Banner upload failed.', 'error');
        }
      };
      reader.readAsDataURL(file);
    }
  }

  addSelectedFiles(event: any): void {
    const files: File[] = Array.from(event.addedFiles);
    this.selectedFiles.push(...files);
    this.filePreviews.push(...files.map(file => ({
      name: file.name,
      url: URL.createObjectURL(file)
    })));
  }

  async formSubmit(event: Event): Promise<void> {
    event.preventDefault();
    let fileUrls: string[] = [];
    if (this.selectedFiles.length > 0) {
      try {
        fileUrls = await this.uploadFiles();
      } catch (error) {
        Swal.fire('Error', 'File upload failed.', 'error');
        return; 
      }
    }
    this.saveFormData(fileUrls);
  }

  async uploadFiles(): Promise<string[]> {
    const folderName = 'messages';
    const fileUrls: string[] = [];
    for (const file of this.selectedFiles) {
      try {
        const response: any = await this.mainService.uploadFile(file, folderName).toPromise();
        fileUrls.push(response.message); // Assuming 'message' contains the file URL
      } catch (error) {
        console.error('File upload failed:', error);
        throw new Error('File upload failed');
      }
    }
    return fileUrls;
  }

  saveFormData(fileUrls: string[]): void {
    this.user.files = fileUrls; 
    this.user.memberIds = this.participants.map(p => p.id);
    if (this.isUpdating && this.projectId) {
      this.updateformSubmit(this.projectId, this.user);
    } else {
      const userId = this.selectedEmployee ? this.selectedEmployee.id : this.id;
      this.mainService.savAssignment(userId, this.user).subscribe(
        (res) => {  
          Swal.fire('Success', 'Form submitted successfully', 'success');
          this.router.navigate(['manager-dashboard/project-dashboard']);
          this.isSubmitting = false;
        },
        (error) => {
          Swal.fire('Error', 'Failed to submit the form', 'error');
          this.isSubmitting = false;
        }
      );
    }
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }

  addParticipant(): void {
    if (this.selectedEmployee && !this.participants.some(p => p.id === this.selectedEmployee.id)) {
      this.participants.push({
        assignToProfile: this.selectedEmployee.profileImage,
        assignToName: this.selectedEmployee.userName,
        id: this.selectedEmployee.id
      });
      this.user.memberIds = this.participants.map(p => p.id);
      console.log('Participant added:', this.selectedEmployee);
      console.log('Current memberIds:', this.user.memberIds);
    } else {
      Swal.fire('Info', 'Employee is already added or no employee selected', 'info');
    }
    this.selectedEmployee = null;
  }

  removeParticipant(participant: any): void {
    this.participants = this.participants.filter(p => p.id !== participant.id);
    this.user.memberIds = this.user.memberIds.filter(id => id !== participant.id);
  }

  removeFile(index: number): void {
    URL.revokeObjectURL(this.filePreviews[index].url);
    this.selectedFiles.splice(index, 1);
    this.filePreviews.splice(index, 1);
  }

  updateformSubmit(projectId: string, projectUpdate: any): void {
    this.mainService.updateProject(projectId, projectUpdate).subscribe(
      (res: any) => {
        Swal.fire('Success', 'Project updated successfully', 'success');
        this.router.navigate(['manager-dashboard/project-dashboard']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to update the project', 'error');
        this.isSubmitting = false;
      }
    );
  }
}
