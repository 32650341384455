import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-show-user-chat',
  templateUrl: './show-user-chat.component.html',
  styleUrls: ['./show-user-chat.component.css','/../../../shared/all-style/all-style.component.css']
})
export class ShowUserChatComponent implements OnInit {
  filteredData: any;
  data: any;
  userId: string;
  id: string = localStorage.getItem('id');
  startDate: string = '';
  endDate: string = '';
  inputDateFromString: any;
  inputDateToString: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  showFilterForm: boolean = false;
  user_id: string;
  employees: any;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchMeetings();
    this.mainService.managerAlllist(this.id,true).subscribe((res:any)=>{
      this.employees = res;
    })
  }

  fetchMeetings() {
    const start = this.mainService.toTimestamp(this.startDate) ;
    const end = this.mainService.toTimestamp(this.endDate);
    const userId= this.user_id || this.id
    this.mainService.showUserMessage(userId, start, end, this.pageNumber, this.pageSize).subscribe(
      (res) => {
        this.data = res;
        this.filteredData = this.data.content;
        this.totalElements = this.data.totalElements;
        console.log('Fetched meetings:', JSON.stringify(this.data));
      },
      (error) => {
        console.error('Error fetching meetings:', error);
      }
    );
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchMeetings();
  }

  onSubmitFilter(): void {
    this.pageNumber = 0; 
    this.fetchMeetings();
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }
  openPopup(fileUrl) {
    window.open(fileUrl, 'Document Viewer', 'resizable=yes,scrollbars=yes');
    return false;
  }

  navigateToAddUserTask(): void {
    this.router.navigate(['manager-dashboard/add-message']);
  }
  clearFilters() {
    this.user_id = '';
    this.startDate = '';
    this.endDate = '';
    this.fetchMeetings();
  }
}
