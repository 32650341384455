import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-user-ratings',
  templateUrl: './show-user-ratings.component.html',
  styleUrls: ['./show-user-ratings.component.css','/../../../shared/all-style/review-performance.component.css']

})
export class ShowUserRatingsComponent implements OnInit {
  id: number;
  // user_id = localStorage.getItem('id');
  month: string;
  data: any = {};
  circleDasharray = 283;
  circleDashoffset: any;
  strokeColor: string;
  performanceMessage: string;
  report: any = {};
  userReviews: number;
  teamWork: number;
  generalConduct: number;
  jobKnowledge: number;
  workQuality: number;
  skills: number;
  assistance: number;
  attendance: number;
  workDelivery: number;
  performance: number;
  userId:any
  timeline:any;
  constructor(private mainService: MainService, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.userId = params['id'] || '';
    });
  }

  ngOnInit(): void {
    if (!this.userId) {
      console.error('User ID is null or invalid');
      return; // Handle the absence of user ID appropriately
    }

    this.mainService.performance(this.userId, this.month).subscribe((res: any) => {
      this.data = res;
      this.timeline=this.data.timeline;
      this.calculateCircleDashoffset();
      this.setPerformanceMessage();
    }, error => {
      console.error('Error fetching performance data:', error);
      // Handle error appropriately
    });

    this.mainService.report(this.userId).subscribe((res: any) => {
      this.report = res;
      console.log('API Response report:', this.report); // Debug output for report data
      this.setReportData();
    }, error => {
      console.error('Error fetching report data:', error);
      // Handle error appropriately
    });
  }

  setReportData(): void {
    this.userReviews = 0;
    this.teamWork = 0;
    this.generalConduct = 0;
    this.jobKnowledge = 0;
    this.workQuality = 0;
    this.skills = 0;
    this.assistance = 0;
    this.attendance = 0;
    this.workDelivery = 0;
    this.performance = 0;
    if (this.report.length > 0) {
      this.report.forEach((item: any) => {
        this.userReviews += item.percentage ;
        this.teamWork  += (item.teamWork / 5) * 100 || 0;
        this.generalConduct += (item.generalConduct / 5) * 100 || 0;
        this.jobKnowledge += (item.jobKnowledge / 5) * 100 || 0;
        this.workQuality += (item.workQuality / 5) * 100 || 0;
        this.skills += (item.skills / 5) * 100 || 0;
        this.assistance += (item.assistance / 5) * 100 || 0;
        this.attendance += (item.attendance / 5) * 100 || 0;
        this.workDelivery += (item.workDelivery / 5) * 100 || 0;
        this.performance += item.percentage;
      });
    } else {
      // Handle the case when the report array is empty
      this.userReviews = 0;
      this.teamWork = 0;
      this.generalConduct = 0;
      this.jobKnowledge = 0;
      this.workQuality = 0;
      this.skills = 0;
      this.assistance = 0;
      this.attendance = 0;
      this.workDelivery = 0;
      this.performance = 0;
    }
  }

  calculateCircleDashoffset(): void {
    const percentage = Math.round(this.data.overAllPerformace);
    this.circleDashoffset = this.circleDasharray - (this.circleDasharray * percentage / 100);
    this.strokeColor = percentage === 100 ? '#BDBDBD' : '#4FBAF0';
  }

  setPerformanceMessage(): void {
    if (this.data.overAllPerformace < 50) {
      this.performanceMessage = "Performance needs improvement!";
    } else if (this.data.overAllPerformace < 75) {
      this.performanceMessage = "Good performance, keep it up!";
    } else if (this.data.overAllPerformace < 90) {
      this.performanceMessage = "Great performance!";
    } else {
      this.performanceMessage = "Outstanding performance!";
    }
  }
}
