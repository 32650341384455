import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { UpdateStatusModalComponent } from '../update-status-modal/update-status-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-candidates-update',
  templateUrl: './candidates-update.component.html',
  styleUrls: ['./candidates-update.component.css']
})
export class CandidatesUpdateComponent implements OnInit {
  candidates: any[] = [];
  hirings: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  userId: any;
  vacancyId: any;
  isActiveVacancy: boolean;
  hiringAIStatus: any;
  status :any;
  startDate: any;
  designation: any;
  endDate: any;

  constructor(private mainService: MainService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.mainService.getAllhiring(this.userId, this.vacancyId, this.isActiveVacancy, this.hiringAIStatus, this.status, this.startDate, this.designation, this.endDate, this.pageNumber, this.pageSize).subscribe((res: any) => {
      this.hirings = res.content;
      this.totalElements = res.totalElements;
      console.log(JSON.stringify(this.hirings));
    });
  }

  openModalDialog(id: number): void {
    const dialogRef = this.dialog.open(UpdateStatusModalComponent, {
      width: '585px',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('this dialog was closed');
    });
  }

  filterCandidates(progressStatus: string): any[] {
    return this.hirings.filter((hiring: any) => hiring.progressStatus === progressStatus);
  }
}
