<div class="content-wrapper" style="min-height: 906px">
    <section class="Leads_manage">
      <div class="bord">
        <div class="user-panel">
          <div class="pull-left image"></div>
          <div
            class="pull-left info"
            style="
              display: flex;
              margin-left: 20px;
              margin-top: 10px;
              margin-bottom: 10px;
            "
          >
            <p style="font-weight: 900; font-size: 20px; margin-bottom: 1px; color: white;text-transform:capitalize;">
              {{ name }}
            </p>
            <span
              ><p
                style="
                  font-weight: 900;
                  color: white;
                  margin-bottom: 4px;
                  margin-left: 20px;
                "
              >
                {{ dateAndTime }}
              </p></span
            >
          </div>
        </div>
        <agm-map
          class="sebm-google-map-container"
          [latitude]="lat"
          [longitude]="long"
          [zoom]="15"
          [mapTypeControl]="true"
        >
          <agm-marker [latitude]="lat" [longitude]="long"> </agm-marker>
        </agm-map>
      </div>
    </section>
  </div>
  