import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarHidden = new BehaviorSubject<boolean>(true);

  constructor() {}

  // Observable to subscribe to sidebar visibility changes
  isSidebarHidden(): Observable<boolean> {
    return this.sidebarHidden.asObservable();
  }

  // Method to toggle the sidebar visibility
  toggleSidebar(): void {
    this.sidebarHidden.next(!this.sidebarHidden.value);
  }
}
