import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salary-cycle',
  templateUrl: './salary-cycle.component.html',
  styleUrls: ['./salary-cycle.component.css']
})
export class SalaryCycleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // this.mainService.showAllSalary().subscribe((res) => {
    //   this.data = res;
    // });
  }

}
