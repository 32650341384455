
  <div class="container">
    <div class="project-details">
      <div class="project-info">
        <div class="project-icon">
          <i class="fas fa-video"></i>
        </div>
        <div class="project-name">Ace Divino Video</div>
      </div>
      <table>
        <tr>
          <th>CREATED</th>
          <td>Mar 23, 02:23 PM</td>
        </tr>
        <tr>
          <th>DEADLINE</th>
          <td>Jun 02, 04:01 PM</td>
        </tr>
        <tr>
          <th>MEMBERS</th>
          <td>
            <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=40&d=identicon&r=PG" alt="Member 1" height="30" width="30" style="border-radius: 50%; margin-right: 5px;">
            <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=40&d=identicon&r=PG" alt="Member 2" height="30" width="30" style="border-radius: 50%; margin-right: 5px;">
            <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=40&d=identicon&r=PG" alt="Member 3" height="30" width="30" style="border-radius: 50%;">
          </td>
        </tr>
      </table>
      <div class="project-info">
        <i class="fas fa-clock"></i>
        <span>Created By</span>
        <span>Nitya Sharma (Marketing Head)</span>
      </div>
      <div class="project-info">
        <i class="fas fa-user"></i>
        <span>Assign</span>
        <span>Shakshi</span>
        <span>Akash</span>
        <span>Ajay</span>
      </div>
      <div class="project-info">
        <i class="fas fa-users"></i>
        <span>Group</span>
        <span>Team projects</span>
        <i class="fas fa-arrow-right"></i>
        <span>Editing Team</span>
      </div>
      <div class="project-info">
        <i class="fas fa-star"></i>
        <span>Priority</span>
        <span>Medium</span>
      </div>
      <div class="description">
        <p>TaskFlow is an intuitive task management system designed to help teams.</p>
      </div>
    </div>
    <div class="todos">
      <div class="todo-item">
        <input type="checkbox" checked />
        <div class="todo-title">Create Thumbnail</div>
        <div class="todo-date">May 20, 2020</div>
      </div>
      <div class="todo-item">
        <input type="checkbox" checked />
        <div class="todo-title">Video Creation</div>
        <div class="todo-date">May 20, 2020</div>
      </div>
      <div class="todo-item">
        <input type="checkbox" />
        <div class="todo-title">Task 3</div>
        <div class="todo-date">May 20, 2020</div>
      </div>
      <div class="todo-item">
        <input type="checkbox" />
        <div class="todo-title">Task 4</div>
        <div class="todo-date">May 20, 2020</div>
      </div>
    </div>
   
  </div>
  <div class="container">
    <div class="header">
      <h1>Notes</h1>
      <button class="add-button">+</button>
    </div>
    <div class="content">
      <div class="note">
        <div class="note-header">
          <div class="note-date">Apr 2, 2023</div>
          <button class="note-options">...</button>
        </div>
        <div class="note-title">ChatGPT Tricks for business marketing</div>
        <div class="note-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id dui mi. Fusce varius bibendum ante, non lacinia. Fall usasc ce variu slorem ipsum dolor sit amet
        </div>
      </div>
      <div class="note">
        <div class="note-header">
          <div class="note-date">Apr 2, 2023</div>
          <button class="note-options">...</button>
        </div>
        <div class="note-title">ChatGPT Tricks for business marketing</div>
        <div class="note-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id dui mi. Fusce varius bibendum ante, non lacinia. Fall usasc ce variu slorem ipsum dolor sit amet
        </div>
      </div>
      <div class="note">
        <div class="note-header">
          <div class="note-date">Apr 2, 2023</div>
          <button class="note-options">...</button>
        </div>
        <div class="note-title">ChatGPT Tricks for business marketing</div>
        <div class="note-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id dui mi. Fusce varius bibendum ante, non lacinia. Fall usasc ce variu slorem ipsum dolor sit amet
        </div>
      </div>
    </div>
  </div>

