<h4> Tasks</h4>
<div class="header-row">
  <h5>Task List</h5>
  <div class="button-group" >
    <!-- <button mat-button class="custom-button" (click)="assignTaskToUser()">
      <mat-icon>add</mat-icon> Assign Task
    </button> -->
    <button mat-button class="custom-button" routerLink="/admin/add-task">
      <mat-icon>add</mat-icon>Task Addition
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserProfile()">
      <mat-icon>arrow_back</mat-icon>All Task
    </button>
    
  </div>
</div>


<div class="container2" >
  <div class="scrollable-content">
  <table>
    <thead>
      <tr>
        <th>Time</th>
        <th>Name</th>
        <th>Task</th>
        <th>Comments</th>
        <th>Deadline</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of data">
        <td *ngIf="data.createdTime">
          {{ data.createdTime | date : "dd-MMM-yyyy " }}
        </td>

        <td>{{ data.userName }}</td>
        <td>{{ data.subject }}
          <ng-container *ngIf="data.fileUrl">
            <a href="#" (click)="openPopup(data.fileUrl)">
              <img src="../../../../assets/task.png" alt="Document Icon" width="20" height="20">
            </a>
          </ng-container>
        </td>

        <td>
          <div id="existingComments">{{ data.comments }}</div>
        </td>
        <td>{{data.deadLine |date: 'dd-MMM-yyyy hh:mm a'  }}</td>

        <td style="color: white" [ngStyle]="{
          'background-color': data.status === 'PENDING' ? 'rgb(103, 59, 141)' :
                              data.status === 'REVIEWED' && data.comments ? 'rgba(103, 59, 141, 0.67)' :
                              data.status === 'DONE' ? 'rgba(103, 59, 141, 0.67)' :
                              data.status === 'DUE' ? 'rgba(103, 59, 141, 0.67)' :
                              data.status === 'ASSIGNED' ? 'rgba(103, 59, 141, 0.67)' :
                              'rgb(106, 106, 152)'
        }">
          {{ data.status }}
        </td>
        <td>
          <button mat-button [matMenuTriggerFor]="actionMenu"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #actionMenu="matMenu">
            <!-- <button mat-menu-item [routerLink]="['/admin/user-task']" [queryParams]="{ id: data.userId, name: data.userName }">
              <mat-icon>done_all</mat-icon> All Task
            </button> -->
            <button mat-menu-item (click)="openUserModal(data.userId, data.id)" >
              <mat-icon>comment</mat-icon> Add Comment
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>