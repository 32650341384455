<mat-dialog-content>
    <div class="modal-overlay" (click)="closeModal()"></div>
    <form (ngSubmit)="submitVerification()">
        <div class="modal-content" style="width: 400px;">
            <span class="close" (click)="closeModal()">&times;</span>
            <div>
                <p>Document Verification</p>
                <mat-form-field appearance="fill" style="width: 360px;">
                    <mat-label>Verification Status</mat-label>
                    <mat-select [(ngModel)]="documentVerified" style="color: black;">
                        <mat-option value="true" style="color: black;">Verified</mat-option>
                        <mat-option value="false" style="color: black;">Unverified</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button mat-raised-button  type="submit" style="background-color: #ff4081;color: white;">Submit</button> 
        </div>
    </form>
</mat-dialog-content>
