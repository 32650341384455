<h4>
   User Assignment
  </h4>
  <div class="container2" style="margin-top: 30px;">
    <table>
      <thead>
        <tr>
          <th class="background">Date</th>
          <th class="background">Name</th>
          <th class="background">Assignment</th>
          <th class="background">Description</th>
          <th class="background">Deadline</th>
          <th class="background">Comment</th>
          <th class="background">Status</th>
          <!-- <th  class="background">All Task</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <th>{{ data.date }}</th>
          <th>{{ data.user.firstName }} {{ data.user.lastName }}</th>
          <th>{{ data.task }}</th>
          <th>{{ data.description }}</th>
          <th>{{ data.deadline }}</th>

          <th>
            <div id="existingComments">{{ data.comment }}</div>
          </th>

          <th>
            <button
              class="button2"
              style="width: 121px"
              [style.background-color]="
                data.status === 'Pending'
                  ? '#ff7800'
                  : data.status === 'Reviewed' && data.comment
                  ? '#e61212'
                  : data.status === 'Completed'
                  ? 'green'
                  : ''
              "
            >
              {{ data.status }}
            </button>
          </th>
        </tr>
      </tbody>
    </table>
  </div>

