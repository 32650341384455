<h4> Employee's Salary Cycle</h4>
<div class="container2" style="margin-top: 39px;">
    <table>
      <thead>
        <tr>
          <th style="width: 72px">Id</th>
          <th>Name</th>
          <th>Total Salary</th>
          <th>Deducted</th>
          <th>Comment</th>
          
        </tr>
      </thead>
      <!-- <tbody>
        <tr *ngFor="let data of data">
          <td>{{ data.userId }}</td>
          <td>{{ data.userName }}</td>
          <td>{{ data.basicSalary }}</td>
          <td>{{ data.deduction }}</td>
          <td>{{ data.commentsForSalary }}</td>
         
        </tr>
      </tbody> -->
    </table>
  </div>
  