import { Component, OnInit, Input } from "@angular/core";
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MainService } from "src/app/MyService/main.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
@Component({
  selector: 'app-hrattendance',
  templateUrl: './hrattendance.component.html',
  styleUrls: ['./hrattendance.component.css']
})
export class HRAttendanceComponent implements OnInit
{
  user_id:any;
  users: Users[];
  userAttendanceList: any[];
  inputDateFromString: any;
  inputDateToString: any;
  dropdownSettings: IDropdownSettings = {};
  userId: any;
  filter: any;
  start_date: any;
  end_date: any;
  myDate = new Date();
  currDate: string;
  userList: any[];
  employees: any;
  showFilterForm: boolean = false;
  pageNumber: number=0;
  totalElements: number=0;
  pageSize: number =10;
  data:any;
  isFilterApplied: boolean = false;
  constructor(private datePipe: DatePipe, private auth: MainService,private router:Router) {
    this.currDate = this.datePipe.transform(this.myDate, "dd/MM/yyyy");
  }

  ngOnInit() {

    this.auth.AllEmployeeProfileList().subscribe((res) => {
      this.employees = res;
      console.log("hello", JSON.stringify(this.employees)); 
    });
    this.userAttendance();
    // this.auth.getAllActiveUser().subscribe((data) => {
    //   this.users = data;
    // });

    this.getAgents();
  }
  userAttendance() {
    this.auth.startLoader();
    this.auth.getTodayAttendanceList(this.pageNumber,this.pageSize).subscribe(
      (res) => {
        this.data=res;
        this.userAttendanceList = this.data.content;
        this.totalElements =  this.data.totalElements;
        this.userAttendanceList.forEach((item, index) => {
          item.serialNumber = index + 1;
        });
        this.auth.stopLoader();
      },
      (error) => {
        console.error(error);
        this.auth.stopLoader();
      }
    );
  }
  addFilteredSerialNumbers(filteredData: any[]) {
    filteredData.forEach((item, index) => {
      item.serialNumber = index + 1 + (this.pageNumber * this.pageSize);
    });
  }
 
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    if (this.isFilterApplied) {
      this.AttendanceFIlter();
    } else {
      this.userAttendance();
    }
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  liveLocation(id) {
    this.router.navigate(["/admin/userGoogleMap/" + id + "/attendance"]);
  }

  userLiveLocation(id) {

    this.router.navigate(['/admin/userGoogleMap', id]);

  }


  agentIdChange(id) {
    this.userId = id;
  }

  isClickedOnAttendance = false;

  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(
      date,
      "hh:mm a                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           "
    );
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  AttendanceFIlter()
  {
    if (!this.start_date || !this.end_date) {
      Swal.fire("  Search for Filter ");
      return;
    }
    this.isFilterApplied = true;
    const userId = this.user_id ;
    this.inputDateFromString = this.auth.toTimestamp(this.start_date);
    this.inputDateToString = this.auth.toTimestamp(this.end_date);

    this.auth.getUserAttendanceList(userId,this.inputDateFromString,this.inputDateToString,this.pageNumber,this.pageSize).subscribe(
      (res: any) => {
        this.data=res;
        this.userAttendanceList = this.data.content;
        this.totalElements = this.data.totalElements
        this.addFilteredSerialNumbers(this.userAttendanceList);
        JSON.stringify(this.userAttendanceList);
       },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );

    }
}

export class Users {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  gender: string;
  dob: string;
  password: string;
  active: boolean;
  deleted: boolean;
  emailVerified: boolean;
  parentId: number;
  role: {
      role: string;
  }
  corporate: {
      corporate: string;
  }

}
