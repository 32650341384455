import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampFormat'
})
export class TimestampFormatPipe implements PipeTransform {

  transform(dateTime: number[]): string {
    const [year, month, day, hours, minutes, seconds, milliseconds] = dateTime;
    const date = new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
    const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const formattedTime = `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}:${this.padZero(date.getSeconds())}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }

  padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }


}


