import { MainService } from 'src/app/MyService/main.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { error } from 'console';
@Component({
  selector: 'app-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.css']
})
export class UserRatingComponent implements OnInit {
  @Input() maxRating = 5;
  maxRatingArr: any = [];
  @Output()
  onRating: EventEmitter<number> = new EventEmitter<number>();
  data: any;
  userId=localStorage.getItem('id');
  comment: string = '';
  specificAchievement:string = '';
  empId:number;

  categories: string[] = [
    'Attendance',
    'Job Knowledge',
    'Skills',
    'Work Quality',
    'Initiative',
    'TeamWork',
    'General Conduct',
    'Discipline'
  ];

  ratings: { [key: string]: number } = {};
  previousSelection: number;
  attendance: number;
  jobKnowledge: number;
  skills: number;
  workQuality: number;
  teamWork: number;
  initiative: number;
  generalConduct: number;

  constructor(private mainService: MainService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const empIdParam = this.route.snapshot.queryParamMap.get('id');
    this.empId = empIdParam ? parseInt(empIdParam, 10) : 0;
    this.maxRatingArr = Array(this.maxRating).fill(0);
  }


  HandelMouseEnter(category: string, index: number) {
    this.ratings[category] = index + 1;
  }


  HandelMouseLeave(category: string) {
    if (this.previousSelection !== 0) {
      this[category + 'Rating'] = this.previousSelection;
    } else {
      this[category + 'Rating'] = 0;
    }
  }


  Rating(category: string, index: number) {
    this.ratings[category] = index + 1;
    this.onRating.emit(this.ratings[category]);
  }

  submitReview() {
    const review = {
      attendance: this.ratings['Attendance'],
      jobKnowledge: this.ratings['Job Knowledge'],
      skills: this.ratings['Skills'],
      workQuality: this.ratings['Work Quality'],
      initiative: this.ratings['Initiative'],
      teamWork: this.ratings['TeamWork'],
      generalConduct: this.ratings['General Conduct'],
      discipline: this.ratings['Discipline'],
      comment: this.comment || '',
      specificAchievement: this.specificAchievement || '',
    };

    this.mainService.ratings(this.userId, this.empId, review).subscribe(
      (response) => {
        console.log('Review submitted successfully', response);
        this.resetRatings();
        Swal.fire({
          text: 'Your review has been submitted successfully!',
          
        });
        window.location.href = "#/admin/all-employee-profile";
      },
      (error)=>{
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }

    );
  }

  resetRatings() {
    for (const category of this.categories) {
      this.ratings[category] = 0;
    }
    this.comment = '';
  }

  isCategoryRatingHigher(category: string, index: number): boolean {
    return this.ratings[category] > index;
  }
}
