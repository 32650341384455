<table>
  <thead>
    <tr>
      <th>User ID</th>
      <th>Full Name</th>
      <th>Taken Amount</th>
    </tr>
  </thead>
  <tbody>
     <tr>
        <td>{{ data.userId }}</td>
        <td>{{ data.userName }} </td>
        <td>{{ data.takenAmount }}</td>
      </tr>
  </tbody>
</table>
