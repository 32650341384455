import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-comment-assignment',
  templateUrl: './admin-comment-assignment.component.html',
  styleUrls: ['./admin-comment-assignment.component.css']
})
export class AdminCommentAssignmentComponent implements OnInit {

  private routeSub: Subscription;
  userId :string;
  data : any;
  comment:string;


  constructor(private snack: MatSnackBar, private mainService : MainService,private route: ActivatedRoute,private router:Router) { }
  id :any;

  ngOnInit(): void {

    this.routeSub = this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      // this.taskd = params['taskid'];
      this.userId = params['assignid'];
      console.log("userId ::: "+this.userId);
    });


    this.mainService.gettask(this.id).subscribe(res=>{
      this.data=res;
    })

  }

  formSubmit(event) {

  let target = event.target;
  var arrObj = {};

  arrObj['id'] = this.userId;
  if (target.querySelector('#comment').value != null) {
    arrObj["comment"] = target.querySelector('#comment').value;
  }
    this.mainService.updateAssignmentbyadmin(this.id,arrObj).subscribe(
      (data: any) => {
        //success
        console.log(data);
        alert('success');
        Swal.fire('Successfully done !!',   'success');
this.router.navigate(['/admin/assignment'])

      },
      (error) => {
        //error
        console.log(error);
        // alert('' + error.error.message); // Displaying the error message in an alert
        Swal.fire('Error', ' ' + error.error.message, 'error'); // Displaying the error message using Swal (assuming Swal is a library for displaying modals)
        // You can handle the error further based on your application's requirements
      }
    );
  }
}


