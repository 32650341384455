import { Component } from '@angular/core';
import { NotificationService } from './MyService/notification.service';"firebase/messaging";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent
{
  title = 'TestYourself';

  message: any;

  constructor(private notification : NotificationService,private router: Router, private ngxLoader: NgxUiLoaderService)
  {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.ngxLoader.start();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.ngxLoader.stop();
      }
    });
  }

  ngOnInit(): void
  {
    // this.notification.requestPermission();
    // this.notification.receiveMessaging();
    // this.message = this.notification.currentMessage;
  }

}
