<div class="row" style="background: #161515;">
  <div class="col-md-6" style="background: #070707; box-shadow: 0 0 10px #958c8c;">
    <canvas id="myChart" style="width: 534px;height: 395px;color:white; "></canvas>
  </div>
  <div class="col-md-6">
    <div
      style=" display: block; box-sizing: border-box; height: 435px; border-radius: 5px; background: #070707;color:white; width: 100%;  background: #070707; padding: 20px; box-shadow: 0 0 10px #958c8c;">
      <div >
        <h3>Department</h3>
        <button class="btn" (click)="loadPieChartData('IT & Development')">IT & Development</button>
        <button class="btn" (click)="loadPieChartData('Marketing')">Digital Marketing</button>
      </div>
      <canvas id="myPieChart" style="width: 534px; height: 310px; "></canvas>
    </div>
  </div>
</div>
