import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { SidebarService } from '../../../services/sidebar.service';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  [x: string]: any;
  
  id = localStorage.getItem('id');
  hideSidebar: boolean = true;
  colMdClass: string = 'col-md-10';
  constructor(private mainService: MainService,private sidebarService: SidebarService) { }

  ngOnInit(): void {
  
    this.sidebarService.isSidebarHidden().subscribe((hidden) => {
      this.hideSidebar = hidden;
    });
  }


  
}
