<head>
  <!-- Add Bootstrap and font-awesome -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />

 
  <script src="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/5.10.0/main.min.js"></script>
</head>

<body>
  <div class="back">
    <button
      class="button1"
      style="width: 39px; margin-top: 2px; border-radius: 50%"
    >
      <a [routerLink]="['/admin']">⇦ </a>
    </button>
    <button
      class="button2"
      style="float: right; width: 39px; margin-top: 2px; border-radius: 50%"
    >
      <a class="theme-btn" onclick="history.go(-1)"> ⇨</a>
    </button>
   
    <div class="container3" style="margin-left: 500px">
      <!-- <hr class="animated-line"> -->
    </div>
  </div>
  <div class="row" style="    margin-top: 66px;">
    <div class="col-md-3">
      <div class="container">
        <button
          class="button1"
          style="margin-bottom: 10px; margin-left: -15px"
          (click)="openDialog()"
        >
          <mat-icon>add</mat-icon>Events
        </button>
      </div>
      <div class="container" id="eventsContainer">
        <div class="event-container-horizontal">
          <div
            *ngFor="let eventData of data; let i = index"
            class="event-data"
            style="border: 1px solid #cb316052"
          >
            <div class="event-number">{{ i + 1 }}.</div>
            <div class="event-delete-icon" (click)="ondelete(eventData?.id)">
              <i class="fas fa-times"></i>
            </div>
            <div class="event-title">{{ eventData?.title }}</div>
            <div class="event-datetime">
              <span>{{
                formatDateTimeRange(eventData?.start, eventData?.end)
              }}</span>
            </div>
            <div class="event-description">{{ eventData?.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container col-md-9"
      style="margin-top: 20px; margin-left: -12px"
    >
      <div id="calendar" style="pointer-events: auto">
        <full-calendar
          [options]="calendarOptions"
          (eventClick)="handleEventClick($event)"
        ></full-calendar>
      </div>
    </div>
  </div>
</body>
