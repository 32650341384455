import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient } from '@angular/common/http';
import { LoginService } from 'src/app/services/login.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user-performance',
  templateUrl: './user-performance.component.html',
  styleUrls: ['./user-performance.component.css']
})
export class UserPerformanceComponent implements OnInit {
  data: any = null;
  user: any[] = [];
  totalWorkingDays: number | undefined;
  present: number | undefined;
  absent: number | undefined;
  user_id:number;
  per:any;
  employeeId:any;
  constructor(private mainService: MainService, private http: HttpClient, private login: LoginService) { }

  ngOnInit(): void {
    this.user = this.login.getUser();
    this.mainService.overAll().subscribe((res: any[]) => {
      this.data = res;
      JSON.stringify(this.data)
    });
  }
  formatDateWithAbbreviatedMonth(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const formattedMonth = formatDate(date, 'MMM', 'en-US');
    const formattedDate = formatDate(date, 'dd -', 'en-US');
    const formattedYear = formatDate(date, 'yyyy HH:mm:ss', 'en-US');
    return `${formattedDate} ${formattedMonth} ${formattedYear}`;
  }
  isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
  
}
