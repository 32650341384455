import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
@Component({
  selector: 'app-employee-attendance',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.css']
})
export class EmployeeAttendanceComponent implements OnInit {

  constructor(private http: HttpClient, private mainService : MainService) { }
  id = localStorage.getItem('id')
  phone = localStorage.getItem('phone')
  data : any;
  ngOnInit(): void {
    console.log("heyy   "+this.id)
    // this.mainService.showAllAttendance().subscribe(res=>{
    //   this.data=res;
    //   JSON.stringify(this.data)
    //   console.log("Attendance Data : "+this.data)
    // })
  }

}









