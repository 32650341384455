<h4> Add & Assign Task</h4>

  <mat-card-content>
        <div class="meetingContainer">
          <form (ngSubmit)="formSubmit($event)">
           
            <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)">
              <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Task Files</ngx-dropzone-label>
              <ngx-dropzone-image-preview 
                ngProjectAs="ngx-dropzone-preview" 
                *ngIf="selectedFile" 
                [file]="selectedFile" 
                [removable]="true" 
                (removed)="onRemove()">
                <ngx-dropzone-label>{{ selectedFile.name }} ({{ selectedFile.type }})</ngx-dropzone-label>
              </ngx-dropzone-image-preview>
            </div>  
            <label for="userIds" style="color: white;">Select Employee:</label>
            <div class="form-field" style="background: rgb(49, 48, 48);">
              <select id="userIds" name="employeeSelector" style="background-color: #2d2d2d;    border-radius: 6px; color: white;width: 100%; height: 39px; padding: 8px" [(ngModel)]="user_id">
                <option *ngFor="let employee of data" [value]="employee.id">
                  {{ employee.userName }}
                </option>
              </select>
            </div>
            <label for="deadline" >Deadline Date</label>
            <input type="date" id="deadline" name="deadline" [(ngModel)]="user.deadDate" required>
            <label for="deadline">DeadLine Time</label>
            <input type="time" id="deadline" name="deadline" [(ngModel)]="user.deadTime" required>
            <mat-form-field class="w100" appearance="outline" >
              <mat-label style="color: white;">Task</mat-label>
              <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="color: white;" rows="8"></textarea>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
    
   
  </mat-card-content>
