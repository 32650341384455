import { Component, OnInit, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ShowEmailPopupComponent } from '../show-email-popup/show-email-popup.component';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  emailDetails: any[] = [];
  startDate: string = '';
  endDate: string = '';
  userId: string = '';
  users: any[] = [];
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;
  selectedEmailDetail: any;
  showCcBccPopup: boolean = false;

  constructor(
    private _snack: MatSnackBar,
    private mainService: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
    this.fetchData();
  }

  fetchUsers(): void {
    this.mainService.AllEmployeeProfileList().subscribe((res: any) => {
      this.users = res;
      console.log(JSON.stringify(this.users));
    });
  }

  fetchData(): void {
    const formattedStartDate = this.startDate ? this.toTimestamp(this.startDate) : '';
    const formattedEndDate = this.endDate ? this.toTimestamp(this.endDate) : '';
    this.mainService.getAllEmail(this.userId, formattedStartDate, formattedEndDate, this.pageNumber, this.pageSize).subscribe((res: any) => {
      if (this.pageNumber === 0) {
        this.emailDetails = res.content;
      } else {
        this.emailDetails = [...this.emailDetails, ...res.content];
      }
      this.totalElements = res.totalElements;
    });
  }

  toTimestamp(dateString: string): number {
    return new Date(dateString).getTime();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.body.scrollHeight;

    if (scrollPosition >= threshold && this.emailDetails.length < this.totalElements) {
      this.pageNumber++;
      this.fetchData();
    }
  }

  applyFilters(): void {
    this.pageNumber = 0;
    this.fetchData();
  }

  showEmailDetails(emailId: string): void {
    this.selectedEmailDetail = this.emailDetails.find((email: any) => email.id === emailId);
  }

  toggleCcBccPopup(event: MouseEvent): void {
    this.showCcBccPopup = !this.showCcBccPopup;
    event.stopPropagation();
  }

  closeCcBccPopup(): void {
    this.showCcBccPopup = false;
  }

  closeMessage(): void {
    this.selectedEmailDetail = null;
    this.showCcBccPopup = false;
  }

  openEmailPopup(): void {
    this.dialog.open(ShowEmailPopupComponent, {
      width: '800px', // Set the width of the popup
    });
  }
}
