<mat-dialog-content>
    <div class="modal-overlay" (click)="closeModal()"></div>
    <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <div class="popup-content">
            <h4><span class="status {{ data.status.toLowerCase() }}">{{ data.userName }}</span></h4>
            <p>
                <!-- <span><mat-icon>date_range</mat-icon>{{ data.createdDate | date: 'MMM d, y' }}</span> 
                <span class="separator"><mat-icon>access_time</mat-icon></span> 
                <span>{{ data.createdDate | date: 'hh:mm a' }}</span> Display time with icon -->
              </p>  
          
             
            <h3 style="font: 600;">Leave Type :<span>{{ getLeaveTypeText( data.leaveType )}}</span></h3>
            <h3 style="font: 600; font-weight: 700;">Reason:</h3>
            <h3 style="font-size: 12px; font-family: sans-serif;">{{ data.reason }}</h3>
            <!-- <h4>Status: <span class="status {{ data.status.toLowerCase() }}">{{ data.status }}</span></h4> -->
        </div>
    </div>
</mat-dialog-content>
