<h4>Feedback</h4>
<form (ngSubmit)="submitFeedback()">
  <label for="section">Section:</label>
  <select id="section" name="section" [(ngModel)]="feedbackData.section" required>
    <option value="" disabled selected>Select a Section</option>
    <option value="task">Task</option>
    <option value="attendance">Attendance</option>
    <option value="assignment">Assignment</option>
    <option value="salary">Salary</option>
    <option value="review">Review</option>
    <option value="uploadDocument">Upload Document</option>
    <option value="other">Other</option>
  </select>
  <label for="feedback">Feedback:</label>
  <textarea id="feedback" name="feedback" [(ngModel)]="feedbackData.feedback" required rows="10"></textarea>
  <button  mat-raised-button color="accent" type="submit" > Submit</button>
</form>
