import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-add-task',
  templateUrl: './admin-add-task.component.html',
  styleUrls: ['./admin-add-task.component.css','/../../../shared/all-style/add-meeting-style.component.css']
})
export class AdminAddTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  user_id:any
  isSubmitting = false;
  selectedFile: File | null = null;
  user = {
    date: '',
    time: '',
    subject: '',
    fileUrl: '',
    deadDate:'',
    deadTime:'',
  };
data:any;
  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.AllEmployeeProfileList().subscribe((res: any[]) => {
      this.data = res;
    });
  }

  onSelect(event: any): void {
    const maxFileSize = 300 * 1024; // 300 KB
    const fileToAdd = event.addedFiles[0];

    if (event.addedFiles.length > 1) {
      Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
      return;
    }
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    // if (fileToAdd.type === 'application/pdf') {
    //   Swal.fire('Error', 'You cannot upload PDF files.', 'error');
    //   return;
    // }
    this.selectedFile = fileToAdd;
  }

  onRemove(): void {
    this.selectedFile = null;
  }

  formSubmit(event: Event): void {
    event.preventDefault();
    this.isSubmitting = true;

    if (!this.user.deadDate || !this.user.deadTime) {
      Swal.fire('Error', 'Please fill in both the deadline date and time.', 'error');
      this.isSubmitting = false;
      return;
    }

    // Merge date and time into a single timestamp
    const deadlineTimestamp = new Date(`${this.user.deadDate}T${this.user.deadTime}`).getTime();

    if (this.selectedFile) {
      this.uploadFile(deadlineTimestamp);
    } else {
      if (this.user_id) {
        this.assignTask('', deadlineTimestamp); // Assign task if employee is selected
      } else {
        this.addTask('', deadlineTimestamp); // Add task without assignment
      }
    }
  }

  uploadFile(deadlineTimestamp: number): void {
    const folderName = 'taskDocs';
    const data = new FormData();
    data.append('file', this.selectedFile as Blob);
  
    this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
      (response: any) => {
        const filePath = response.message;
        
        // Check if employee is selected
        if (this.user_id) {
          this.assignTask(filePath, deadlineTimestamp); // Assign task if employee is selected
        } else {
          this.addTask(filePath, deadlineTimestamp); // Add task without assignment
        }
      },
      (error) => {
        console.error('File upload failed:', error);
        Swal.fire('Error', 'File upload failed.', 'error');
        this.isSubmitting = false;
      }
    );
  }

  addTask(filePath: string, deadlineTimestamp: number): void {
    const taskData = {
      date: this.user.date,
      time: this.user.time,
      subject: this.user.subject,
      fileUrl: filePath,
      deadLine: deadlineTimestamp
    };

    this.mainService.addtask(this.id, taskData).subscribe(
      (response) => {
        console.log("API response:", response);
        Swal.fire('Success', 'Task added successfully', 'success');
        this.router.navigate(['/admin/showuser-task']);
        this.isSubmitting = false;
      },
      (error) => {
        console.error(error);
        Swal.fire('Error', 'Failed to add task.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  assignTask(filePath:string,deadlineTimestamp:number):void{
    const assignData ={
      id:this.user_id,
      subject: this.user.subject,
      fileUrl: filePath,
      deadLine: deadlineTimestamp
    }
    this.mainService.assignTask(this.user_id,assignData).subscribe((response) => {
      console.log("API response:", response);
      Swal.fire('Success', 'Task Assign successfully', 'success');
      this.router.navigate(['/admin/showuser-task']);
      this.isSubmitting = false;
    },
    (error) => {
      console.error(error);
      Swal.fire('Error', 'Failed to add task.', 'error');
      this.isSubmitting = false;
    }
  );
  }
}
