import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
// import { UserAnnouncementComponent } from '../user-announcement/user-announcement.component';
import { Renderer2 } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-user-welcome',
  templateUrl: './user-welcome.component.html',
  styleUrls: ['./user-welcome.component.css']
})
export class UserWelcomeComponent implements OnInit {
  totalPendingTasks: number = 0;
  todayMeetingCount: number = 0;
  userAllRequest: number = 0;
  userMonthlyAttendance: number = 0;
  id = localStorage.getItem('id');
  modalVisible: boolean = false;
  refreshToken: string;
  @ViewChild('button') button: ElementRef;
  status: number;
  startDate: number;
  endDate: string;
  pageNumber: any;
  pageSize: any;
  month:any;
  taskCount:any;
  attendanceCount:any;
  projectdetails:any;
  @ViewChild('myChart', { static: true }) chartRef!: ElementRef<HTMLCanvasElement>;
  perform: any = {
    attendanceRatio: 0,
    taskCompletionRatio: 0,
    completedTasks: 0,
    pendingTasks: 0,
    totalTasks: 0,
    averagePunchInTime:0
  };
  constructor(
    private mainService: MainService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private login: LoginService
  ) {}

  ngOnInit(): void {
    this.refreshToken = this.login.getToken();
    this.login.refreshToken(this.refreshToken).subscribe(
      (res: any) => {
        this.login = res;
        console.log("refreshtoken: ", this.refreshToken);
      },
      error => {
        console.error("Error refreshing token:", error);
      }
    );
    this.renderChart();
    setTimeout(() => {
      this.openModal();
    }, 1000);
    this.mainService.performance(this.id,this.month).subscribe((res:any)=>{
      this.perform=res;
      console.log(JSON.stringify(this.perform));
    })
   this.Attendance();
   this.TaskCount();
   this.getProjectDetails();
  }
  Attendance(){
    this.mainService.mothlyAttendanceCount(this.id).subscribe((res:any)=>{
      this.attendanceCount=res;
    })
  }
  getProjectDetails(){
    this.mainService.getAllAssigmentbyUser(this.id,this.status,this.startDate,this.endDate,this.pageNumber,this.pageSize).subscribe((project:any)=>{
      this.projectdetails=project.content;
      console.log("res"+ JSON.stringify(project));
    })
  }
  TaskCount(){
    this.mainService.taskpending(this.id).subscribe((res:any)=>{
      this.taskCount=res;
    })
  }
  renderChart(): void {
    const attendanceChartCanvas = this.chartRef.nativeElement;
    new Chart(attendanceChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['Pending', 'Done'],
        datasets: [{
          label: 'Task Stats',
          data: [this.perform.pendingTasks, this.perform.completedTasks],
          backgroundColor: ['#63a0ff', '#dc3545'],
          hoverOffset: 4
        }]
      },
      options: {
        cutout: '70%',
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
  isNaN(value: any): boolean {
    return isNaN(value);
  }
  openModal() {
    this.modalVisible = true;
  }

  // openDialog() {
  //   this.dialog.open(UserAnnouncementComponent);
  // }
}
