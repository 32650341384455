import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-all-candidate',
  templateUrl: './all-candidate.component.html',
  styleUrls: ['./all-candidate.component.css'],
})
export class AllCandidateComponent implements OnInit {
  id = localStorage.getItem('id');
  startDate: string = '';
  endDate: string = '';
  data: any;
  inputDateFromString: any;
  inputDateToString: any;
  showFilterForm: boolean = false;
  constructor( private http: HttpClient, private mainService: MainService, private router: Router, private auth: MainService) {}

  ngOnInit(): void {
    this.mainService.showCandidate().subscribe((res) => {
      this.data = res;
      JSON.stringify(this.data);
    });
  }
  candidateFilter() {
    if (this.startDate && this.endDate) {
      this.inputDateFromString = this.auth.toTimestamp(this.startDate);
      this.inputDateToString = this.auth.toTimestamp(this.endDate);
      this.mainService
        .findByDateCandidate(this.inputDateFromString, this.inputDateToString)
        .subscribe((res) => {
          this.data = res;
          console.log("green"+ JSON.stringify(this.data))
        });
    } else {
      console.log('Please select both start date and end date.');
    }
  }

  ondelete(id) {
    if (confirm('Are you sure want to delete ')) {
      this.mainService.deletecandidate(id).subscribe((res) => {
        this.router.navigate(['admin/all-candidate']);
        Swal.fire("User Deleted Successfully");
        window.location.reload();
      }),
        (error) => {
          console.log(error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        };
    }
  }
  navigateToAddUserProfile() {
    this.router.navigate(['admin/add-candidate']);
  }
}
