import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-modal',
  templateUrl: './leave-modal.component.html',
  styleUrls: ['./leave-modal.component.css']
})
export class LeaveModalComponent {
  constructor(
    private dialogRef: MatDialogRef<LeaveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {
    this.data.createdDate = new Date(this.data.createdDate);
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  getLeaveTypeText(leaveType: string): string {
    switch (leaveType) {
      case 'HALF_DAY':
        return 'Half Leave';
      case 'ABSENT':
        return 'Absent';
      case 'SHORT_LEAVE':
        return 'Short Leave';
      case 'PAID_LEAVE':
        return 'Paid Leave';
      default:
        return leaveType;
    }
  }
}
