<div class="container-10" style="width: 100%">
  <!-- <button 
          class="button1"
          style="margin-bottom: 10px; margin-left: -15px;    display: none;"
          (click)="openDialog()"
        >
          Events
    </button> -->
  <div
    class="row"
    style="
      position: relative;
      margin-top: 20px;
      z-index: 1;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 0px;
    "
  >
    <div class="col-md-2">
      <a [routerLink]="['/admin/HrAttendance']"> <div
        class="card"
        style="
          background: var(--cui-primary, #1f1498);
          background-repeat: no-repeat;
          background-position: bottom;
          width: 198px;
          height: 127px;
          box-shadow: 0 0 12px #1f1498;
          ">
      <div class="card-content">
          <mat-icon style="background: #574cd1">person</mat-icon>
          <h2>{{ totalUserPresent }}</h2>
          <p> User Availability </p>
        </div>
      </div>
      </a>
    </div>

    <div class="col-md-2">
      <a [routerLink]="['/admin/showuser-task']"> <div
        class="card"
        style="
          background: #4591da;
          background-repeat: no-repeat;
          background-position: bottom;
          width: 198px;
          height: 127px;
          box-shadow: 0 0 12px #4591da;
        "
      >
        <div>
          <mat-icon style="background-color: #2f7ac2">task</mat-icon>
          <h2>{{ totalPendingTasks }}</h2>
        </div>
        <p> Pending Tasks</p>
      </div>
      </a>
    </div>

    <div class="col-md-2">
      <a [routerLink]="['/admin/show-meetings']"> <div
        class="card"
        style="
          background: #1c7a16;
          background-repeat: no-repeat;
          background-position: bottom;
          width: 198px;
          height: 127px;
          box-shadow: 0 0 12px #1c7a16;
        "
      >
        <div>
          <mat-icon mat-list-icon style="background-color: #edf4ec36"
            >voice_chat</mat-icon
          >
          <h2>{{ todayMeetingCount }}</h2>
        </div>
        <p> Meetings Aligned</p>
      </div></a>
    </div>

    <div class="col-md-2">
      <a [routerLink]="['/admin/all-candidate']"> <div
        class="card"
        style="
          background: #c75858;
          background-repeat: no-repeat;
          background-position: bottom;
          width: 198px;
          height: 127px;
          box-shadow: 0 0 12px #c75858;
        "
      >
        <div>
          <mat-icon mat-list-icon style="background-color: #da7878"
            >person_add</mat-icon
          >
          <h2>{{ interviewScheduled }}</h2>
          <p style="width: 163px;">Scheduled Interviews</p>
        </div>
      </div></a>
    </div>

    <div class="col-md-2">
      <a [routerLink]="['/admin/showLeave-Request']">  <div
        class="card"
        style="
          background: #3f9f6b;
          background-repeat: no-repeat;
          background-position: bottom;
          width: 198px;
          height: 127px;
          box-shadow: 0 0 12px #3f9f6b;
        "
      >
        <div>
          <mat-icon mat-list-icon style="background-color: #44b678"
            >accessibility_new</mat-icon
          >
          <h2>{{ getPendingLeaves }}</h2>
        </div>
        <p> Day Off Applications
        </p>
      </div>
      </a>
    </div>
  </div>
</div>

<app-event-chart></app-event-chart>

<div class="row" style="position: relative; margin-top: 20px; z-index: 1;">
  <div class="col-md-12 body flex-grow-1 px-3">
    <div class="row">
      <div class="col-md-3">
        <div class="card" style="box-shadow: 0 0 10px #958c8c;">
          <div class="row">
            <a
              href="https://www.facebook.com/InvestMango"
              target="_blank"
              style="width: 100%;"
              ><div
                class="card-header"
                style="background: #4591da; "
              >
                <img
                  src="../../../../assets/facebook-5-xxl.png"
                  Alt="Facebook Icon"
                  style="height: 30px; "
                />
              </div>
            </a>
          </div>

          <div class="card-body">
            <div class="row">
              <!-- First col-md-6 - Past Data -->
              <div class="col-md-4" style=" background: #2d2d2d;text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followers</h2>
                <p class="text-muted" style="color: white; text-align: center">
                  <span>{{latestData.fbFollowers}} </span><br>

                </p>
              </div>

              <!-- Second col-md-6 - Latest Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followings</h2>
                <p class="text-muted" style="color: white; text-align: center">


                  <span>{{latestData.fbFollowings}}</span><br>

                </p>
              </div>
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Posts</h2>
                <p class="text-muted" style="color: white; text-align: center">

                  <span>{{latestData.fbPosts}}</span>
                           </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="card" style="box-shadow: 0 0 10px #958c8c;">
          <div class="row">
            <a
              href="https://www.instagram.com/investmango/"
              target="_blank"
              style="width: 100%"
              ><div
                class="card-header"
                style="text-align: center;
                  background: #f09433;
                  background: -moz-linear-gradient(
                    45deg,
                    #f09433 0%,
                    #e6683c 25%,
                    #dc2743 50%,
                    #cc2366 75%,
                    #bc1888 100%
                  );
                  background: -webkit-linear-gradient(
                    45deg,
                    #f09433 0%,
                    #e6683c 25%,
                    #dc2743 50%,
                    #cc2366 75%,
                    #bc1888 100%
                  );
                  background: linear-gradient(
                    45deg,
                    #f09433 0%,
                    #e6683c 25%,
                    #dc2743 50%,
                    #cc2366 75%,
                    #bc1888 100%
                  );
                  width: 100%;
                "
              >
                <img
                  src="../../../../assets/instagram.png"
                  alt="Instagram Icon "
                  style="height: 30px; "
                /></div
            ></a>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- First col-md-6 - Past Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followers</h2>
                <p class="text-muted" style="color: white;">
                  <span>{{latestData.instaFollowers}} </span><br>

                </p>
              </div>

              <!-- Second col-md-6 - Latest Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followings</h2>
                <p class="text-muted" style="color: white; ">


                  <span>{{latestData.instaFollowings}}</span><br>

                </p>
              </div>
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Posts</h2>
                <p class="text-muted" style="color: white; text-align: center">

                  <span>{{latestData.instaPosts}}</span>
                           </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card" style="background: #2d2d2d; box-shadow: 0 0 10px #958c8c;">
          <div class="row">
            <a
              href="https://www.linkedin.com/company/investmango/mycompany/"
              target="_blank"
              style="width: 100%"
            >
              <div class="card-header" style="background: #405de6; width: 100%;text-align: center;">
                <img
                  src="../../../../assets/linkedin-5-xxl.png"
                  Alt="Linkedin Icon"
                  style="height: 30px;"
                /></div
            ></a>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- First col-md-6 - Past Data -->
              <div class="col-md-4" style="background: #2d2d2d ; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followers</h2>
                <p class="text-muted" style="color: white; text-align: center">
                  <span>{{latestData.linkedinFollowers}} </span><br>

                </p>
              </div>

              <!-- Second col-md-6 - Latest Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followings</h2>
                <p class="text-muted" style="color: white; text-align: center">


                  <span>{{latestData.linkedinFollowings}}</span><br>

                </p>
              </div>
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Posts</h2>
                <p class="text-muted" style="color: white; text-align: center">

                  <span>{{latestData.linkedInPosts}}</span>
                           </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card" style="box-shadow: 0 0 10px #958c8c;">
          <div class="row">
            <a
              href="https://www.youtube.com/@InvestMango"
              target="_blank"
              style="width: 100%"
            >
              <div class="card-header" style="background: #ff0000; width: 100%;text-align: center;padding: 4px;">
                <img
                  src="../../../../assets/youtubeicon.png"
                  alt="Youtube-icon"
                  style="height: 38px;    width: 79px;"
                /></div
            ></a>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- First col-md-6 - Past Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followers</h2>
                <p class="text-muted" style="color: white; text-align: center">
                  <span>{{latestData.youtubeFollowers}} </span><br>

                </p>
              </div>

              <!-- Second col-md-6 - Latest Data -->
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Followings</h2>
                <p class="text-muted" style="color: white; text-align: center">


                  <span>{{latestData.youtubeFollowings}}</span><br>

                </p>
              </div>
              <div class="col-md-4" style="background: #2d2d2d; text-align: center">
                <h2 style="margin-top: 4px; color: white;margin-bottom: 4px;font-size: 16px;">Posts</h2>
                <p class="text-muted" style="color: white; text-align: center">

                  <span>{{latestData.youtubePosts}}</span>
                           </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-user-performance></app-user-performance>
