import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
// import { AuthService } from '../path/to/auth.service';
@Component({
  selector: 'app-update-salary',
  templateUrl: './update-salary.component.html',
  styleUrls: ['./update-salary.component.css']
})
export class UpdateSalaryComponent implements OnInit {
  userId :string;
  data : any;
  // user:any;
  constructor(private http: HttpClient, private mainService : MainService,private route: ActivatedRoute,private router:Router) { }
  id = localStorage.getItem('id')
  phone = localStorage.getItem('phone')

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
    console.log("-------+ userId= "+this.userId)

    this.mainService.showuserSalary(this.userId).subscribe(
      (res )=>{
        this.data = res;

        JSON.stringify(this.data)
  }
  );
}
updateUser(event) {
  let target = event.target;
  var arrObj = {};

  arrObj['userId'] = this.userId;
 
  if (target.querySelector('#hra').value != null) {
    arrObj["hra"] = target.querySelector('#hra').value;
  } else {
    arrObj["hra"] = null;
  }
  if (target.querySelector('#medicalFund').value != null) {
    arrObj["medicalFund"] = target.querySelector('#medicalFund').value;
  }
  if (target.querySelector('#convience').value != null) {
    arrObj["convience"] = target.querySelector('#convience').value;
  }
  if (target.querySelector('#bonus').value != null) {
    arrObj["bonus"] = target.querySelector('#bonus').value;
  }
  if (target.querySelector('#basicSalary').value != null) {
    arrObj["basicSalary"] = target.querySelector('#basicSalary').value;
  }
  if (target.querySelector('#incentive').value != null) {
    arrObj["incentive"] = target.querySelector('#incentive').value;
  }

  console.log("---------- "+JSON.stringify(arrObj))
  this.mainService.updateuser(this.userId,arrObj).subscribe(
    (res :HttpResponse<any>)=>{
      this.mainService.showuserSalary(this.userId).subscribe(res=>{
      })
      JSON.stringify(this.data)
      Swal.fire("Successfully updated.")

      this.router.navigate(['/admin/show-employee-salary'])
    },
    (error) => {
      //error
      console.log(error);
      // alert('' + error.error.message);
      Swal.fire('Error', ' ' + error.error.message, 'error');
    }
  );


}
}



