import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-user-meeting',
  templateUrl: './user-meeting.component.html',
  styleUrls: ['./user-meeting.component.css','/../../../shared/all-style/all-style.component.css']
}) 
export class UserMeetingComponent implements OnInit {
  categories: any[] = [];
  data: any;
  purpose: any;
  userId: string;
  id = localStorage.getItem('id');
  filteredData: any[] = [];
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  status: string;
  startDate: any;
  endDate: any;
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  togglePresence(data: any,user:any) {
    const meetingId = data.id;
    const updatedIsPresent = !user.isUserPresent;
    this.mainService.updatePresence(meetingId, updatedIsPresent).subscribe(
      response => {
        Swal.fire('Presence updated successfully', response.message, 'success');
        user.isUserPresent = updatedIsPresent; 
        console.log("helloooooooooooooooooooo"+data.isUserPresent)
      },
      error => {
        Swal.fire('Error', error.error.message || 'An error occurred while updating presence.', 'error');
      }
    );
  }
 
  fetchData(): void {
    this.mainService.usergetmeetingbyid(this.id, this.pageNumber, this.pageSize,this.status,this.startDate,this.endDate).subscribe((res: any) => {
      this.data = res;
      this.filteredData = this.data.content;
      this.totalElements = this.data.totalElements;
      console.log("hello"+JSON.stringify(this.data));
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }
  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  filterTable(): void {
    const filterValue = (<HTMLInputElement>document.getElementById('filterInput')).value;
    if (!filterValue) {
      Swal.fire({
        title: 'Empty Search',
        text: 'Please enter a date to perform the search.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }
    const filterDateParts = filterValue.split('-');
    const filterDate = `${filterDateParts[2]}/${filterDateParts[1]}/${filterDateParts[0]}`;
    this.filteredData = this.data.filter((data) => data.date === filterDate);
  }
 
}
