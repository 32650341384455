import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css','/../../../shared/all-style/all-style.component.css']
})
export class AttendanceComponent implements OnInit {
  user_id = localStorage.getItem('id');
  users: Users[];
  userAttendanceList: any[];
  inputDateFromString: any;
  inputDateToString: any;
  filter: any;
  data: any;
  startDate: string;
  endDate: string; 
  myDate = new Date();
  currDate: string;
  userList: any[];
  filteredData: any[] = [];
  showFilterForm: boolean = false;
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  user:any;
  userId:string = '';
  selectedEmployee: string;
  constructor(private datePipe: DatePipe, private auth: MainService, private router: Router) {
    this.currDate = this.datePipe.transform(this.myDate, "dd-MM-yyyy");
  }

  ngOnInit() {
    this.fetchData();
this.auth.managerAlllist(this.user_id,true).subscribe((res:any)=>{
this.user=res;
this.addFilteredSerialNumbers(this.userAttendanceList);
})
   
}


  onEmployeeSelect(event: any) {
    const selectedEmployeeId = event.target.value;
    this.auth.showuserbyId(selectedEmployeeId).subscribe((res: any) => {
      this.data = res;
    });
  }

  fetchData() {
    this.auth.getattendancewithuser1(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      JSON.stringify(this.data);
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;

      this.addFilteredSerialNumbers(this.userAttendanceList);
      // this.userAttendance();
     
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  filterByWeek(): void {
    this.auth.weeklyAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  filterByMonth(): void {
    this.auth.monthAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  filterByQuarter(): void {
    this.auth.quarterAttendance(this.user_id, this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.userAttendanceList = this.data.content;
      this.totalElements = this.data.totalElements;
      this.addFilteredSerialNumbers(this.userAttendanceList);
    });
  }

  getTimeAsMinutes(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }

  addFilteredSerialNumbers(data: any[]) {
    data.forEach((item, index) => {
      item.serialNumber = index + 1+ (this.pageNumber * this.pageSize);
    });
  }
  liveLocation(id) {
    this.router.navigate(["/manager-dashboard/google-map/" + id + "/" + "attendance"]);
  }

  userLiveLocation(id) {
    this.router.navigate(["/manager-dashboard/google-map/" + id]);
  }

  agentIdChange(id) {
    this.user_id = id;
  }

  isClickedOnAttendance = false;

  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(
      date,
      "hh:mm a"
    );
  }

  attendanceFilter() {
    const userId = this.selectedEmployee || this.user_id;
    this.inputDateFromString = this.auth.toTimestamp(this.startDate);
    this.inputDateToString = this.auth.toTimestamp(this.endDate);

    this.auth.getUserAttendanceList(userId, this.inputDateFromString, this.inputDateToString, this.pageNumber, this.pageSize).subscribe(
      (res: any) => {
        this.data = res;
        this.userAttendanceList = this.data.content;
        this.totalElements=this.data.totalElements;
        this.addFilteredSerialNumbers(this.userAttendanceList);
        JSON.stringify(this.userAttendanceList);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }

  clearFilters() {
    this.startDate = '';
    this.endDate = '';
    this.selectedEmployee='';
    this.fetchData();
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  navigateToAddUserAttendance() {
    this.router.navigate(['/manager-dashboard/mark-attendances']);
  }
}

export class Users {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  gender: string;
  dob: string;
  password: string;
  active: boolean;
  deleted: boolean;
  emailVerified: boolean;
  parentId: number;
  role: {
    role: string;
  };
  corporate: {
    corporate: string;
  };
}
