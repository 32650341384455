<h4> Add Employee Salary</h4>
  <mat-card-content style="margin-top:40px">
  <div class="bootstrap-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <form (ngSubmit)="formSubmit()">
            <table class="table text-center">
              <tr>
                <td>Basic Salary</td>
                <td><input type="number"  placeholder="Enter Basic Salary" name="basicSalary"  [(ngModel)]="user.basicSalary"/></td>
              </tr>
              <tr>
                <td>House Rent Allowance</td>
                <td><input type="number"  placeholder="Enter House Rent Allowance"   name="hra" [(ngModel)]="user.hra"/></td>
              </tr>
              <tr>
                <td>Medicalfund</td>
                <td><input type="number" placeholder="Enter Medicalfund" name="medicalFund" [(ngModel)]="user.medicalFund" /></td>
              </tr>
              <tr>
                <td>Annual Bonus</td>
                <td><input type="number"  placeholder="Enter Bonus" name="bonus" [(ngModel)]="user.bonus" /></td>
              </tr>
              <tr>
                <td>Convience</td>
                <td><input type="number" placeholder="Enter Convience"  name="convience" [(ngModel)]="user.convience" /> </td>
              </tr>
              <tr>
                <td>Incentive</td>
                <td><input type="number" placeholder="Enter Incentive"  name="incentive" [(ngModel)]="user.incentive" /> </td>
              </tr>
            </table>
            <div class="container text-center mt15">
            <button  type="submit" mat-raised-button color="accent" style="margin-top: 30px;">Submit</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-card-content>

