import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-all-employee-profile',
  templateUrl: './all-employee-profile.component.html',
  styleUrls: ['./all-employee-profile.component.css'],
})
export class AllEmployeeProfileComponent implements OnInit {

  isDropdownOpen: boolean = false;
  showAdditionalOptions = false;
  pageNumber: number=0;
  pageSize: number=10;
  totalElements: number=0;
  employeeDetails: any;
  authorities: any= { authority: ''}; 
  storeAuthority:any;
  constructor(private http: HttpClient,private mainService: MainService,private router: Router,private sanitizer: DomSanitizer) {}
  id: string;
  date: string;
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  username: string;
  address: string;
  p_address: string;
  email: string;
  department: string;
  designation: string;
  data: any;
  filterList = {
    id: [],
    joiningDate: [],
    firstName: [],
    lastName: [],
    dob: [],
    AlternativePhone: [],
    Phone: [],
    CurrentAddress: [],
    PermanentAddress: [],
    Email: [],
    Department: [],
    Designation: [],
  };
  lists:any;
  role:string='MANAGER';
  isEnabled: boolean = true;
  ngOnInit(): void {
    this.mainService.AllEmployeeProfileListUsingRole(this.role,this.isEnabled).subscribe((res:any)=>{
      this.lists = res;
      console.log("istsID"+JSON.stringify(this.lists));
    })
    this.fetchData();
   
  }
  fetchData(): void {
    this.mainService.AllEmployeeProfile(this.pageNumber, this.pageSize).subscribe((res) => {
      this.data = res;
      this.employeeDetails = this.data.content;
      // this.storeAuthority = this.employeeDetails.authorities[0];
      // this.storeAuthority = this.employeeDetails.authorities[0]?.authority || '';
      this.totalElements = this.data.totalElements;
      console.log("checking"+ JSON.stringify(res));
      // this.data.forEach((data: any) => {
      //   data.showAdditionalOptions = false;
      // });

      this.filterTable();
      this.extractAuthorities();
    });
    
  }

  extractAuthorities(): void {
    const authoritiesSet = new Set();
    this.data.content.forEach((employee: any) => {
      employee.authorities.forEach((auth: any) => {
        authoritiesSet.add(auth.authority);
      });
    });
    this.authorities = Array.from(authoritiesSet);
    console.log('Authorities:', this.authorities);
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    console.log("pageIndex"+this.pageNumber)
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  filterTable1() {
    this.data.forEach((employee: any) => {
      if (!this.filterList.id.includes(employee.id)) {
        this.filterList.id.push(employee.id);
      }
      if (!this.filterList.joiningDate.includes(employee.date)) {
        this.filterList.joiningDate.push(employee.date);
      }
      if (!this.filterList.firstName.includes(employee.firstName)) {
        this.filterList.firstName.push(employee.firstName);
      }
      if (!this.filterList.lastName.includes(employee.lastName)) {
        this.filterList.lastName.push(employee.lastName);
      }
      if (!this.filterList.dob.includes(employee.dob)) {
        this.filterList.dob.push(employee.dob);
      }
      if (!this.filterList.AlternativePhone.includes(employee.phone)) {
        this.filterList.AlternativePhone.push(employee.phone);
      }
      if (!this.filterList.Phone.includes(employee.username)) {
        this.filterList.Phone.push(employee.username);
      }
      if (!this.filterList.CurrentAddress.includes(employee.address)) {
        this.filterList.CurrentAddress.push(employee.address);
      }
      if (!this.filterList.PermanentAddress.includes(employee.p_address)) {
        this.filterList.PermanentAddress.push(employee.p_address);
      }
      if (!this.filterList.Email.includes(employee.email)) {
        this.filterList.Email.push(employee.email);
      }
      if (!this.filterList.Department.includes(employee.department)) {
        this.filterList.Department.push(employee.department);
      }
      if (!this.filterList.Designation.includes(employee.designation)) {
        this.filterList.Designation.push(employee.designation);
      }
    });
  }

  assignManagerToUser(managerId: string, userId: string) {
    this.mainService.updateAssignUser(managerId, userId).subscribe(
      (response) => {
        console.log('Assignment successful', response);
        Swal.fire('Assigned!', 'The manager has been assigned successfully.', 'success');
      },
      (error) => {
        console.log('Assignment failed', error);
        Swal.fire('Error!', 'There was an error assigning the manager.', 'error');
      }
    );
  }

   filterTable() {
    const filterValue = (document.getElementById('filterInput') as HTMLInputElement).value;
    var rows = document.querySelectorAll('.data-row');
    for (var i = 0; i < rows.length; i++) {
      var firstName = rows[i]
        .querySelector('.table-cell:nth-child(3)')
        .textContent.toLowerCase();
      var joiningDate = rows[i]
        .querySelector('.table-cell:nth-child(6)')
        .textContent.toLowerCase();
      var DataOfBirth = rows[i]
        .querySelector('.table-cell:nth-child(5)')
        .textContent.toLowerCase();
      var Designation = rows[i]
        .querySelector('.table-cell:nth-child(12)')
        .textContent.toLowerCase();
      var Department = rows[i]
        .querySelector('.table-cell:nth-child(11)')
        .textContent.toLowerCase();
      var Email = rows[i]
        .querySelector('.table-cell:nth-child(10)')
        .textContent.toLowerCase();

      var firstNameMatch = firstName.indexOf(filterValue) > -1;
      var joiningDateMatch = joiningDate.indexOf(filterValue) > -1;
      var dob = DataOfBirth.indexOf(filterValue) > -1;
      var designation = Designation.indexOf(filterValue) > -1;
      var department = Department.indexOf(filterValue) > -1;
      var email = Email.indexOf(filterValue) > -1;

      if (
        firstNameMatch ||
        joiningDateMatch ||
        dob ||
        designation ||
        department ||
        email
      ) {
        rows[i].setAttribute('style', 'display: table-row;');
      } else {
        rows[i].setAttribute('style', 'display: none;');
      }
    }
  }
  downloadpdf() {
    this.mainService.downloadpdf().subscribe((blobData: Blob) => {
      const blob = new Blob([blobData], { type: 'application/pdf' });
      saveAs(blob, 'ActiveUser.pdf');
      Swal.fire({
        title: 'Downloaded',
        text: 'The PDF has been downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      });
      (error) => {
        console.error('Pdf or Excel Not Downloaded: ', error);
      };
    });
  }
  downloadExcel() {
    this.mainService.downloadExcel().subscribe((blobData: Blob) => {
      const blob = new Blob([blobData], { type: 'application/excel' });
      saveAs(blob, 'ActiveUser.xlsx');
      Swal.fire({
        title: 'Downloaded',
        text: 'The Excel has been downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      });
      (error) => {
        console.error('Error: ', error);
      };
    });
  }
 
  // onDelete(id: any) {
  //   if (confirm('Are you sure you want to delete?')) {
  //     this.mainService.deleteemployeedetails(id, { isEnabled: false }).subscribe(
  //       (res) => {
  //         this.router.navigate(['admin/all-employee-profile']);
  //         Swal.fire("User Deleted Successfully");
  //         window.location.reload();
  //       },
  //       (error) => {
  //         console.error(error);
  //         Swal.fire("User Not Deleted Successfully", error.message, 'error');
  //          console.log("user" +error.message)
  //         // window.location.reload();
  //       }
  //     );
  //   }
    onDelete(id: any) {
      if (confirm('Are you sure you want to delete?')) {
        this.mainService.deleteemployeedetails(id, false).subscribe(
          (res) => {
            this.router.navigate(['admin/all-employee-profile']);
            // console.log(JSON.stringify(res))
            Swal.fire("User Deleted Successfully");
            window.location.reload();
          },
          (error) => {
            // console.error(error);
            Swal.fire("User Deleted Successfully");
            window.location.reload();
          }
        );
      }
    }
  }
  
  
  

