import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { UpdateTaskComponent } from '../update-task/update-task.component';
import { LoginService } from 'src/app/services/login.service';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css', '/../../../shared/all-style/all-style.component.css']
})
export class TaskComponent implements OnInit {
  id = localStorage.getItem('id');
  userId: any;
  data: any;
  user: any;
  status: string; 
  userAttendanceList: any[];
  inputDateFromString: any;
  inputDateToString: any;
  filter: any;
  startDate: string = '';
  endDate: string = '';
  myDate = new Date();
  currDate: string;
  userList: any[];
  filteredData: any[] = [];
  showFilterForm: boolean = false;
  selectedId: string;
  selectedStatus: string;
  totalElements: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;
  subChild: boolean = true;
  employees: any;
  
  // To hold the selected employee ID

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private router: Router,
    private auth: MainService,
    private dialog: MatDialog,
    private login: LoginService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  fetchData(userId: string = this.id, pageNumber: number = this.pageNumber, pageSize: number = this.pageSize, status: string = this.status, startDate: string = this.startDate, endDate: string = this.endDate) {
    this.mainService.task(userId, status, pageNumber, pageSize, startDate, endDate).subscribe((res: any) => {
      this.data = res;
      this.filteredData = this.data.content;
      this.totalElements = this.data.totalElements;
      console.log("res"+JSON.stringify(res))
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.id, this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate);
  }

  navigateToAddUserTask() {
    this.router.navigate(['/user-dashboard/add-task']);
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  update(event) {
    let target = event.target;
    const arrObj = {
      id: this.userId,
      status: target.querySelector('#status').value || null
    };
    JSON.stringify(arrObj);
    this.mainService.taskbyid(arrObj.id, arrObj).subscribe(
      (res: HttpResponse<any>) => {
        JSON.stringify(this.data);
        alert("Successfully updated.");
        this.router.navigate(['/task']);
      },
      (error) => {
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }

  openPopup(fileUrl) {
    window.open(fileUrl, 'Document Viewer', 'width=600,height=400,resizable=yes,scrollbars=yes');
    return false;
  }

  downloadpdf() {
    const startDate = this.auth.toTimestamp(this.startDate);
    const endDate = this.auth.toTimestamp(this.endDate);
    
    this.mainService.downloadtaskpdf(this.id, startDate, endDate).subscribe((blobData: Blob) => {
      const blob = new Blob([blobData], { type: 'application/pdf' });
      saveAs(blob, 'Task.pdf');
      Swal.fire({
        title: 'Downloaded',
        text: 'The PDF has been downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      });
    }, (error) => {
      console.error('Pdf or Excel Not Downloaded: ', error);
    });
  }

  onSubmitFilter() {
    this.inputDateFromString = this.auth.toTimestamp(this.startDate);
    this.inputDateToString = this.auth.toTimestamp(this.endDate);
    this.fetchData(this.id, this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString);
    // this.downloadpdf(this.id, this.inputDateFromString, this.inputDateToString);
  }

  openUpdateModal(userId: string, task: string): void {
    const dialogRef = this.dialog.open(UpdateTaskComponent, {
      width: '450px',
      data: { taskId: userId, subject: task }
    });
  }

  clearFilters(): void {
    this.startDate = '';
    this.endDate = '';
    this.status = '';  
    this.fetchData();
  }
}
