import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
// import { UserAnnouncementComponent } from '../user-announcement/user-announcement.component';
import { ElementRef, Renderer2 } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-user-welcome',
  templateUrl: './user-welcome.component.html',
  styleUrls: ['./user-welcome.component.css']
})
export class UserWelcomeComponent implements OnInit {
  totalPendingTasks: number = 0;
  todayMeetingCount: number = 0;
  userAllRequest: number = 0;
  userMonthlyAttendance: number = 0;
  id = localStorage.getItem('id');
  modalVisible: boolean = false;
  refreshToken:string;
  @ViewChild('button') button: ElementRef;
  status: number;
  startDate: number;
  endDate: string;
  pageNumber: any;
  pageSize: any;
  month:any;
  perform: any = {
    attendanceRatio: 0,
    taskCompletionRatio: 0,
    completedTasks: 0,
    pendingTasks: 0,
    totalTasks: 0,
    averagePunchInTime:0
  };
  constructor(private mainService: MainService, private dialog: MatDialog,private renderer: Renderer2,private login:LoginService) { }

  ngOnInit(): void {
    this.refreshToken = this.login.getToken();
    this.login.refreshToken(this.refreshToken).subscribe(
      (res: any) => {
        this.login = res;
        console.log("refreshtoken: ", this.refreshToken);
      },
      error => {
        console.error("Error refreshing token:", error);
      }
    );
    // setTimeout(() => {
    //     this.openDialog();
    //   }, 1000); // 1000 milliseconds = 1 second
    this.mainService.performance(this.id,this.month).subscribe((res:any)=>{
      this.perform=res;
      console.log(JSON.stringify(this.perform));
    })
    this.fetchTotalTaskCount();
    this.todayMeeting();
    this.leaveRequest();
    this.attendance();
    // Open modal after 1 second
    setTimeout(() => {
      this.openModal();
    }, 1000);
  }

  fetchTotalTaskCount() {
    this.mainService.taskpending(this.id).subscribe(
      (res: any) => {
        this.totalPendingTasks = res;
        console.log("pendingtask"+this.totalPendingTasks)
      },
      (error) => {
        console.error('Error fetching total pending tasks:', error);
      }
    );
  }
 
  todayMeeting() {
    this.mainService.usergetmeetingbyid(this.id,this.status,this.startDate,this.endDate,this.pageNumber,this.pageSize).subscribe(
      (res: any) => {
        this.todayMeetingCount = res.content.length;
      }
    );
  }
  leaveRequest() {
    this.mainService.userleavebyidwelcome(this.id).subscribe(
      (res: any) => {
        if (res && res.content) { // Check if 'content' array exists
          this.userAllRequest = res.content.length;
          console.log("leaverequest" + JSON.stringify(res));
        } else {
          this.userAllRequest = 0; // Set count to 0 if 'content' array is not found
        }
      },
      (error: any) => {
        console.error('Error fetching leave requests:', error);
        this.userAllRequest = 0; // Set count to 0 in case of error
      }
    );
  }
  

  attendance() {
    this.mainService.monthlyAttendance(this.id).subscribe(
      (res: any) => {
        this.userMonthlyAttendance = res;
      }
    );
  }

  openModal() {
    this.modalVisible = true;
  }

  // openDialog() {
  //   this.dialog.open(UserAnnouncementComponent);
  // }
 
}
