<h4 style="text-align: center; font-size: 30px;color:white; margin-top: 20px;">Update Meeting</h4>
<div class="container" style="margin-top: 72px;">
  <div class="col-12">
    <mat-card>
      <form (ngSubmit)="updatemeetings($event)">
        <mat-form-field
          class="w100"
          appearance="outline"
          style="margin-top: 16px;width: 100%;"
        >
          <mat-label style="font-weight: 500; font-size: 16px"
            >Status</mat-label
          >
          <mat-select [(ngModel)]="user.status" id="status" name="status">
            <mat-option value="POSTPONED">Postponed</mat-option>
            <mat-option value="SCHEDULED">Scheduled</mat-option>
            <mat-option value="SUSPENDED">Suspended</mat-option>
            <mat-option value="PENDING">Pending</mat-option>
            <mat-option value="DONE">Done</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="container text-center mt15">
          <button mat-raised-button color="accent">Add</button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
