import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowEmailPopupComponent } from '../../admin/show-email-popup/show-email-popup.component';

@Component({
  selector: 'app-manager-email',
  templateUrl: './manager-email.component.html',
  styleUrls: ['./manager-email.component.css','/../../../shared/all-style/email.css']
})
export class ManagerEmailComponent implements OnInit {
  emails: any[] = [];
  startDate: string = '';
  endDate: string = '';
  userId: string = '';
  users: any[] = [];
  pageSize: number = 50;
  pageNumber: number = 0;
  totalElements: number = 0;
  selectedEmailDetail: any;
  showCcBccPopup: boolean = false;
id=localStorage.getItem('id');
  constructor(
    private mainService: MainService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
    this.fetchData();
  }


  fetchUsers(): void {
    this.mainService.managerAlllist(this.id,true).subscribe((res: any) => {
      this.users = res;
      console.log(JSON.stringify(this.users));
    });
  }


  fetchData(): void {
    const formattedStartDate = this.startDate ? this.toTimestamp(this.startDate) : '';
    const formattedEndDate = this.endDate ? this.toTimestamp(this.endDate) : '';
    this.mainService.getAllEmail(this.id, formattedStartDate, formattedEndDate, this.pageNumber, this.pageSize).subscribe((res: any) => {
      if (this.pageNumber === 0) {
        this.emails = res.content;
      console.log("email"+JSON.stringify(this.emails))
      } else {
        this.emails = [...this.emails, ...res.content];
        console.log("testing"+JSON.stringify(this.emails))
      }
      this.totalElements = res.totalElements;
    });
  }

  toTimestamp(dateString: string): number {
    return new Date(dateString).getTime();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.body.scrollHeight;

    if (scrollPosition >= threshold && this.emails.length < this.totalElements) {
      this.pageNumber++;  
      this.fetchData();
    }
  }

  applyFilters(): void {
    this.pageNumber = 0;
    this.fetchData();
  }

  showEmailDetails(emailId: string): void {
    this.selectedEmailDetail = this.emails.find((email: any) => email.id === emailId);
  }

  toggleCcBccPopup(event: MouseEvent): void {
    this.showCcBccPopup = !this.showCcBccPopup;
    event.stopPropagation();
  }

  closeCcBccPopup(): void {
    this.showCcBccPopup = false;
  }

  closeMessage(): void {
    this.selectedEmailDetail = null;
    this.showCcBccPopup = false;
  }

  openEmailPopup(): void {
    this.dialog.open(ShowEmailPopupComponent, {
      width: '800px', // Set the width of the popup
    });
  }
}
