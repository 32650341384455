import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subtask-assignment',
  templateUrl: './subtask-assignment.component.html',
  styleUrls: ['./subtask-assignment.component.css']
})
export class SubtaskAssignmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
